import React from 'react';
import {makeStyles} from '@material-ui/core';
import {GoogleMapProvider, MapBox} from '@googlemap-react/core';
import {useSelector} from 'react-redux';
import AOILayer from './AOILayer';
import LayerSwitcher from './LayerSwitcher';
import POILayer from './POILayer';
import InfoWindow from './InfoWindow';
import CenterButton from './CenterButton';
import RasterLayer from './RasterLayer';
import Legend from './Legend';
import GeoLocation from './GeoLocation';

const useStyles = makeStyles(theme => ({
    map: {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        }
    }
}));

export default function MyMap() {
    const classes = useStyles();
    const layers = useSelector(state => state.map.layers);

    return (
        <GoogleMapProvider>
            <div className={classes.map}>
                <LayerSwitcher/>
                <MapBox
                    apiKey={'AIzaSyCTR7YFa-PlovkbROk3oK4jXWf4OunqxPM'}
                    style={{
                        flex: 1,
                        width: '100%',
                        height: '100%',
                    }}
                    opts={{
                        mapTypeId: 'hybrid',
                        mapTypeControl: false,
                    }}
                    useDrawing
                    useGeometry
                    usePlaces
                    useVisualization
                />
                <Legend/>
                <GeoLocation/>
                <AOILayer/>
                <CenterButton/>
                {layers.map(layer =>
                    layer.url ?
                        <RasterLayer key={layer.id} layer={layer}/>
                        :
                        <POILayer key={layer.id} layer={layer}/>
                )}
                <InfoWindow/>
            </div>
        </GoogleMapProvider>
    );
}
