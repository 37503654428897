import React, {Suspense, useEffect} from 'react';
import UserAccountHandler from '../accounts/UserAccountHandler';
import Errors from '../errors/Errors';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {BrowserRouter} from 'react-router-dom';
import {StaticRouter} from 'react-router';
import Loader from '../loader/Loader';

function CommonFramework(props) {
    const { children, store, theme } = props;

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <Errors>
                    <UserAccountHandler>
                        {children}
                    </UserAccountHandler>
                </Errors>
            </MuiThemeProvider>
        </Provider>
    );
}

export default function Framework(props) {
    const { ssr, location, i18n, children } = props;

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }, []);

    if (ssr) {
        return (
            <StaticRouter location={location} context={{}}>
                <I18nextProvider i18n={i18n}>
                    <CommonFramework {...props}>
                        {children}
                    </CommonFramework>
                </I18nextProvider>
            </StaticRouter>
        );
    }

    return (
        <Suspense fallback={<Loader/>}>
            <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
                <CommonFramework {...props}>
                    {children}
                </CommonFramework>
            </BrowserRouter>
        </Suspense>
    );
}
