import {simpleAction} from './baseActions';
import {checkoutService} from '../../services';
import {errorActions} from './errorActions';
import {checkoutModule} from 'shared/src/modules/checkout';

export const checkoutActions = {
    loadOrder,
    saveOrder,
    launchCheckout,
    addToOrder,
    interruptCheckout,
    setPaymentInfo,
    resetOrder,
    LAUNCH_CHECKOUT: 'CHECKOUT_LAUNCH_CHECKOUT',
    INTERRUPT_CHECKOUT: 'CHECKOUT_INTERRUPT_CHECKOUT',
    SET_ORDER: 'CHECKOUT_SET_ORDER',
    SET_ORDER_SAVING: 'CHECKOUT_SET_ORDER_SAVING',
    SET_PAYMENT_INFO: 'CHECKOUT_SET_PAYMENT_INFO'
};

function loadOrder() {
    return async (dispatch) => {
        try {
            const order = await checkoutService.loadOrder();
            dispatch(simpleAction(checkoutActions.SET_ORDER, { order }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function saveOrder(order, final = false) {
    return async (dispatch) => {
        try {
            if (order && order.orderItems && order.orderItems.length > 0) {
                dispatch(setOrderSaving(true));
                const {order: savedOrder, paymentInfo} = await checkoutService.saveOrder(order, final);
                dispatch(simpleAction(checkoutActions.SET_ORDER, { order: savedOrder }));
                dispatch(setPaymentInfo(paymentInfo));
                dispatch(setOrderSaving(false));
            } else {
                dispatch(resetOrder());
                dispatch(setOrderSaving(false));
                dispatch(interruptCheckout());
            }
        } catch (error) {
            dispatch(errorActions.setError(error));
            dispatch(resetOrder());
            dispatch(setOrderSaving(false));
            dispatch(interruptCheckout());
        }
    };
}

function resetOrder() {
    return async (dispatch) => {
        try {
            await checkoutService.resetOrder();
            dispatch(simpleAction(checkoutActions.SET_ORDER, { order: null }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function launchCheckout() {
    return simpleAction(checkoutActions.LAUNCH_CHECKOUT, {});
}

function addToOrder(orderParam, tour, date = null, time = null, tourDateId = null, tourTimeId = null) {
    return async (dispatch) => {
        try {
            let order = orderParam;
            if (!order) {
                order = await checkoutService.getInitialOrder();
            }
            const updatedOrder = checkoutModule.addToOrder(order, tour, date, time, tourDateId, tourTimeId, true);
            dispatch(simpleAction(checkoutActions.SET_ORDER, { order: updatedOrder }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function interruptCheckout() {
    return simpleAction(checkoutActions.INTERRUPT_CHECKOUT, {});
}

function setOrderSaving(isSaving) {
    return simpleAction(checkoutActions.SET_ORDER_SAVING, {orderSaving: isSaving});
}

function setPaymentInfo(paymentInfo = null) {
    return simpleAction(checkoutActions.SET_PAYMENT_INFO, {paymentInfo: paymentInfo});
}

