import {sendServerRequest} from './baseService';

export const userService = {
    login,
    logout,
    getCurrentUser,
    register,
    validateRegistrationInfo,
    passwordReset,
    passwordRenew,
    activateAccount,
    subscribeToNewsLetter,
};

async function login(username, password) {
    const body = {
        'grant_type': 'password',
        username,
        password,
    };

    return sendServerRequest('POST', `/api/oauth2/token`, body);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function getCurrentUser() {
    return sendServerRequest('GET', `/api/users?loggedIn=true`);
}

async function register(email, password) {
    const body = {
        email,
        password,
    };

    return sendServerRequest('POST', '/api/users', body);
}

async function validateRegistrationInfo(email) {
    const body = {
        email
    };

    return sendServerRequest('POST', `/api/validateRegistrationInfo`, body);
}

async function passwordReset(username) {
    const body = {
        username,
    };

    return sendServerRequest('POST', `/api/passwordReset`, body);
}

async function passwordRenew(password, token) {
    const body = {
        password,
        token
    };

    return sendServerRequest('POST', `/api/passwordRenew`, body);
}

async function activateAccount(token) {
    const body = {
        token
    };

    return sendServerRequest('POST', `/api/activateAccount`, body);
}

async function subscribeToNewsLetter(emailAddress) {
    return sendServerRequest('POST', `/api/subscribeToNewsletter`, {
        emailAddress,
    });
}