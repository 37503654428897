import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import {LANGUAGES} from 'shared/src/constants/translation';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES} from 'shared/src/constants/routes';

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-between",
        margin: 0,
        padding: 0,
    },

    item: {
        color: "#9B9B9B",
        display: "block",
        flex: "0 1 auto",
        listStyleType: "none",
        fontWeight: "500",
        '&:not(:first-child)': {
            paddingLeft: "10px",
        },
    },

    link: {
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "inherit",
        color: "inherit",
    },
    selected: {
        color: "#FFFFFF",
        fontWeight: "bold",
    }
});

class LanguageMenu extends React.Component {
    render() {
        const { classes, className, i18n } = this.props;

        return (
            <ul className={clsx(classes.root, className)}>
                {LANGUAGES.map(language => (
                    <li key={language.id} className={clsx(classes.item, i18n.language === language.code ? classes.selected : null)}>
                        <Link to={getPathWithLanguage(ROUTES.HOME, language.code)} className={classes.link}>
                            {language.shortName}
                        </Link>
                    </li>
                ))}
            </ul>
        );
    }
}

LanguageMenu.propTypes = {};

export default withTranslation()(withStyles(styles)(LanguageMenu));