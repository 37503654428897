import React from 'react';
import MainScreen from "../components/screens/MainScreen";
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import {screens} from '../styles/styles';
import _ from 'lodash';
import {contentActions} from 'client_shared/src/state/actions';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
    contentLayout: {
        paddingTop: '30px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '30px',
    },
    title: {
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '35px',
    },
    pageHeader: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginTop: '50px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginTop: '30px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginTop: '30px',
        },
    },
    textBlock: {
        paddingTop: '50px',
        paddingBottom: '41px',
        '& > p': {
            color: 'rgba(74,74,74,1)',
            fontSize: '16px',
            lineHeight: '21px',
            margin: 0,
            [theme.breakpoints.down(screens.mobile)]: {
                fontSize: '14px',
                lineHeight: '18px',
            },
            '&:not(:first-child)': {
                paddingTop: '30px',
            }
        }
    },
}));


function PrivacyPolicy() {
    const classes = useStyles();
    const privacyPolicy = useSelector(state => state.content.privacyPolicy);

    const createMarkup = (text) => {
        return { __html: text };
    };

    if (!privacyPolicy) {
        return null;
    }

    return (
        <MainScreen>
            <div className={classes.contentLayout}>
                <div className={clsx(classes.title, classes.pageHeader)}>
                    {privacyPolicy.title}
                </div>
                <div className={classes.textBlock} dangerouslySetInnerHTML={createMarkup(privacyPolicy.body)}/>
            </div>
        </MainScreen>
    );
}

PrivacyPolicy.initState = (environment) => {
    const { storeState, dispatch } = environment;

    const promises = [];

    if (_.isEmpty(storeState.content.privacyPolicy)) {
        promises.push(dispatch(contentActions.loadPrivacyPolicy()));
    }

    return promises;
};

export default PrivacyPolicy;

