import React from 'react';
import {makeStyles} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import DeleteIcon from '../../icons/mono/DeleteIcon';
import CheckboxComponent from '../../controls/CheckboxComponent';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import SubmitButton from '../../buttons/SubmitButton';
import {userActions} from 'client_shared/src/state/actions';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-rounded': {
            borderRadius: '8px',
        },
        position: 'relative',
    },
    layout: {
        backgroundColor: '#FFFFFF',
        padding: '30px 20px 20px 20px',
        [theme.breakpoints.up('sm')]: {
            padding: '30px 20px 20px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '40px',
        },
    },
    title: {
        fontSize: '16px',
    },
    titleBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    ovalWrapper: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    oval: {
        position: 'relative',
        height: '24px',
        width: '24px',
        borderRadius: '24px',
        backgroundColor: '#F2F2F2',
        cursor: 'pointer',
    },
    icon: {
        position: 'absolute',
        top: '6px',
        left: '6px',
        height: '12px',
        width: '12px',
    },
    button: {
        marginTop: '30px',
    },
    checkBoxChecked: {
        color: '#96CC29',
    },
    checkBoxUnChecked: {
        color: '#D93616',
    },
    rejectedBox: {
        borderRadius: '4px',
        padding: '10px',
        backgroundColor: '#FFAA99',
    },
    rejectedText: {
        '&.paragraph4': {
            fontSize: '10px',
            lineHeight: '16px',
            letterSpacing: '0.3px',
            color: '#000000'
        }
    },
    message: {
        marginBottom: '30px',
    }
}));

export default function CookiePopup(props) {
    const classes = useStyles();
    const cookiesAccepted = useSelector(state => state.user.cookiesAccepted);
    const cookiesPopupOpen = useSelector(state => state.user.cookiesPopupOpen);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    function onImportantCookiesApprovedChange(props) {
        return (event) => {
            props.setFieldValue('importantCookiesApproved', event.target.checked);
            if (!event.target.checked) {
                props.setFieldValue('convenienceCookiesApproved', false);
                props.setFieldValue('statisticalCookiesApproved', false);
            }
        }
    }

    function onConvenienceCookiesApprovedChange(props) {
        return (event) => {
            props.setFieldValue('convenienceCookiesApproved', event.target.checked);
        }
    }

    function onStatisticalCookiesApprovedChange(props) {
        return (event) => {
            props.setFieldValue('statisticalCookiesApproved', event.target.checked);
        }
    }

    async function onSubmit(values, { setSubmitting, resetForm }) {
        resetForm();
        setSubmitting(false);
        dispatch(userActions.setCookiesAccepted(values));
        dispatch(userActions.setCookiesPopupOpen(false))
    }

    async function onCancel() {
        dispatch(userActions.setCookiesPopupOpen(false))
    }

    return (
        <Dialog onClose={onCancel} aria-labelledby="simple-dialog-title" open={cookiesPopupOpen} className={classes.dialog}>
            <Formik
                initialValues={{
                    importantCookiesApproved: cookiesAccepted ? cookiesAccepted.importantCookiesApproved : true,
                    convenienceCookiesApproved: cookiesAccepted ? cookiesAccepted.convenienceCookiesApproved : true,
                    statisticalCookiesApproved: cookiesAccepted ? cookiesAccepted.statisticalCookiesApproved : true
                }}
                onSubmit={onSubmit}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit} className={classes.form}>
                        <div className={classes.ovalWrapper}>
                            <div className={classes.oval} onClick={onCancel}>
                                <DeleteIcon fill={'#5C5C66'} className={classes.icon}/>
                            </div>
                        </div>
                        <div className={classes.layout}>
                            <div className={classes.titleBar}>
                                <h4>{t('Text.CookieDialogTitle')}</h4>
                            </div>
                            <div className={clsx('paragraph4', classes.message)}
                                 dangerouslySetInnerHTML={createMarkup(t('Text.CookieDialogMessage'))}/>
                            <h5 className={classes.title}>{t('Text.CookieImportant')}</h5>
                            <CheckboxComponent
                                label={props.values.importantCookiesApproved ? t('Text.CookieDialogCheckboxApprove') : t('Text.CookieDialogCheckboxReject')}
                                onChange={onImportantCookiesApprovedChange(props)}
                                propertyName={'importantCookiesApproved'}
                                disabled={true}
                                checkBoxLabelClassName={props.values.importantCookiesApproved ? classes.checkBoxChecked : classes.checkBoxUnChecked}
                                {...props}
                            />
                            <div className={clsx('paragraph4', classes.message)}
                                 dangerouslySetInnerHTML={createMarkup(t('Text.CookieImportantDescription'))}/>
                            <h5 className={classes.title}>{t('Text.CookieConvenience')}</h5>
                            <CheckboxComponent
                                label={props.values.convenienceCookiesApproved ? t('Text.CookieDialogCheckboxApprove') : t('Text.CookieDialogCheckboxReject')}
                                onChange={onConvenienceCookiesApprovedChange(props)}
                                propertyName={'convenienceCookiesApproved'}
                                checkBoxLabelClassName={props.values.convenienceCookiesApproved ? classes.checkBoxChecked : classes.checkBoxUnChecked}
                                {...props}
                            />
                            <div className={clsx('paragraph4', classes.message)}
                                 dangerouslySetInnerHTML={createMarkup(t('Text.CookieConvenienceDescription'))}/>
                            <h5 className={classes.title}>{t('Text.CookieStatistical')}</h5>
                            <CheckboxComponent
                                label={props.values.statisticalCookiesApproved ? t('Text.CookieDialogCheckboxApprove') : t('Text.CookieDialogCheckboxReject')}
                                onChange={onStatisticalCookiesApprovedChange(props)}
                                propertyName={'statisticalCookiesApproved'}
                                checkBoxLabelClassName={props.values.statisticalCookiesApproved ? classes.checkBoxChecked : classes.checkBoxUnChecked}
                                {...props}
                            />
                            <div className={clsx('paragraph4', classes.message)}
                                 dangerouslySetInnerHTML={createMarkup(t('Text.CookieStatisticalDescription'))}/>
                            <SubmitButton className={classes.button} variant={'normal'} type={'submit'}
                                                 title={t('Text.CookieDialogSaveButton')}/>
                        </div>
                    </form>
                )}
            </Formik>
        </Dialog>

    );
}
