import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button/Button';
import {screens} from '../../../styles/styles';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    card: {
        borderColor: 'rgba(239,239,239,1)',
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '30px 30px 54px 30px',
        },
    },
    layout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: '24px',
        fontWeight: 900,
        lineHeight: '28px',
        paddingBottom: '15px',
    },
    radioLabel: {
        fontSize: '16px',
    },
    radioLabelSelected: {
        fontWeight: 'bold',
    },
    radio: {
        '&$checked': {
            color: 'rgba(0,84,255,1)',
        },
    },
    checked: {},
    description: {
        paddingLeft: '35px',
        color: 'rgba(155,155,155,1)',
        fontSize: '14px',
    },
    button: {
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    statementText: {
        marginTop: 20,
    },
    logo: {
        marginTop: 20,
    },
});

/*const options = [
    {
        id: 1,
        label: 'Checkout.PaymentCard',
        value: CHECKOUT.PAYMENT_OPTIONS.CREDIT_CARD,
        description: 'Checkout.PaymentCardDescription',
    },
    {
        id: 2,
        label: 'Checkout.Transfer',
        value: CHECKOUT.PAYMENT_OPTIONS.BANK_TRANSFER,
        description: 'Checkout.PaymentTransferDescription',
    },
];*/

function PaymentOptionsCard(props) {
    const { classes, className, t } = props;

/*
    const onPaymentOptionChange = event => {
        this.props.setFieldValue('paymentType', event.target.value);
    };

*/
    return (
        <div className={clsx(classes.layout, classes.card, className)}>
            {/*            <div className={classes.title}>
                {t('Checkout.PaymentTitle')}
            </div>
            <FormControl component="fieldset">
                {options.map(option =>
                    <div key={option.id}>
                        <FormControlLabel
                            checked={`${values.paymentType}` === `${option.value}`}
                            onChange={onPaymentOptionChange}
                            key={option.id}
                            value={`${option.value}`}
                            control={
                                <Radio classes={{ root: classes.radio, checked: classes.checked }}/>
                            }
                            label={t(option.label)}
                            classes={{ label: clsx(classes.radioLabel, `${values.paymentType}` === `${option.value}` ? classes.radioLabelSelected : '') }}
                        />
                        {`${values.paymentType}` === `${option.value}` ?
                            <>
                                <div className={classes.description}>
                                    {t(option.description)}
                                </div>
                            </> : null
                        }
                    </div>
                )}
            </FormControl>*/}
            <Button className={classes.button} type="submit">
                {t('Checkout.StartSimplePay')}
            </Button>
            <div className={classes.statementText}>
                {t('Checkout.SimplePayDescription')}
            </div>
            <a className={classes.logo} href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
               target="_blank" rel="noopener noreferrer">
                <img width="364" src="/images/simplepay_horizontal_02.png"
                     title=" SimplePay - Online bankkártyás fizetés"
                     alt=" SimplePay vásárlói tájékoztató"/>
            </a>
        </div>
    );
}

export default withTranslation()(withStyles(styles)(PaymentOptionsCard));