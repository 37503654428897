export const pageActions = {
    setPages,
    setPage,
    setParentPage,
    SET_PAGES: 'PAGE_SET_PAGES',
    SET_PAGE: 'PAGE_SET_PAGE',
    SET_PARENT_PAGE: 'PAGE_SET_PARENT_PAGE',
};

function setPages(pages) {
    return {
        type: pageActions.SET_PAGES,
        payload: {
            pages,
        }
    };
}

function setPage(page) {
    return {
        type: pageActions.SET_PAGE,
        payload: {
            page,
        }
    };
}

function setParentPage(parentPage) {
    return {
        type: pageActions.SET_PARENT_PAGE,
        payload: {
            parentPage,
        }
    };
}

