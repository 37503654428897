import {errorActions, setError} from './errorActions';
import {badgeService} from '../../services/badgeService';

export const SET_BADGES = 'BADGES_SET_BADGES';

export const badgeActions = {
    loadBadges,
    setBadges,
};

function loadBadges() {
    return async (dispatch) => {
        try {
            const badges = await badgeService.getBadges();
            dispatch(badgeActions.setBadges(badges));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setBadges(badges) {
    return {
        type: SET_BADGES,
        payload: {
            badges,
        }
    };
}

