import {checkoutActions} from '../actions/checkoutActions';
import _ from 'lodash';

const initialState = {
    open: false,
    order: null,
    orderSaving: false,
    paymentInfo: null,
};

export function checkout(state = initialState, action) {
    switch (action.type) {
        case checkoutActions.LAUNCH_CHECKOUT:
            return {
                ...state,
                open: true,
            };
        case checkoutActions.INTERRUPT_CHECKOUT:
            return {
                ...state,
                open: false,
            };
        case checkoutActions.SET_ORDER:
            return {
                ...state,
                order: action.payload.order ? _.cloneDeep(action.payload.order) : null,
            };
        case checkoutActions.SET_ORDER_SAVING:
            return {
                ...state,
                orderSaving: action.payload.orderSaving,
            };
        case checkoutActions.SET_PAYMENT_INFO:
            return {
                ...state,
                paymentInfo: action.payload.paymentInfo,
            };
        default:
            return state
    }
}
