import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core';
import InfoLayout from '../../layouts/InfoLayout';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PropTypes from 'prop-types';
import {screens} from '../../../styles/styles';
import CardBase from '../CardBase';
import {withTranslation} from 'react-i18next';

const styles = theme => ({

    descriptionBlockLayout: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        '&:last-child': {
            paddingBottom: '44px',
        },
        [theme.breakpoints.down(screens.tablet)]: {
            padding: '23.87px 21.56px 21.56px 21.56px',
            '&:last-child': {
                paddingBottom: '33.82px',
            },
        },
        [theme.breakpoints.down(screens.mobile)]: {
            paddingTop: '35px',
        },
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '21px',
        border: 0,
        padding: '0px 0px 14px 0px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            fontSize: '18px',
            lineHeight: '21px',
            padding: '0px 0px 10.95px 0px',
        },
        [theme.breakpoints.down(screens.mobile)]: {},
    },
    phone: {
        color: 'rgba(74,74,74,1)',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '22px',
        padding: '0px',
        [theme.breakpoints.down(screens.tablet)]: {
            fontSize: '14px',
            lineHeight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {},
    },
    email: {
        fontSize: '16px',
        fontWeight: 'normal',
        [theme.breakpoints.down(screens.tablet)]: {
            fontSize: '14px',
            lineHeight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {},
    },
    icon: {
        color: 'rgba(255,159,0,1)',
        minHeight: '20px',
        maxHeight: '20px',
        minWidth: '20px',
        maxWidth: '20px',
        [theme.breakpoints.down(screens.tablet)]: {
            minHeight: '15px',
            maxHeight: '15px',
            minWidth: '16px',
            maxWidth: '16px',
        },
        [theme.breakpoints.down(screens.mobile)]: {},
    },
    contact: {
        marginTop: '20px',
        color: 'rgba(74,74,74,1)',
        fontSize: '16px',
        lineHeight: '22px',
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
});

export class MainContactsCard extends React.Component {
    render() {
        const { classes, className, t } = this.props;
        return (
            <CardBase className={className}>
                <div className={clsx(classes.descriptionBlockLayout)}>
                    <InfoLayout
                        icon={(
                            <PhoneIcon className={classes.icon}/>
                        )}
                        title={(
                            <div className={classes.title}>
                                {t('Text.Phone')}
                            </div>
                        )}
                        text={(
                            <div className={classes.phone}>
                                <div className={classes.contact}>
                                    <div>{t('Text.Police')}</div>
                                    <div>{t('Text.PolicePhone')}</div>
                                </div>
                                <div className={classes.contact}>
                                    <div>{t('Text.Guards')}</div>
                                    <div>{t('Text.GuardsPhone')}</div>
                                </div>
                            </div>
                        )}/>
                </div>
                <div className={clsx(classes.hideOnMobile, classes.descriptionBlockLayout)}>
                    <InfoLayout
                        icon={(
                            <EmailIcon className={classes.icon}/>
                        )}
                        title={(
                            <div className={classes.title}>
                                {t('Text.Email')}
                            </div>
                        )}
                        text={(
                            <a href={`mailto:${t('Text.AnpiEmail')}`} className={classes.email}>{t('Text.AnpiEmail')}</a>
                        )}/>
                </div>
            </CardBase>
        )
    }
}

MainContactsCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

MainContactsCard.defaultProps = {
    className: '',
};

export default withTranslation()(withStyles(styles)(MainContactsCard));
