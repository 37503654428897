import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {screens} from '../../../styles/styles';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    root: {
        [theme.breakpoints.down(screens.mobile)]: {
            background: theme.palette.background.default,
            paddingTop: '24px',
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '10px',
        },
    },
    text: {
        color: 'rgba(74,74,74,1)',
        paddingBottom: '20px',
        fontSize: '16px',
        lineHeight: '22px',
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '12px',
            lineHeight: '17px',
        },
    },
});

class CheckoutSummaryCard extends React.Component {
    render() {
        const { classes, className, t } = this.props;
        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.text}>
                    {t('Text.CheckoutSummaryTitle')}
                </div>
            </div>
        )
    }
}

CheckoutSummaryCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

CheckoutSummaryCard.defaultProps = {
    className: '',
};

export default withTranslation()(withStyles(styles)(CheckoutSummaryCard));