import React from 'react';
import MainScreen from "../components/screens/MainScreen";
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import {screens} from '../styles/styles';
import _ from 'lodash';
import {contentActions, userActions} from 'client_shared/src/state/actions';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    contentLayout: {
        paddingTop: '30px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '30px',
    },
    title: {
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '35px',
    },
    pageHeader: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginTop: '50px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginTop: '30px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginTop: '30px',
        },
    },
    textBlock: {
        paddingTop: '50px',
        paddingBottom: '41px',
        '& > p': {
            color: 'rgba(74,74,74,1)',
            fontSize: '16px',
            lineHeight: '21px',
            margin: 0,
            [theme.breakpoints.down(screens.mobile)]: {
                fontSize: '14px',
                lineHeight: '18px',
            },
            '&:not(:first-child)': {
                paddingTop: '30px',
            }
        }
    },
    button: {
        whiteSpace: 'nowrap',
        height: '40px',
        border: '0px',
        borderRadius: '25px',
        backgroundColor: 'rgba(18,218,173,1)',
        color: 'rgba(255,255,255,1)',
        fontSize: '15px',
        lineHeight: '18px',
        textAlign: 'center',
        padding: '0 15px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.up(screens.desktop)]: {
            marginLeft: '10px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginLeft: '10px',
        },
        [theme.breakpoints.down(screens.mobile)]: {},
    },
    buttonSecondary: {
        backgroundColor: 'rgba(0,0,0,0.95)',
    }
}));


function CookiePolicy() {
    const classes = useStyles();
    const cookiePolicy = useSelector(state => state.content.cookiePolicy);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function onDetailsClick() {
        dispatch(userActions.setCookiesPopupOpen(true));
    }

    const createMarkup = (text) => {
        return { __html: text };
    };

    if (!cookiePolicy) {
        return null;
    }

    return (
        <MainScreen>
            <div className={classes.contentLayout}>
                <div className={clsx(classes.title, classes.pageHeader)}>
                    {cookiePolicy.title}
                </div>
                <div className={classes.textBlock} dangerouslySetInnerHTML={createMarkup(cookiePolicy.body)}/>
            </div>
            <button onClick={onDetailsClick}
                    className={clsx(classes.button, classes.buttonSecondary)}>{t('Text.AdjustCookies')}</button>
        </MainScreen>
    );
}

CookiePolicy.initState = (environment) => {
    const { storeState, dispatch } = environment;

    const promises = [];

    if (_.isEmpty(storeState.content.cookiePolicy)) {
        promises.push(dispatch(contentActions.loadCookiePolicy()));
    }

    return promises;
};

export default CookiePolicy;

