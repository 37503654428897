import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {screens} from '../../styles/styles';
import SimpleHeader from '../headers/SimpleHeader';

const styles = theme => ({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
    },
    mainAreaAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
    },
    mainAreaSize: {
        minWidth: theme.main.width,
        maxWidth: theme.main.width,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down(screens.tablet)]: {
            minWidth: '100%',
            maxWidth: '100%',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
});

class SecondaryScreen extends React.Component {
    render() {
        const { classes, children, title } = this.props;

        return (
            <div className={classes.page}>
                <SimpleHeader title={title}/>
                <main className={clsx(classes.mainAreaAlign)}>
                    <div className={clsx(classes.mainAreaSize)}>
                        {children}
                    </div>
                </main>
            </div>
        )
    }
}

SecondaryScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SecondaryScreen);