import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CardBase from '../CardBase';
import clsx from 'clsx';
import {screens} from '../../../styles/styles';
import InfoLayout from '../../layouts/InfoLayout';
import MoreButton from '../../buttons/MoreButton';

const styles = theme => ({
    card: {
        padding: '20px',
    },
    layout: {},
    column: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '133px',
        paddingRight: '83px',
        paddingTop: '29px',
        [theme.breakpoints.down(screens.tablet)]: {
            paddingTop: '20px',
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    imageMask: {
        minHeight: '271px',
        maxHeight: '271px',
        minWidth: '462px',
        maxWidth: '462px',
        [theme.breakpoints.down(screens.tablet)]: {
            minHeight: '120px',
            maxHeight: '120px',
            minWidth: 'initial',
            maxWidth: 'initial',
        },
    },
    moreButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    title: {
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '24px',
        marginTop: '3px',
    },
    description: {
        marginTop: '16px',
        marginBottom: '16px',
        color: 'rgba(74,74,74,1)',
        fontSize: '14px',
        lineHeight: '18px',
    },
});

class RuleCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        }
    }

    onExpandToggle = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    createMarkup = (text) => {
        return { __html: text };
    };

    render() {
        const { classes, className, title, text, summary, icon } = this.props;
        return (
            <CardBase className={clsx(classes.card, className)}>
                <InfoLayout
                    className={classes.layout}
                    icon={icon}
                    title={(
                        <div className={classes.title}>
                            {title}
                        </div>
                    )}
                    textWide={(
                        <div className={classes.description} dangerouslySetInnerHTML={this.createMarkup(summary)}/>
                    )}
                    textHidden={this.state.expanded}
                />
                {!this.state.expanded && (
                    <div className={classes.moreButton}>
                        <MoreButton expanded={this.state.expanded} expandToggle={this.onExpandToggle}/>
                    </div>
                )}
                {this.state.expanded && (
                    <div className={classes.description} dangerouslySetInnerHTML={this.createMarkup(text)}/>
                )}
                {this.state.expanded && (
                    <div className={classes.moreButton}>
                        <MoreButton expanded={this.state.expanded} expandToggle={this.onExpandToggle}/>
                    </div>
                )}
            </CardBase>
        )
    }
}

RuleCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
};

RuleCard.defaultProps = {
    className: '',
};


export default withStyles(styles)(RuleCard);