import {userActions} from './userActions';
import {userService} from '../../services/userService';
import {errorActions} from './errorActions';

export const loginActions = {
    login,
    autoLogin,
    logout,
    passwordReset,
    passwordRenew,
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT: 'LOGIN_LOGOUT',
};

function login(userName, password) {
    return async (dispatch) => {
        try {
            const token = await userService.login(userName, password);
            const accessToken = token.access_token;
            localStorage.setItem('anpi-jwt', accessToken);
            dispatch(success());
            dispatch(userActions.getCurrentUser());
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };

    function success() {
        return { type: loginActions.LOGIN_SUCCESS }
    }
}

function autoLogin() {
    return async (dispatch) => {
        const token = localStorage.getItem('anpi-jwt');

        if (token) {
            dispatch(userActions.getCurrentUser());
        }
    };
}

function logout() {
    return async (dispatch) => {
        localStorage.removeItem('anpi-jwt');
        dispatch(userActions.removeUser());
        dispatch({ type: loginActions.LOGOUT });
    }
}

function passwordReset(userName) {
    return async (dispatch) => {
        try {
            await userService.passwordReset(userName);
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function passwordRenew(password, token) {
    return async (dispatch) => {
        try {
            await userService.passwordRenew(password, token);
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}