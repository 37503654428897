import {errorActions} from './errorActions';
import {tourGuideService} from '../../services/tourGuideService';
import {simpleAction} from './baseActions';
import {tourService} from '../../services';
import {tourActions} from './tourActions';

export const tourGuideActions = {
    loadTourGuides,
    loadTourGuide,
    SET_TOUR_GUIDES: 'TOUR_GUIDE_SET_TOUR_GUIDES',
    SET_TOUR_GUIDE: 'TOUR_GUIDE_SET_TOUR_GUIDE',
};

function loadTourGuides() {
    return async (dispatch) => {
        try {
            const tourGuides = await tourGuideService.getTourGuides();
            dispatch(simpleAction(tourGuideActions.SET_TOUR_GUIDES, {tourGuides}));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadTourGuide(tourGuideId) {
    return async (dispatch) => {
        try {
            const tourGuide = await tourGuideService.getTourGuide(tourGuideId);
            dispatch(simpleAction(tourGuideActions.SET_TOUR_GUIDE, { tourGuide }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}
