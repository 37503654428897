import React from 'react';
import clsx from 'clsx';
import DoneIcon from '../../icons/DoneIcon';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import 'url-search-params-polyfill';

const styles = theme => ({
    color: {
        backgroundColor: theme.palette.background.paper,
    },
    layout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '83px 30px 186px 30px',
    },
    title: {
        fontSize: '24px',
        fontWeight: 900,
        lineHeight: '28px',
        paddingTop: '26px',
        paddingBottom: '15px',
    },
    description: {
        color: 'rgba(74,74,74,1)',
        fontSize: '14px',
        lineHeight: '17px',
        textAlign: 'center',
    },
    successIcon: {
        backgroundColor: 'rgba(18,218,173,1)',
    },
    failureIcon: {
        color: 'rgba(255,0,0,1)',
        minWidth: '78px',
        minHeight: '78px',
    }
});

export class OrderAcknowledgementCard extends React.Component {
    render() {
        const { classes, className, location, t } = this.props;

        let searchParams = new URLSearchParams(location.search);
        const orderRef = searchParams.get('order_ref');
        const paymentRef = searchParams.get('payrefno');
        const date = searchParams.get('date');
        const resultCode = searchParams.get('RC');
        const email = searchParams.get('orderEmail');

        const success = (resultCode === '000' || resultCode === '001' || resultCode === '');

        return (
            <div className={clsx(classes.layout, classes.color, className)}>
                {success &&
                <DoneIcon className={classes.successIcon}>
                    <CheckIcon/>
                </DoneIcon>
                }
                {!success &&
                <ErrorIcon className={classes.failureIcon}/>
                }
                <div className={classes.title}>
                    {success &&
                    t('Checkout.SuccessfulTransaction')
                    }
                    {!success &&
                    t('Checkout.FailedTransaction')
                    }
                </div>
                {success &&
                <div className={classes.description}>
                    {resultCode === '' &&
                    (
                        <>
                            {t('Checkout.TransferTicketEmailingMessage')}<br/>
                            <br/>
                            <strong>{email}</strong>
                        </>
                    )}
                    {(resultCode === '000' || resultCode === '001') &&
                    (
                        <>
                            {t('Checkout.CardTicketEmailingMessage')}<br/>
                            <br/>
                            <strong>{email}</strong>
                        </>
                    )}
                    <br/>
                    <br/>
                    {t('Checkout.SimplePayRef')}: <strong>{paymentRef}</strong><br/>
                    {t('Checkout.OrderRef')}: <strong>{orderRef}</strong><br/>
                    {t('Checkout.OrderDate')}: <strong>{date}</strong><br/>
                </div>
                }
                {!success &&
                <div className={classes.description}>
                    {!resultCode &&
                    (
                        <>
                            {t('Checkout.CancelledTransaction')}<br/>
                            {t('Checkout.CancelledTransactionDescription')}<br/>
                            <br/>
                            {t('Checkout.OrderRef')}: <strong>{orderRef}</strong><br/>
                            {date && (<>{t('Checkout.OrderDate')}: <strong>{date}</strong><br/></>)}
                        </>
                    )}
                    {resultCode &&
                    (
                        <>
                            {t('Checkout.FailedTransactionDescription')}<br/>
                            <br/>
                            {t('Checkout.SimplePayRef')}: <strong>{paymentRef}</strong><br/>
                            {t('Checkout.OrderRef')}: <strong>{orderRef}</strong><br/>
                            {t('Checkout.OrderDate')}: <strong>{date}</strong><br/>
                        </>
                    )}
                </div>
                }
            </div>
        )
    }
}

OrderAcknowledgementCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

OrderAcknowledgementCard.defaultProps = {
    className: '',
};

export default withTranslation()(withStyles(styles)(withRouter(OrderAcknowledgementCard)));