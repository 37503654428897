import React from 'react';
import {makeStyles} from '@material-ui/core';
import {screens} from '../styles/styles';
import {contentActions} from 'client_shared/src/state/actions';
import {CONTENT} from 'shared/src/constants/content';
import MainScreen from '../components/screens/MainScreen';
import WaterTourArticleCard from '../components/cards/article/WaterTourArticleCard';
import {tourActions} from 'client_shared/src/state/actions/tourActions';
import {TOURS} from 'shared/src/constants/tours';

const useStyles = makeStyles(theme => ({
    article: {
        marginBottom: '10px',
        '&:last-child': {
            marginBottom: '47px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginBottom: '10px',
            '&:last-child': {
                marginBottom: '35px',
            },
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '28px',
            '&:last-child': {
                marginBottom: '47px',
            },
        },
    },
}));

function WaterTourArticle() {
    const classes = useStyles();

    return (
        <MainScreen>
            <WaterTourArticleCard className={classes.article}/>
        </MainScreen>
    );
}

WaterTourArticle.initState = (environment) => {
    const { dispatch } = environment;

    const promises = [];

    promises.push(dispatch(contentActions.loadContentByName(CONTENT.ARTICLE_WATER_TOUR)));
    promises.push(dispatch(tourActions.loadTour(TOURS.WATER_TOUR_ID)));

    return promises;
};

export default WaterTourArticle;
