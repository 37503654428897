import {makeStyles, TextField} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';
import * as React from 'react';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {mapService} from '../../services/mapService';
import SearchIcon from '@material-ui/icons/Search';
import {mapActions, zoomToFeature} from '../../state/actions/mapActions';
import clsx from 'clsx';

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
        <TextField
            className={classes.root}
            InputProps={{
                inputRef: ref,
                disableUnderline: true,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem && suggestion && selectedItem.id === suggestion.id);

    return (
        <MenuItem
            {...itemProps}
            key={index}
            selected={isHighlighted}
            component="div"
            style={{
                fontSize: '12px',
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.name}
        </MenuItem>
    );
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        zIndex: 2,
        backgroundColor: 'white',
        borderRadius: '2px',
        width: '100%',
        position: 'relative',
    },
    searchIcon: {
        zIndex: 1010,
        width: '50px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
    },
    root: {
        margin: '0px',
    },
    inputRoot: {
        height: '40px',
        color: 'inherit',
        width: '100%',
        margin: '0px',
    },
    inputInput: {
        margin: '0px',
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(6),
        width: '100%',
    },
}));

export default function SearchField(props) {
    const { className } = props;
    const classes = useStyles();
    const [results, setResults] = useState([]);
    const dispatch = useDispatch();

    async function getSuggestions(phrase) {
        const value = phrase || '';

        const results = await mapService.searchMap(value, 5);

        setResults(results);
    }

    function handleOnFocus(inputValue, openMenu) {
        return async () => {
            await getSuggestions(inputValue);
            openMenu();
        };
    }

    function onItemSelected(selection) {
        if (selection) {
            dispatch(mapActions.zoomToFeature(selection));
        }
    }

    return (
        <div className={clsx(className, classes.wrapper)}>
            <div className={classes.searchIcon}>
                <SearchIcon/>
            </div>
            <Downshift id="downshift-simple"
                       onChange={onItemSelected}
                       itemToString={item => {
                           if (!item) {
                               return '';
                           }
                           return item.name;
                       }}
                       onInputValueChange={getSuggestions}
            >
                {({
                      getInputProps,
                      getItemProps,
                      getMenuProps,
                      highlightedIndex,
                      isOpen,
                      selectedItem,
                      inputValue,
                      openMenu,
                  }) => (
                    <div>
                        {renderInput({
                            fullWidth: true,
                            classes,
                            InputProps: getInputProps({
                                placeholder: 'Keresés...',
                                onFocus: handleOnFocus(inputValue, openMenu),
                            }),
                        })}
                        <div {...getMenuProps()}>
                            {isOpen ? (
                                <Paper className={classes.paper} square={true}>
                                    {results.map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem,
                                        }),
                                    )
                                    }
                                </Paper>
                            ) : null}
                        </div>
                    </div>
                )}
            </Downshift>
        </div>
    );
}
