import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import LoginButton from '../components/buttons/LoginButton';
import clsx from 'clsx';
import {connect} from 'react-redux';
import {loginActions} from 'client_shared/src/state/actions';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import {withFormik} from 'formik';
import {withRouter} from 'react-router';
import {ROUTES} from 'shared';
import {screens} from '../styles/styles';
import {withTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const styles = theme => ({
    layout: {
        minWidth: '400px',
        maxWidth: '400px',
        padding: '30px',
        marginTop: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            padding: '30px',
            marginTop: '0px',
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: 'rgba(255,0,0,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
        paddingBottom: '15px',
    },
    text: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px'
    },
    checkLabel: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px'
    },
    check: {
        '&$checked': {
            color: 'rgba(0,84,255,1)',
        },
    },
    checked: {},
    loginButton: {
        marginTop: '30px',
    },
    titleBlock: {
        marginBottom: '20px',
    },
    textBlock: {
        marginBottom: '30px',
    }
});

class PasswordRenew extends React.Component {
    render() {
        const {
            classes,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            t,
        } = this.props;

        return (
            <SecondaryScreen title={t('Text.PasswordRenewPageTitle')}>
                <div className={clsx(classes.layout)}>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit}
                    >
                        <div className={classes.textBlock}>
                            {t('Text.RenewPasswordDescription')}
                        </div>
                        <div className={classes.text}>
                            {t('Text.LoginPassword')}
                        </div>
                        <TextField
                            name="password"
                            type="password"
                            autoComplete="password"
                            error={!!(touched.password && errors.password)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            variant="outlined"
                            helperText={touched.password && errors.password}
                        />
                        <LoginButton title={t('Button.RenewPassword')} className={classes.loginButton}/>
                    </form>
                </div>
            </SecondaryScreen>
        );
    }
}

PasswordRenew.propTypes = {
    classes: PropTypes.object.isRequired,
};

const formikOptions = {
    mapPropsToValues: () => ({ username: '', password: '' }),
    validate: (values, props) => {
        let errors = {};
        const requiredFields = [
            'password',
        ];
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = props.t('Form.Mandatory');
            }
        });

        return errors;
    },
    handleSubmit: (values, { setSubmitting, props }) => {
        const { password } = values;
        const { match: { params } } = props;
        const token = params.token;
        props.dispatch(loginActions.passwordRenew(password, token));
        setSubmitting(false);
        props.history.push(getPathWithLanguage(ROUTES.LOGIN, props.i18n.language));
    },
    displayName: 'BasicForm',
};

function mapStateToProps(state) {
    return {
        login: state.login,
        error: state.error.error,
    };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(withFormik(formikOptions)(withStyles(styles)(PasswordRenew)))));