export const MAP_LAYERS = {
    TYPES: {
        BASE: 1,
        AOI: 2,
        POI: 3,
    },

    LAYER_ID_AOI: 'AOI',

    PROJECTS: {
        BODROGZUG: 'Bodrogzug',
        ANPI: 'ANPI',
    }
};
