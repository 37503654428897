import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import LoginButton from '../components/buttons/LoginButton';
import clsx from 'clsx';
import {connect} from 'react-redux';
import {loginActions} from 'client_shared/src/state/actions';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import {withFormik} from 'formik';
import {withRouter} from 'react-router';
import {ROUTES} from 'shared';
import {screens} from '../styles/styles';
import {withTranslation} from 'react-i18next';
import {SERVER_ERROR_INVALID_CREDENTIALS} from 'client_shared/src/services/baseService';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const styles = theme => ({
    layout: {
        minWidth: '400px',
        maxWidth: '400px',
        padding: '30px',
        marginTop: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            padding: '30px',
            marginTop: '0px',
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: 'rgba(255,0,0,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
        paddingBottom: '15px',
    },
    text: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px'
    },
    checkLabel: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px'
    },
    check: {
        '&$checked': {
            color: 'rgba(0,84,255,1)',
        },
    },
    checked: {},
    loginButton: {
        marginTop: '30px',
    },
    link: {
        textDecoration: "none",
        fontSize: "12px",
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
        }
    },
});

class Login extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.login.success) {
            this.props.history.push(getPathWithLanguage(ROUTES.HOME, this.props.i18n.language));
        }
    }

    render() {
        const {
            classes,
            error,
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            t,
            i18n
        } = this.props;

        return (
            <SecondaryScreen title={t('Text.LoginPageTitle')}>
                <div className={clsx(classes.layout)}>
                    {error && error.code === SERVER_ERROR_INVALID_CREDENTIALS &&
                    <div className={classes.error}>
                        {t(error.message)}
                    </div>
                    }

                    <form
                        className={classes.form}
                        onSubmit={handleSubmit}
                    >
                        <div className={classes.text}>
                            {t('Text.LoginEmailAddress')}
                        </div>
                        <TextField
                            name="username"
                            autoFocus={true}
                            autoComplete={'username'}
                            error={!!(touched.username && errors.username)}
                            onChange={handleChange}
                            value={values.username}
                            variant="outlined"
                            helperText={touched.username && errors.username}
                        />

                        <div className={classes.text}>
                            {t('Text.LoginPassword')}
                        </div>
                        <TextField
                            name="password"
                            type="password"
                            autoComplete="password"
                            error={!!(touched.password && errors.password)}
                            onChange={handleChange}
                            value={values.password}
                            variant="outlined"
                            helperText={touched.password && errors.password}
                        />
                        <Link to={getPathWithLanguage(ROUTES.PASSWORD_RESET, i18n.language)} className={classes.link}>
                            {t('Text.PasswordForgotten')}
                        </Link>


                        <LoginButton title={t('Button.Login')} className={classes.loginButton}/>
                    </form>
                </div>
            </SecondaryScreen>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

const formikOptions = {
    mapPropsToValues: () => ({ username: '', password: '' }),
    validate: (values, props) => {
        let errors = {};
        const requiredFields = [
            'username',
            'password',
        ];
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = props.t('Form.Mandatory');
            }
        });
        if (
            values.username &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
        ) {
            errors.username = props.t('Form.InvalidEmail')
        }

        return errors;
    },
    handleSubmit: (values, { setSubmitting, props }) => {
        const { username, password } = values;
        props.dispatch(loginActions.login(username, password));
        setSubmitting(false);
    },
    displayName: 'BasicForm',
};

function mapStateToProps(state) {
    return {
        login: state.login,
        error: state.error.error,
    };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(withFormik(formikOptions)(withStyles(styles)(Login)))));