import {menuService} from '../../services';
import {errorActions} from './errorActions';

export const menuActions = {
    loadMenus,
    setMenus,
    showMobileMenu,
    hideMobileMenu,
    showMobileMenuItem,
    hideMobileMenuItem,
    showMainMenuItem,
    hideMainMenuItem,
    SET_MENUS: 'MENU_SET_MENUS',
    SHOW_MOBILE_MENU: 'MENU_SHOW_MOBILE_MENU',
    HIDE_MOBILE_MENU: 'MENU_HIDE_MOBILE_MENU',
    SHOW_MOBILE_MENU_ITEM: 'MENU_SHOW_MOBILE_MENU_ITEM',
    HIDE_MOBILE_MENU_ITEM: 'MENU_HIDE_MOBILE_MENU_ITEM',
    SHOW_MAIN_MENU: 'MENU_SHOW_MAIN_MENU',
    HIDE_MAIN_MENU: 'MENU_HIDE_MAIN_MENU',
};

function loadMenus() {
    return async (dispatch) => {
        try {
            const menus = await menuService.getMenus();
            dispatch(menuActions.setMenus(menus));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setMenus(menus) {
    return {
        type: menuActions.SET_MENUS,
        payload: {
            menus,
        }
    };
}

function showMobileMenu() {
    return {
        type: menuActions.SHOW_MOBILE_MENU,
        payload: {}
    };
}

function hideMobileMenu() {
    return {
        type: menuActions.HIDE_MOBILE_MENU,
        payload: {}
    };
}

function showMobileMenuItem(selectedMainMenuItem) {
    return {
        type: menuActions.SHOW_MOBILE_MENU_ITEM,
        payload: {
            selectedMainMenuItem
        }
    };
}

function hideMobileMenuItem() {
    return {
        type: menuActions.HIDE_MOBILE_MENU_ITEM,
        payload: {}
    };
}

function showMainMenuItem(selectedMainMenuItem) {
    return {
        type: menuActions.SHOW_MAIN_MENU,
        payload: {
            selectedMainMenuItem
        }
    };
}

function hideMainMenuItem() {
    return {
        type: menuActions.HIDE_MAIN_MENU,
        payload: {}
    };
}