import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import OrderForm from '../components/checkout/OrderForm';
import {CHECKOUT} from 'shared/src';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import clsx from 'clsx';
import CheckoutSummaryCard from '../components/cards/checkout/CheckoutSummaryCard';
import {screens} from '../styles/styles';
import PaymentForm from '../components/checkout/PaymentForm';
import {useTranslation} from 'react-i18next';
import {orderActions} from 'client_shared/src/state/actions';
import {useDispatch, useSelector} from 'react-redux';
import {OrderDetailCards} from '../components/cards/checkout/OrderInfoCards';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        [theme.breakpoints.down(screens.tablet)]: {
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    column: {
        flex: 1,
        [theme.breakpoints.down(screens.mobile)]: {
            width: '100%',
        }
    },
    columnContent: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginTop: '30px',
            minWidth: theme.checkoutDialog.columnWidth[screens.desktop],
            maxWidth: theme.checkoutDialog.columnWidth[screens.desktop],
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minWidth: theme.checkoutDialog.columnWidth[screens.tablet],
            maxWidth: theme.checkoutDialog.columnWidth[screens.tablet],
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: theme.checkoutDialog.columnWidth[screens.mobile],
            maxWidth: theme.checkoutDialog.columnWidth[screens.mobile],
        }
    },
    summary: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginTop: '37px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginTop: '37px',
        },
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
}));

function Checkout() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const step = useSelector(state => state.order.step);

    useEffect(() => {
        dispatch(orderActions.setOrder(null));
        dispatch(orderActions.getInitialOrder(i18n.language));
        dispatch(orderActions.setOrderStep(CHECKOUT.STEPS.ORDER_DETAILS))
    }, [dispatch, i18n.language]);

    return (
        <SecondaryScreen title={t('Header.Checkout')}>
            <div className={classes.layout}>
                <div className={classes.column}>
                    <div className={classes.columnContent}>
                        <CheckoutSummaryCard className={classes.summary}/>
                        <OrderDetailCards className={clsx(classes.hideOnMobile, classes.hideOnTablet)}/>
                    </div>
                </div>
                <div className={classes.column}>
                    <div className={classes.columnContent}>
                        {step && step === CHECKOUT.STEPS.ORDER_DETAILS &&
                        <OrderForm/>
                        }
                        {step && step === CHECKOUT.STEPS.PAYMENT_OPTIONS &&
                        <PaymentForm/>
                        }
                    </div>
                </div>
                <div className={clsx(classes.column, classes.hideOnDesktop)}>
                    <OrderDetailCards className={classes.columnContent}/>
                </div>
            </div>
        </SecondaryScreen>
    );
}

export default Checkout;