import React from 'react';
import {withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button/Button";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    root: {},
    size: {
        minHeight: "50px",
        maxHeight: "50px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    color: {
        backgroundColor: 'rgba(239,239,239,1)',
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 'normal',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(200,200,200,1)',
        },
    },
    shape: {
        borderRadius: '12px',
    },
    expandButton: {
        minWidth: '16px',
        maxWidth: '16px',
        minHeight: '16px',
        maxHeight: '16px',
        marginLeft: '3px',
    }
});

class MoreButton extends React.Component {
    render() {
        const { classes, className, expandToggle, t, expanded } = this.props;
        return (
            <Button onClick={expandToggle}
                    className={clsx(className, classes.root, classes.color, classes.size, classes.shape)}>
                {expanded ? t('Button.Less') : t('Button.More')}
                {expanded ? <ExpandLessIcon className={classes.expandButton}/> : <ExpandMoreIcon className={classes.expandButton}/>}
            </Button>
        );
    }
}

MoreButton.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    expandToggle: PropTypes.func.isRequired,
};

MoreButton.defaultProps = {
    className: '',
};

export default withTranslation()(withStyles(styles)(MoreButton));