import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import OrderCard from '../components/cards/order/OrderCard';
import {connect} from 'react-redux';
import SecondaryScreen from '../components/screens/SecondaryScreen';
import {orderService} from 'client_shared/src/services';
import {screens} from '../styles/styles';
import {withRouter} from 'react-router';
import {useTranslation} from 'react-i18next';

const styles = theme => ({
    layout: {
        marginTop: '20px',
        minWidth: '800px',
        maxWidth: '800px',
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: '100%',
            maxWidth: '100%',
            flex: 1,
            marginTop: '0px',
        }
    },
    noOrder: {
        fontSize: '16px',
        marginTop: '20px',
        textAlign: 'center',
    }
});

function ShoppingCart(props) {
    const { classes } = props;
    const { t, i18n } = useTranslation();
    const [orders, setOrders] = useState(null);

    useEffect(() => {
        orderService.getOrders().then((orders => setOrders(orders)));
    }, [i18n.language]);

    if (!orders) {
        return null;
    }

    return (
        <SecondaryScreen title={t('Text.MyOrders')}>
            <div className={classes.layout}>
                {orders.length === 0 && <div className={classes.noOrder}>{t('Text.NoOrders')}</div>}
                {orders.map(order =>
                    <OrderCard key={order.id} order={order}/>
                )}
            </div>
        </SecondaryScreen>
    );
}

ShoppingCart.propTypes = {
    classes: PropTypes.object.isRequired,
    classNames: PropTypes.string,
};

ShoppingCart.defaultProps = {
    className: '',
};

function mapStateToProps(state) {
    const { user } = state.user;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withRouter((withMobileDialog({ breakpoint: 'xs' })(withStyles(styles)(ShoppingCart)))));
