import i18n from 'i18next';
import Backend from 'i18next-fetch-backend';
import {initReactI18next} from 'react-i18next';
import getI18NextOptions from './i18NextOptions';
import LanguageDetector from 'i18next-browser-languagedetector';

export default function initI18n(siteId) {
    return i18n
        .use(LanguageDetector)
        .use(Backend)
        .use(initReactI18next)
        .init(getI18NextOptions(siteId));
}
