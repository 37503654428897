import {orderService} from '../../services';
import {errorActions} from './errorActions';

export const orderActions = {
    getInitialOrder,
    setOrder,
    setOrderStep,
    SET_ORDER: 'ORDER_SET_ORDER',
    SET_ORDER_STEP: 'ORDER_SET_ORDER_STEP',
};

function getInitialOrder() {
    return async (dispatch) => {
        try {
            const order = await orderService.getInitialOrder();
            dispatch(orderActions.setOrder(order));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setOrder(order) {
    return {
        type: orderActions.SET_ORDER,
        payload: {
            order,
        }
    };
}

function setOrderStep(step) {
    return {
        type: orderActions.SET_ORDER_STEP,
        payload: {
            step,
        }
    };
}

