import {tourActions} from '../actions/tourActions';

const initialState = {
    tour: null,
    tours: [],
    topCaveTours: [],
    topProgrammes: [],
};

export function tour(state = initialState, action) {
    switch (action.type) {
        case tourActions.SET_TOUR:
            return {
                ...state,
                tour: action.payload.tour,
            };
        case tourActions.SET_TOURS:
            return {
                ...state,
                tours: action.payload.tours,
            };
        case tourActions.SET_TOP_CAVE_TOURS:
            return {
                ...state,
                topCaveTours: action.payload.topCaveTours,
            };
        case tourActions.SET_TOP_PROGRAMMES:
            return {
                ...state,
                topProgrammes: action.payload.topProgrammes,
            };
        default:
            return state
    }
}
