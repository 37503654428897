import {sendServerRequest} from './baseService';

export const mapService = {
    getMapLayers,
    getMapFeatures,
    searchMap,
};

async function searchMap(phrase, limit) {
    return sendServerRequest('GET', `/api/mapFeatures?phrase=${encodeURIComponent(phrase)}&limit=${limit}`);
}

async function getMapLayers() {
    return sendServerRequest('GET', `/api/mapLayers`);
}

async function getMapFeatures(layerId) {
    return sendServerRequest('GET', `/api/mapFeatures?layerId=${layerId}`);
}
