import React from 'react';
import OrderInfoCard from './OrderInfoCard';
import OrderHelpCard from './OrderHelpCard';
import {useTranslation} from 'react-i18next';

const infos = [
    {
        id: 1,
        title: "Text.OrderInfo1Title",
        description: "Text.OrderInfo1Description"
    },
    {
        id: 2,
        title: "Text.OrderInfo2Title",
        description: "Text.OrderInfo2Description"
    },
];

export function OrderDetailCards(props) {
    const { className } = props;
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div>
                {infos.map(info =>
                    <OrderInfoCard
                        key={info.id}
                        title={t(info.title)}
                        description={t(info.description)}
                    />)
                }
            </div>
            <OrderHelpCard/>
        </div>
    );
}