import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import LanguageMenu from "../menus/LanguageMenu";
import SiteLogo from "../icons/SiteLogo";
import MobileMenu from "../menus/MobileMenu";
import clsx from 'clsx';
import MainMenu from "../menus/MainMenu";
import {screens} from '../../styles/styles';
import CheckoutButton from '../buttons/CheckoutButton';
import {connect} from 'react-redux';

const styles = theme => ({
    size: {
        zIndex: 1,
        minHeight: theme.header.size[screens.desktop],
        maxHeight: theme.header.size[screens.desktop],
        [theme.breakpoints.down(screens.tablet)]: {
            minHeight: theme.header.size[screens.tablet],
            maxHeight: theme.header.size[screens.tablet],
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minHeight: theme.header.size[screens.mobile],
            maxHeight: theme.header.size[screens.mobile],
        },
    },
    color: {
        backgroundColor: theme.header.backgroundColor,
    },
    align: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    menuBarSize: {
        minWidth: theme.main.width,
        maxWidth: theme.main.width,
    },
    menuBarAlign: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    menuBarAlignTablet: {
        width: '100%',
    },
    menuBarPadding: {
        paddingTop: theme.header.padding[screens.desktop],
        paddingBottom: theme.header.padding[screens.desktop],
        [theme.breakpoints.down(screens.tablet)]: {
            paddingTop: theme.header.padding[screens.tablet],
            paddingBottom: theme.header.padding[screens.tablet],
        },
        [theme.breakpoints.down(screens.mobile)]: {
            paddingTop: theme.header.padding[screens.mobile],
            paddingBottom: theme.header.padding[screens.mobile],
        },
    },
    separator: {
        marginLeft: '10px',
        marginRight: '10px',
        minHeight: '46px',
        maxHeight: '46px',
        borderLeft: `2px solid ${theme.palette.grey['800']}`,
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
    flexible: {
        flex: '1 1 auto',
    },
    languages: {
        paddingLeft: '10px',
    },
    checkoutButton: {
        marginRight: '40px',
    }
});

class MainHeader extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={clsx(classes.size, classes.color, classes.align)}>
                <div className={clsx(classes.menuBarAlign, classes.menuBarAlignTablet, classes.hideOnDesktop)}>
                    <SiteLogo classNames={classes.menuBarPadding}/>
                    <div className={classes.flexible}/>
                    <CheckoutButton className={clsx(classes.checkoutButton, classes.hideOnMobile)}/>
                    <MobileMenu/>
                </div>
                <div
                    className={clsx(classes.menuBarAlign, classes.menuBarSize, classes.menuBarPadding, classes.hideOnMobile, classes.hideOnTablet)}>
                    <SiteLogo/>
                    <div className={classes.separator}/>
                    <LanguageMenu className={classes.languages}/>
                    <div className={classes.flexible}/>
                    <MainMenu/>
                </div>
            </div>
        );
    }
}

MainHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(MainHeader));