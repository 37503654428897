import {contentService} from '../../services';
import {CONTENT} from 'shared/src';
import {errorActions} from './errorActions';
import {simpleAction} from './baseActions';
import {SITE} from 'shared/src/constants/site';

export const contentActions = {
    loadContent,
    loadNews,
    loadHomePageNews,
    loadOfferedProgrammes,
    loadArticles,
    loadVideos,
    setArticles,
    setVideos,
    loadRules,
    loadPrivacyPolicy,
    loadCookiePolicy,
    loadCookieWarning,
    loadContentByName,
    loadDangers,
    loadHomeSummary,
    loadHeadlineContent,
    loadPromotion,
    loadPromotionBig,
    SET_CONTENT: 'CONTENT_SET_CONTENT',
    SET_HOME_SUMMARY: 'CONTENT_HOME_SUMMARY',
    SET_NEWS: 'CONTENT_SET_NEWS',
    SET_NEWS_COUNT: 'CONTENT_SET_NEWS_COUNT',
    SET_HOME_PAGE_NEWS: 'CONTENT_SET_HOME_PAGE_NEWS',
    SET_RULES: 'CONTENT_SET_RULES',
    SET_PRIVACY_POLICY: 'CONTENT_SET_PRIVACY_POLICY',
    SET_COOKIE_POLICY: 'CONTENT_SET_COOKIE_POLICY',
    SET_COOKIE_WARNING: 'CONTENT_SET_COOKIE_WARNING',
    SET_DANGERS: 'CONTENT_SET_DANGERS',
    SET_HEADLINE_CONTENT: 'CONTENT_SET_HEADLINE_CONTENT',
    SET_OFFERED_PROGRAMMES: 'CONTENT_SET_OFFERED_PROGRAMMES',
    SET_ARTICLES: 'CONTENT_SET_ARTICLES',
    SET_VIDEOS: 'CONTENT_SET_VIDEOS',
    SET_PROMOTION: 'CONTENT_SET_PROMOTION',
    SET_PROMOTION_BIG: 'CONTENT_SET_PROMOTION_BIG',
};

function loadContent(contentId) {
    return async (dispatch) => {
        try {
            if (contentId) {
                const article = await contentService.getContent(contentId);
                dispatch(setContent(article));
                return;
            }
            dispatch(setContent(null));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadContentByName(contentName) {
    return async (dispatch) => {
        try {
            const content = await contentService.getContentByName(contentName);
            dispatch(setContent(content));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setContent(content) {
    return {
        type: contentActions.SET_CONTENT,
        payload: {
            content,
        }
    };
}


function loadHomeSummary() {
    return async (dispatch) => {
        try {
            const homeSummary = await contentService.getContentByName(CONTENT.HOME_SUMMARY);
            dispatch(simpleAction(contentActions.SET_HOME_SUMMARY, { homeSummary }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadNews(start, end) {
    return async (dispatch) => {
        try {
            const news = await contentService.getContents({ type: CONTENT.TYPE.NEWS, start, end, sort: 'index', order: 'ASC',titleTranslated: true, summaryTranslated: true, bodyTranslated: true });
            dispatch(setNews(news));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setNews(news) {
    return {
        type: contentActions.SET_NEWS,
        payload: {
            news,
        }
    };
}

function loadHomePageNews(count = CONTENT.MAX_HOME_PAGE_NEWS_COUNT, siteId = SITE.BODROGZUG) {
    return async (dispatch) => {
        try {
            const homePageNews = await contentService.getContents({ type: CONTENT.TYPE.NEWS, start: 1, end: count, sort: 'homePageIndex', order: 'ASC', titleTranslated: true, summaryTranslated: true, bodyTranslated: true, descriptionTranslated: siteId === SITE.ANP });
            dispatch(setHomePageNews(homePageNews));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setHomePageNews(homePageNews) {
    return {
        type: contentActions.SET_HOME_PAGE_NEWS,
        payload: {
            homePageNews,
        }
    };
}

function loadOfferedProgrammes(ids) {
    return async (dispatch) => {
        try {
            const offeredProgrammes = await contentService.getContents({ ids });
            dispatch(setOfferedProgrammes(offeredProgrammes));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setOfferedProgrammes(offeredProgrammes) {
    return {
        type: contentActions.SET_OFFERED_PROGRAMMES,
        payload: {
            offeredProgrammes,
        }
    };
}

function loadArticles(pageId) {
    return async (dispatch) => {
        try {
            const articles = await contentService.getContents({ pageId, type: CONTENT.TYPE.ARTICLE, titleTranslated: true, summaryTranslated: true, bodyTranslated: true });
            const sortedArticles = articles.sort((article1, article2) => {
                if (article1.id < article2.id) {
                    return -1;
                }
                if (article1.id > article2.id) {
                    return 1;
                }
                return 0;
            });

            dispatch(setArticles(sortedArticles));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setArticles(articles) {
    return {
        type: contentActions.SET_ARTICLES,
        payload: {
            articles,
        }
    };
}

function loadVideos(pageId) {
    return async (dispatch) => {
        try {
            const videos = await contentService.getContents({ pageId, type: CONTENT.TYPE.VIDEO, titleTranslated: true, summaryTranslated: true });
            const sortedVideos = videos.sort((video1, video2) => {
                if (video1.id < video2.id) {
                    return -1;
                }
                if (video1.id > video2.id) {
                    return 1;
                }
                return 0;
            });
            dispatch(setVideos(sortedVideos));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setVideos(videos) {
    return {
        type: contentActions.SET_VIDEOS,
        payload: {
            videos,
        }
    };
}

function loadRules() {
    return async (dispatch) => {
        try {
            const rules = await contentService.getContents({ type: CONTENT.TYPE.OTHER });
            dispatch(setRules(rules));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setRules(rules) {
    return {
        type: contentActions.SET_RULES,
        payload: {
            rules,
        }
    };
}

function loadPrivacyPolicy() {
    return async (dispatch) => {
        try {
            const privacyPolicy = await contentService.getContentByName('privacy-policy');
            dispatch(setPrivacyPolicy(privacyPolicy));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setPrivacyPolicy(privacyPolicy) {
    return {
        type: contentActions.SET_PRIVACY_POLICY,
        payload: {
            privacyPolicy,
        }
    };
}

function loadCookiePolicy() {
    return async (dispatch) => {
        try {
            const cookiePolicy = await contentService.getContentByName('cookie-policy');
            dispatch(setCookiePolicy(cookiePolicy));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setCookiePolicy(cookiePolicy) {
    return {
        type: contentActions.SET_COOKIE_POLICY,
        payload: {
            cookiePolicy,
        }
    };
}

function loadCookieWarning() {
    return async (dispatch) => {
        try {
            const cookieWarning = await contentService.getContentByName('cookie-warning');
            dispatch(setCookieWarning(cookieWarning));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setCookieWarning(cookieWarning) {
    return {
        type: contentActions.SET_COOKIE_WARNING,
        payload: {
            cookieWarning,
        }
    };
}

function loadDangers() {
    return async (dispatch) => {
        try {
            const dangers = await contentService.getContents({ type: CONTENT.TYPE.DANGER, titleTranslated: true, summaryTranslated: true, enabled: true });
            dispatch(setDangers(dangers));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setDangers(dangers) {
    return {
        type: contentActions.SET_DANGERS,
        payload: {
            dangers,
        }
    };
}

function loadHeadlineContent() {
    return async (dispatch) => {
        try {
            const headlineContents = await contentService.getContents({ type: CONTENT.TYPE.NEWS, headline: true, titleTranslated: true, summaryTranslated: true, bodyTranslated: true });
            if (headlineContents && headlineContents.length > 0) {
                dispatch(setHeadlineContent(headlineContents[0]));
            }
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setHeadlineContent(headlineContent) {
    return {
        type: contentActions.SET_HEADLINE_CONTENT,
        payload: {
            headlineContent,
        }
    };
}

function loadPromotion(promotionId) {
    return async (dispatch) => {
        try {
            if (!promotionId) {
                dispatch(setPromotion(null));
                return;
            }

            const promotion = await contentService.getContent(promotionId);
            dispatch(setPromotion(promotion));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setPromotion(promotion) {
    return {
        type: contentActions.SET_PROMOTION,
        payload: {
            promotion,
        }
    };
}

function loadPromotionBig(promotionId) {
    return async (dispatch) => {
        try {
            if (!promotionId) {
                dispatch(setPromotionBig(null));
                return;
            }

            const promotionBig = await contentService.getContent(promotionId);
            dispatch(setPromotionBig(promotionBig));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setPromotionBig(promotionBig) {
    return {
        type: contentActions.SET_PROMOTION_BIG,
        payload: {
            promotionBig,
        }
    };
}