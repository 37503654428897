import React, {useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {useDispatch, useSelector} from 'react-redux';
import {SERVER_ERROR_INVALID_CREDENTIALS} from '../../services/baseService';
import {makeStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {errorActions} from '../../state/actions/errorActions';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

export function ErrorBar(props) {
    const {message, onClose, hasCloseIcon = true} = props;
    const [open, setOpen] = useState(true);
    const classes = useStyles();

    function handleClose() {
        setOpen(false);
        onClose && onClose();
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            autoHideDuration={hasCloseIcon ? null : 6000}
            className={classes.margin}
            action={hasCloseIcon ? [
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>,
            ] : null}
        >
            <SnackbarContent
                className={clsx(classes.error)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={clsx(classes.message, classes.error)}>
                        <ErrorIcon className={clsx(classes.icon, classes.iconVariant)}/>
                        {message}
                    </span>
                }
                action={hasCloseIcon ? [
                    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                        <CloseIcon className={classes.icon}/>
                    </IconButton>,
                ] : null}
            />
        </Snackbar>
    );
}