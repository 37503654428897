import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {screens} from '../../../styles/styles';
import {connect} from 'react-redux';
import {userActions} from 'client_shared/src/state/actions';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    layout: {
        zIndex: 100,
        position: 'fixed',
        minHeight: '100px',
        bottom: '0px',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.95)',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    rowLayout: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *:not(:first-child)': {
            marginTop: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            '& > *:not(:first-child)': {
                marginTop: '0px',
            },
        },
    },
    text: {
        '& > *': {
            margin: 0,
            color: 'rgba(155,155,155,1)',
            fontSize: '17px',
            letterSpacing: '0.2px',
            lineHeight: '22px',
        },
        '& h5': {
            margin: '0 0 10px 0',
            color: 'rgba(155,155,155,1)',
            fontSize: '17px',
            letterSpacing: '0.2px',
            lineHeight: '22px',
        },
        '& p': {
            margin: '0 0 3px 0',
            color: 'rgba(155,155,155,1)',
            fontSize: '17px',
            letterSpacing: '0.2px',
            lineHeight: '22px',
        }
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '15px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '20px',
        },
        '& > * + *': {
            marginLeft: '15px',
            [theme.breakpoints.up('lg')]: {
                marginLeft: '20px',
            }
        }
    },
    button: {
        whiteSpace: 'nowrap',
        height: '40px',
        border: '0px',
        borderRadius: '25px',
        backgroundColor: 'rgba(18,218,173,1)',
        color: 'rgba(255,255,255,1)',
        fontSize: '15px',
        lineHeight: '18px',
        textAlign: 'center',
        padding: '0 15px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.up(screens.desktop)]: {
            marginLeft: '10px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginLeft: '10px',
        },
        [theme.breakpoints.down(screens.mobile)]: {},
    },
    buttonSecondary: {
        backgroundColor: 'rgba(0,0,0,0.95)',
        border: '1px solid #ffffff',
    }
});

class CookieCard extends React.Component {
    onDetailsClick = () => {
        this.props.dispatch(userActions.setCookiesPopupOpen(true));
    };

    onApproveClick = () => {
        this.props.dispatch(userActions.setCookiesAccepted({
            importantCookiesApproved: true,
            convenienceCookiesApproved: true,
            statisticalCookiesApproved: true
        }));
    };

    render() {
        const { classes, className, cookiesAccepted, cookieWarning, t } = this.props;

        if (cookiesAccepted && cookiesAccepted.importantCookiesApproved) {
            return null;
        }

        if (!cookieWarning) {
            return null;
        }

        return (
            <div className={clsx(classes.layout, className)}>
                <div className={classes.rowLayout}>
                    <div className={clsx(classes.text)}
                         dangerouslySetInnerHTML={createMarkup(cookieWarning.summary)}/>
                    <div className={classes.buttonRow}>
                        <button onClick={this.onDetailsClick}
                                className={clsx(classes.button, classes.buttonSecondary)}>{t('Text.AdjustCookies')}</button>
                        <button onClick={this.onApproveClick}
                                className={classes.button}>{t('Text.AcceptCookies')}</button>
                    </div>
                </div>
            </div>
        )
    }
}

CookieCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

CookieCard.defaultProps = {
    className: '',
};

const mapStateToProps = (state) => {
    const { cookiesAccepted } = state.user;
    const { cookieWarning } = state.content;

    return {
        cookiesAccepted,
        cookieWarning
    }
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(CookieCard)));