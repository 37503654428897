import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import clsx from 'clsx';
import {screens} from '../../styles/styles';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES} from 'shared';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up(screens.desktop)]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            flexDirection: 'column',
        },
    },

    layout: {
        minWidth: '280px',
        maxWidth: '280px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minWidth: '176px',
            maxWidth: '176px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: 'initial',
            maxWidth: 'initial',
        },
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '18px',
        borderBottom: '2px solid rgba(91,91,91,1)',
        marginBottom: '9px',
    },
    rowPhone: {
        paddingTop: '52px',
    },
    rowEmail: {},
    icon: {
        color: 'rgba(255,159,0,1)',
        minHeight: '20px',
        maxHeight: '20px',
        minWidth: '20px',
        maxWidth: '20px',
        marginRight: '10px',
    },
    title: {
        color: 'rgba(255,159,0,1)',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '16px',
        textTransform: 'uppercase',
    },
    phone: {
        color: 'rgba(255,255,255,1)',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '29px',
    },
    email: {
        color: 'rgba(255,255,255,1)',
        textDecoration: 'underline',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19px',
    },
    facebook: {
        paddingTop: '18px',
        '& > a': {
            color: 'rgba(255,159,0,1)',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '16px',
        }
    },
    privacyPolicyLink: {
        display: 'block',
        paddingTop: '18px',
        color: 'rgba(255,159,0,1)',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '16px',
    }
});

class ContactFooter extends React.Component {
    createMarkup = (text) => {
        return { __html: text };
    };

    render() {
        const { classes, className, t, i18n } = this.props;
        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.layout}>
                    <div className={clsx(classes.row, classes.rowEmail)}>
                        <EmailIcon className={classes.icon}/>
                        <div className={classes.title}>
                            {t('Text.Email')}
                        </div>
                    </div>
                    <div className={classes.email}>
                        <a href={`mailto:${t('Text.EmailAddress')}`}
                           className={classes.email}>{t('Text.EmailAddress')}</a>
                    </div>
                    <div className={clsx(classes.row, classes.rowPhone)}>
                        <PhoneIcon className={classes.icon}/>
                        <div className={classes.title}>
                            {t('Text.Phone')}
                        </div>
                    </div>
                    <div className={classes.phone} dangerouslySetInnerHTML={this.createMarkup(t('Text.PhoneNumber'))}/>
                    <div className={clsx(classes.facebook)}>
                        <a href={t('Text.FacebookUrl')} rel="noopener noreferrer"
                           target="_blank">{t('Text.Facebook')}</a>
                    </div>
                    <Link to={getPathWithLanguage(ROUTES.PRIVACY_POLICY, i18n.language)}
                          className={classes.privacyPolicyLink}>
                        {t('Text.PrivacyPolicy')}
                    </Link>
                    <Link to={getPathWithLanguage(ROUTES.COOKIE_POLICY, i18n.language)}
                          className={classes.privacyPolicyLink}>
                        {t('Text.CookiePolicy')}
                    </Link>
                </div>
            </div>

        )
    }
}

ContactFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(ContactFooter));