import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckoutButton2 from '../buttons/CheckoutButton2';
import {useSelector} from 'react-redux';
import {withTranslation} from 'react-i18next';
import numeral from 'numeral';
import {tourModule} from 'shared/src/modules/tour';

const styles = theme => ({
    root: {},
    priceRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '14px',
    },
    description: {
        color: 'rgba(155,155,155,1)',
        fontSize: '14px',
        lineHeight: '16px',
    },
    price: {
        color: theme.palette.text.primary,
        fontSize: '24px',
        fontWeight: '900',
        lineHeight: '28px',
    }
});

const TicketInfoCard = (props) => {
    const { classes, className, t } = props;

    let price = null;

    const tour = useSelector(state => state.tour.tour);

    if (tour) {
        const defaultProduct = tourModule.getDefaultTourProduct(tour);
        price = defaultProduct.price;
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.priceRow}>
                <div className={classes.description}>
                    {t('Text.DailyTicketPrice')}
                </div>
                <div className={classes.price}>
                    {price && `${numeral(price).format('0,0').replace(/,/g, ' ')} Ft`}
                </div>
            </div>
            <CheckoutButton2/>
        </div>
    )
}

TicketInfoCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

TicketInfoCard.defaultProps = {
    className: '',
};


export default withTranslation()(withStyles(styles)(TicketInfoCard));