import {useCallback, useContext, useEffect, useState} from 'react';
import {MAP_LAYERS} from 'shared/src';
import {mapService} from '../../services';
import {useTranslation} from 'react-i18next';
import {GoogleMapContext} from '@googlemap-react/core';
import {toWgs84} from 'reproject';
import epsg from 'epsg';
import {useDispatch, useSelector} from 'react-redux';
import {centerMapDone, mapActions} from '../../state/actions/mapActions';

export default function AOILayer() {
    const { i18n } = useTranslation();
    const { state } = useContext(GoogleMapContext);
    const [features, setFeatures] = useState([]);
    const centerMap = useSelector(state => state.map.centerMap);
    const dispatch = useDispatch();

    const resetMap = useCallback((features) => {
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        features.forEach(feature => {
            feature.getGeometry().forEachLatLng(latlng => {
                bounds.extend(latlng);
            });
        });

        state.map.fitBounds(bounds);
    }, [state.map]);

    useEffect(() => {
        async function getLayers() {
            const aoiFeatures = await mapService.getMapFeatures(MAP_LAYERS.LAYER_ID_AOI);
            const aoiFeature = aoiFeatures[0];
            const geoJson = {
                type: "Feature",
                geometry: toWgs84(aoiFeature.geom, 'EPSG:23700', epsg),
            };

            // eslint-disable-next-line no-undef
            const polygonLayer = new google.maps.Data();
            const features = polygonLayer.addGeoJson(geoJson);

            setFeatures(features);

            resetMap(features);
        }

        if (state.map) {
            getLayers().catch(error => console.log(error));
        }

    }, [i18n, state.map, resetMap]);

    useEffect(() => {
        if (centerMap) {
            resetMap(features);
            dispatch(mapActions.centerMapDone());
        }
    }, [centerMap, dispatch, features, resetMap]);

    return null;
}