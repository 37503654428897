import React from 'react';
import MainScreen from "../components/screens/MainScreen";
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import WavesIcon from '@material-ui/icons/Waves';
import MapIcon from '@material-ui/icons/Map';
import GavelIcon from '@material-ui/icons/Gavel';
import RuleCard from '../components/cards/rule/RuleCard';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import {screens} from '../styles/styles';
import MainContactsCard from '../components/cards/mainInfo/MainContactsCard';
import DangerCard from '../components/cards/danger/DangerCard';
import _ from 'lodash';
import {contentActions} from 'client_shared/src/state/actions';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
    icon: {
        minHeight: '30px',
        maxHeight: '30px',
        minWidth: '30px',
        maxWidth: '30px',
    },
    panel1: {
        color: 'rgba(240,217,53,1)',
    },
    panel2: {
        color: 'rgba(1,155,167,1)',
    },
    panel3: {
        color: 'rgba(255,32,0,1)',
    },
    panel4: {
        color: 'rgba(0,51,255,1)',
    },
    pageHeader: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginTop: '50px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginTop: '30px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginTop: '30px',
        },

    },
    rulesSummaryCard: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginLeft: '18px',
            marginRight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    dangerCard: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginBottom: '30px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginLeft: '18px',
            marginRight: '18px',
            marginBottom: '30px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginLeft: '10px',
            marginRight: '10px',
            marginBottom: '10px',
        },
    },
    ruleCardBlock: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '50px',
            marginTop: '50px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '18px',
            marginRight: '18px',
            marginBottom: '30px',
            marginTop: '30px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    ruleCard: {
        [theme.breakpoints.up(screens.desktop)]: {
            '&:not(:first-child)': {
                marginTop: '30px',
            },
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            '&:not(:first-child)': {
                marginTop: '30px',
            }
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '10px',
            '&:last-child': {
                marginBottom: '20px',
            },
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    contactsCard: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginTop: '50px',
            marginLeft: '42px',
            minWidth: '307px',
            maxWidth: '307px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginTop: '30px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '50px',
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
}));

const ruleList = [
    {
        id: 1,
        articleName: 'rule1',
        icon: (<ErrorIcon/>),
        classes: 'panel1',
    },
    {
        id: 2,
        articleName: 'rule2',
        icon: (<MapIcon/>),
        classes: 'panel2',
    },
    {
        id: 3,
        articleName: 'rule3',
        icon: (<GavelIcon/>),
        classes: 'panel3',
    },
    {
        id: 4,
        articleName: 'rule4',
        icon: (<WavesIcon/>),
        classes: 'panel4',
    }
];

function Rules() {
    const classes = useStyles();
    const rules = useSelector(state => state.content.rules);
    const dangers = useSelector(state => state.content.dangers);

    return (
        <MainScreen
            sideMenu={(<MainContactsCard className={clsx(classes.hideOnMobile, classes.contactsCard)}/>)}>
            <div className={classes.pageHeader}/>
            {dangers && dangers.filter(danger => danger.enabled && danger.title && danger.summary).map(danger => (
                <DangerCard key={danger.id} className={classes.dangerCard} danger={danger}/>))}
            <div className={classes.ruleCardBlock}>
                {rules && ruleList.map(rule => {
                    const article = rules.find(articleItem => articleItem.name === rule.articleName);
                    if (!article) {
                        return null;
                    }
                    return <RuleCard
                        key={rule.id}
                        icon={React.cloneElement(rule.icon, { className: clsx(classes.icon, classes[rule.classes]) })}
                        title={article.title}
                        summary={article.summary}
                        text={article.body}
                        className={classes.ruleCard}
                    />

                })}
            </div>
            <MainContactsCard
                className={clsx(classes.hideOnDesktop, classes.hideOnTablet, classes.contactsCard)}/>
        </MainScreen>
    );
}

Rules.initState = (environment) => {
    const { storeState, dispatch } = environment;

    const promises = [];

    if (_.isEmpty(storeState.content.rules)) {
        promises.push(dispatch(contentActions.loadRules()));
    }

    if (_.isEmpty(storeState.content.dangers)) {
        promises.push(dispatch(contentActions.loadDangers()));
    }

    return promises;
};

export default Rules;
