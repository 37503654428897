import {useContext, useEffect, useState} from 'react';
import {GoogleMapContext} from '@googlemap-react/core';
import {centerMapDone} from '../../state/actions/mapActions';

export default function RasterLayer(props) {
    const { layer } = props;
    const { state } = useContext(GoogleMapContext);
    const [rasterLayer, setRasterLayer] = useState(null);

    useEffect(() => {
        function createLayer() {
            const layerID = `IMAGE_LAYER_${layer.id}`;

            const rasterMapType = new google.maps.ImageMapType({
                name: layerID,
                getTileUrl: function (coord, zoom) {
                    console.log(coord);
                    const url = layer.url
                        .replace('{x}', coord.x)
                        .replace('{y}', coord.y)
                        .replace('{z}', zoom);
                    return url;
                },
                tileSize: new google.maps.Size(256, 256),
                minZoom: layer.minZoom,
                maxZoom: layer.maxZoom,
            });

            setRasterLayer(rasterMapType);

            if (layer.visible) {
                state.map.overlayMapTypes.insertAt(0, rasterMapType);
            }
        }

        if (rasterLayer) {
            return;
        }

        if (state.map) {
            createLayer();
        }

    }, [state.map, rasterLayer]);

    useEffect(() => {
        if (!state.map || !rasterLayer) {
            return;
        }

         if (layer.visible) {
             state.map.overlayMapTypes.insertAt(0, rasterLayer);
             return;
         }

        const index = state.map.overlayMapTypes.indexOf(rasterLayer);
        if (index >= 0) {
            state.map.overlayMapTypes.removeAt(index);
        }
    }, [layer.visible, rasterLayer, state.map]);

    return null;
}