import {menuActions} from '../actions/menuActions';

const initialState = {
    menus: [],
    isMobileMenuOpen: false,
    selectedMobileMenuItem: null,
    selectedMainMenuItem: null,
};

export function menu(state = initialState, action) {
    switch (action.type) {
        case menuActions.SET_MENUS:
            return {
                menus: action.payload.menus,
            };
        case menuActions.SHOW_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuOpen: true,
            };
        case menuActions.HIDE_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuOpen: false,
            };
        case menuActions.SHOW_MOBILE_MENU_ITEM:
            return {
                ...state,
                selectedMobileMenuItem: action.payload.selectedMainMenuItem,
            };
        case menuActions.HIDE_MOBILE_MENU_ITEM:
            return {
                ...state,
                selectedMobileMenuItem: null,
            };
        case menuActions.SHOW_MAIN_MENU:
            return {
                ...state,
                selectedMainMenuItem: action.payload.selectedMainMenuItem,
            };
        case menuActions.HIDE_MAIN_MENU:
            return {
                ...state,
                selectedMainMenuItem: null,
            };
        default:
            return state
    }
}
