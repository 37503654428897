import React from 'react';
import LandscapeIcon from '@material-ui/icons/Landscape';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import {screens} from '../../styles/styles';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES} from 'shared/src/constants/routes';

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        textDecoration: 'none',
        paddingRight: '15px',
        paddingLeft: '10px',
        [theme.breakpoints.down(screens.tablet)]: {
            paddingLeft: "20px",
        },
        [theme.breakpoints.down(screens.mobile)]: {
            paddingLeft: "15px",
        },
    },
    icon: {
        color: theme.palette.text.primaryInverse,
        minHeight: '36px',
        maxHeight: '36px',
        minWidth: '36px',
        maxWidth: '36px',
        [theme.breakpoints.down(screens.tablet)]: {
            minHeight: '30px',
            maxHeight: '30px',
            minWidth: '30px',
            maxWidth: '30px',
        },
    },
    title: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "24px",
        [theme.breakpoints.down(screens.tablet)]: {
            paddingLeft: "20px",
        },
        [theme.breakpoints.down(screens.mobile)]: {
            paddingLeft: "8px",
        },
    },
    mainTitle: {
        color: theme.palette.text.primaryInverse,
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "21px",
        [theme.breakpoints.down(screens.tablet)]: {
            fontSize: "24px",
            lineHeight: "28px",
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: "14px",
            lineHeight: "16px",
        },
    },
    subTitle: {
        color: theme.palette.text.secondaryInverse,
        fontSize: "13px",
        lineHeight: "15px",
        paddingTop: '3px',
        [theme.breakpoints.down(screens.tablet)]: {
            fontSize: "10px",
            lineHeight: "11px",
            paddingTop: "2px",
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: "10px",
            lineHeight: "11px",
            paddingTop: "2px",
        },
    }
});

class SiteLogo extends React.Component {
    render() {
        const { classes, className, t, i18n } = this.props;
        return (
            <Link to={getPathWithLanguage(ROUTES.HOME, i18n.language)} className={clsx(classes.root, className)}>
                <LandscapeIcon className={classes.icon}/>
                <div className={classes.title}>
                    <span className={classes.mainTitle}>
                        {t('Button.Bodrogzug')}
                    </span>
                    <span className={classes.subTitle}>
                        {t('Text.BodrogzugSubTitle')}
                    </span>
                </div>
            </Link>
        );
    }
}

SiteLogo.propTypes = {};

export default withTranslation()(withStyles(styles)(SiteLogo));