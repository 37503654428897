export const shoppingCartActions = {
    showShoppingCart,
    hideShoppingCart,
    SHOW_SHOPPING_CART: 'SHOPPING_CART_SHOW_SHOPPING_CART',
    HIDE_SHOPPING_CART: 'SHOPPING_CART_HIDE_SHOPPING_CART',
};

function showShoppingCart() {
    return {
        type: shoppingCartActions.SHOW_SHOPPING_CART,
        payload: {
        }
    };
}

function hideShoppingCart() {
    return {
        type: shoppingCartActions.HIDE_SHOPPING_CART,
        payload: {
        },
    };
}

