import React, {useEffect, useRef} from 'react';
import Routes from "./Routes";
import 'url-search-params-polyfill';
import _ from 'lodash';
import {contentActions, menuActions, settingActions, simpleAction} from 'client_shared/src/state/actions';
import {useTranslation} from 'react-i18next';
import {useDispatch, useStore} from 'react-redux';
import {useLocation} from 'react-router';
import {rootStoreActions} from 'client_shared/src/state/reducers';
import GlobalCss from '../styles/GlobalCss';

function App() {
    const { i18n: { language } } = useTranslation();
    const previousLanguageRef = useRef(language);
    const store = useStore();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const languageChanged = (previousLanguageRef.current !== language);
        if (languageChanged) {
            dispatch(simpleAction(rootStoreActions.RESET_STORE, {}));
        }
        previousLanguageRef.current = language;

        Promise.all(App.initState({
            dispatch,
            storeState: store.getState(),
            path: location.pathname,
            searchParams: location.search
        }));
    }, [language, dispatch, store, location]);

    return (
        <GlobalCss>
            <Routes/>
        </GlobalCss>
    );
}

App.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.setting.settings)) {
        promises.push(dispatch(settingActions.loadSettings()));
    }

    if (_.isEmpty(storeState.menu.menus)) {
        promises.push(dispatch(menuActions.loadMenus()));
    }

    if (_.isEmpty(storeState.content.cookieWarning)) {
        promises.push(dispatch(contentActions.loadCookieWarning()));
    }

    promises.push(...Routes.initState(environment));


    return promises;
};

export default App;
