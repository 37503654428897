import {simpleAction} from './baseActions';

export const errorActions = {
    setError,
    SET_ERROR: 'ERROR_SET_ERROR',
};

function setError(error) {
    console.log(error);
    return simpleAction(errorActions.SET_ERROR, { error });
}

