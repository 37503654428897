import {pageActions} from '../actions/pageActions';

const initialState = {
    pages: [],
    page: null,
    parentPage: null,
};

export function page(state = initialState, action) {
    switch (action.type) {
        case pageActions.SET_PAGES:
            return {
                ...state,
                pages: action.payload.pages,
            };
        case pageActions.SET_PAGE:
            return {
                ...state,
                page: action.payload.page,
            };
        case pageActions.SET_PARENT_PAGE:
            return {
                ...state,
                parentPage: action.payload.parentPage,
            };
        default:
            return state
    }
}
