import {useContext, useEffect, useState} from 'react';
import {GoogleMapContext} from '@googlemap-react/core';
import {useDispatch, useSelector} from 'react-redux';
import {hideFeatureInfo, mapActions} from '../../state/actions/mapActions';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

export default function InfoWindow() {
    const [infoWindow, setInfoWindow] = useState(null);
    const { state } = useContext(GoogleMapContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const selection = useSelector(state => state.map.selection);

    useEffect(() => {
        if (state.map && !infoWindow) {
            // eslint-disable-next-line no-undef
            const infoWindow = new google.maps.InfoWindow();
            infoWindow.addListener('closeclick', () => {
                dispatch(mapActions.hideFeatureInfo());
            });

            setInfoWindow(infoWindow);
        }
    }, [dispatch, infoWindow, state.map]);

    useEffect(() => {
        function getValueFromObject(valueObject) {
            let value = valueObject.value;
            if (_.isString(value)) {
                value = parseFloat(value);
            }

            let decimals = valueObject.decimals;
            if (_.isString(decimals)) {
                decimals = parseInt(decimals, 10);
            }

            const decimalsMultiplier = 10 ** decimals;

            value = Math.round(value * decimalsMultiplier) / decimalsMultiplier;

            return `${value} ${valueObject.unit}`;
        }

        function composeMetaInfoTable(metaInfo) {
            const rows = Object.keys(metaInfo).map(key => {
                let value = metaInfo[key];

                if (_.isObject(value)) {
                   value = getValueFromObject(value);
                }

                if (`${value}`.startsWith('www.')) {
                    value = `<a href="http://${value}">${value}</a>`;
                }

                if (`${value}`.startsWith('http')) {
                    value = `<a href="${value}">${value}</a>`;
                }

                const upperCaseKey = key.charAt(0).toUpperCase() + key.slice(1);

                return `
                    <tr>
                        <td>
                            ${t(`MapInfo.${upperCaseKey}`, upperCaseKey)}                            
                        </td>                        
                        <td>
                            ${value}
                        </td>
                    </tr>
                `;
            });

            return `
                <table>
                    <tbody>
                    ${rows.join('')}
                    </tbody>
                </table>
            `;
        }

        function composeImages(imagesString) {
            const images = imagesString.split(';');
            return `
                <div style='width: 100%'>
                    <img style='display: block; margin-left: auto; margin-right: auto; width: 80%;' src='/content/maps/${images[0]}' />              
                </div>
            `;
        }

        function composeVideos(videosString) {
            const videos = videosString.split(';');
            return `
                <div style='width: 100%'>
                    <video controls style='display: block; margin-left: auto; margin-right: auto; width: 80%;'>
                        <source src='/content/maps/${videos[0]}' type='video/mp4'>
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            `;
        }

        if (!infoWindow) {
            return;
        }

        if (selection) {
            const feature = selection.mapFeature.getProperty('feature');

            const metaInfo = feature.meta ? composeMetaInfoTable(feature.meta, feature.photo) : '';
            const images = feature.photo ? composeImages(feature.photo) : '';
            const videos = feature.video ? composeVideos(feature.video) : '';

            const content = `
                ${feature.name ? `<h1 class='info-header'>${feature.name}</h1>` : ''}
                ${feature.description ? `<p>${feature.description}</p>` : ''}
                ${metaInfo}
                ${images}             
                ${videos}             
            `;

            infoWindow.setContent(content); // show the html variable in the infowindow
            //infoWindow.setPosition(selection.mapFeature.getGeometry().get()); // anchor the infowindow at the marker

            // eslint-disable-next-line no-undef
            infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -30) }); // move the infowindow up slightly to the top of the marker icon
            infoWindow.setPosition(selection.clickPosition);
            infoWindow.open(state.map);
        } else {
            infoWindow.close();
        }

    }, [selection, infoWindow, state.map, t]);

    return null;
}
