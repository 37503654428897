import React from 'react';
import {makeStyles} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import {useFormikContext} from 'formik';
import {createMarkup} from 'shared/src/helpers/componentHelper';


const useStyles = makeStyles(theme => ({
    checkBoxLabel: {
        ...theme.typography.paragraph3,
        color: '#17171A',
        '& a': {
            ...theme.typography.linkSecondary,
        }
    },
    checkBoxRoot: {
        alignItems: 'flex-start',
    },
    checkBox: {
        color: 'rgba(255,159,0,1)',
        '&$checked': {
            color: 'rgba(255,159,0,1)',
        },
    },
    checkBoxLong: {
        paddingTop: '0px',
    },
    checked: {
        color: 'green',
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '4px',
        width: '20px',
        height: '20px',
        boxShadow: 'inset 0 0 0 2px #D9D9D9, inset 0 -1px 0 #D9D9D9',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
            boxShadow: 'inset 0 0 0 2px #5C5C66, inset 0 -1px 0 #5C5C66',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        color: 'black',
        backgroundColor: '#B0E645',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        boxShadow: 'inset 0 0 0 2px #96CC29, inset 0 -1px 0 #96CC29',
        '&:before': {
            display: 'block',
            width: '20px',
            height: '20px',
            color: 'black',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage:
                "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 22 22'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B0E645',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            boxShadow: 'inset 0 0 0 2px #96CC29, inset 0 -1px 0 #96CC29',
        },
    },
}));

export default function CheckboxComponent(props) {
    const { className, label, onChange, propertyName, checkBoxLabelClassName, disabled = false } = props;

    const { touched, errors, values, handleChange } = useFormikContext();

    const classes = useStyles();

    return (
        <div className={className}>
            <FormControlLabel
                control={
                    <Checkbox
                        name={propertyName}
                        checked={values[propertyName]}
                        onChange={onChange || handleChange}
                        className={classes.root}
                        color="default"
                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
                        icon={<span className={classes.icon}/>}
                        disabled={disabled}
                        classes={{
                            root: clsx(classes.checkBox, classes.checkBoxLong),
                            checked: classes.checked
                        }}
                    />
                }
                classes={{ root: classes.checkBoxRoot, label: clsx(classes.checkBoxLabel, checkBoxLabelClassName) }}
                label={
                    <span className={classes.description} dangerouslySetInnerHTML={
                        createMarkup(label)
                    }/>
                }
            />
            {touched[propertyName] && errors[propertyName] &&
            <FormHelperText
                error={!!(touched[propertyName] && errors[propertyName])}>
                {touched[propertyName] && errors[propertyName]}
            </FormHelperText>
            }
        </div>
    );

}
