export const CONTENT = {
    TYPE: {
        NEWS: 1,
        ARTICLE: 2,
        OTHER: 3,
        DANGER: 4,
        PROMOTION: 5,
        TOP_PROGRAMMES: 7,
        FAQ: 8,
        PAGE_CONTENT: 9,
        VIDEO: 10,
    },

    DANGER_TYPE: {
        INFO: 1,
        DANGER: 2,
    },

    ARTICLE_WATER_TOUR: 'watertour',
    HOME_SUMMARY: 'home-summary',
    COOKIE_WARNING: 'cookie-warning',
    ADVANCED_TOUR_LIST_POPUP: 'advanced-tours-message',

    MAX_HOME_PAGE_NEWS_COUNT: 3,
    MAX_HOME_PAGE_TOP_CAVE_TOUR_COUNT: 3,
    MAX_HOME_PAGE_TOP_PROGRAM_COUNT: 3,
};
