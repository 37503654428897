import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {matchPath, useLocation} from 'react-router';
import Home from '../screens/Home';
import Rules from '../screens/Rules';
import News from '../screens/News';
import Maps from '../screens/Maps';
import WaterTourArticle from '../screens/WaterTourArticle';
import Article from '../screens/Article';
import Login from '../screens/Login';
import PasswordReset from '../screens/PasswordReset';
import {ROUTES} from 'shared/src';
import ShoppingCart from '../screens/ShoppingCart';
import NotFound from '../screens/NotFound';
import Logout from '../screens/Logout';
import OrderAcknowledgement from '../screens/OrderAcknowledgement';
import {useTranslation} from 'react-i18next';
import {getLanguageFromLocation, getPathParserWithLanguage} from 'shared/src/helpers/pathHelper';
import PasswordRenew from '../screens/PasswordRenew';
import {handleClientError} from 'shared/src/helpers/errorHelper';
import Checkout from '../screens/Checkout';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import CookiePolicy from '../screens/CookiePolicy';

const pathDefs = [
    { path: getPathParserWithLanguage(ROUTES.HOME), exact: true, component: Home },
    { path: getPathParserWithLanguage(ROUTES.RULES), component: Rules },
    { path: getPathParserWithLanguage(ROUTES.NEWS), component: News },
    { path: getPathParserWithLanguage(ROUTES.MAPS), component: Maps },
    { path: getPathParserWithLanguage(ROUTES.WATER_TOUR), component: WaterTourArticle },
    { path: getPathParserWithLanguage(ROUTES.ARTICLE(':id?')), component: Article },
    { path: getPathParserWithLanguage(ROUTES.LOGIN), component: Login },
    { path: getPathParserWithLanguage(ROUTES.LOGOUT), component: Logout },
    { path: getPathParserWithLanguage(ROUTES.PASSWORD_RESET), component: PasswordReset },
    { path: getPathParserWithLanguage(ROUTES.NEW_PASSWORD_RESET(':token')), component: PasswordRenew },
    { path: getPathParserWithLanguage(ROUTES.CHECKOUT), component: Checkout },
    { path: getPathParserWithLanguage(ROUTES.SHOPPING_CART), component: ShoppingCart },
    { path: getPathParserWithLanguage(ROUTES.PAYMENT_ACKNOWLEDGEMENT), component: OrderAcknowledgement },
    { path: getPathParserWithLanguage(ROUTES.PAYMENT_TIMEOUT), component: OrderAcknowledgement },
    { path: getPathParserWithLanguage(ROUTES.PRIVACY_POLICY), component: PrivacyPolicy },
    { path: getPathParserWithLanguage(ROUTES.COOKIE_POLICY), component: CookiePolicy },
];

function Routes() {
    const location = useLocation();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!i18n.language) {
            return;
        }

        const language = getLanguageFromLocation(location);
        if (language !== i18n.language) {
            i18n.changeLanguage(language).catch(handleClientError);
        }
    }, [location, i18n]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Switch>
            {pathDefs.map((pathDef, index) =>
                <Route key={index} path={pathDef.path} exact={pathDef.exact} component={pathDef.component}/>
            )}
            <Route component={NotFound}/>
        </Switch>
    );
}

Routes.initState = (environment) => {
    const { path } = environment;

    const promises = [];

    const pathDef = pathDefs.find(pathDef => matchPath(path, pathDef));

    if (pathDef && pathDef.component.initState) {
        promises.push(...pathDef.component.initState(environment));
    }

    return promises;
};

export default Routes;