import {userService} from '../../services';
import {errorActions} from './errorActions';
import Cookies from 'js-cookie';

export const userActions = {
    getCurrentUser,
    setUser,
    removeUser,
    checkCookiesAccepted,
    setCookiesAccepted,
    setCookiesPopupOpen,
    checkNewsletterSubscribed,
    subscribeToNewsletter,
    SET_USER: 'USER_SET_USER',
    REMOVE_USER: 'USER_REMOVE_USER',
    SET_COOKIES_ACCEPTED: 'USER_SET_COOKIES_ACCEPTED',
    SET_COOKIES_POPUP_OPEN: 'USER_SET_COOKIES_POPUP_OPEN',
    SET_NEWSLETTER_SUBSCRIBED: 'USER_SET_NEWSLETTER_SUBSCRIBED',
};

function getCurrentUser() {
    return async (dispatch) => {
        const token = localStorage.getItem('anpi-jwt');
        if (token) {
            try {
                const user = await userService.getCurrentUser();
                dispatch(userActions.setUser(user));
            } catch (error) {
                dispatch(errorActions.setError(error));
            }
        }
    };
}

function setUser(user) {
    return {
        type: userActions.SET_USER,
        payload: {
            user,
        }
    };
}

function removeUser() {
    return {
        type: userActions.REMOVE_USER,
    };
}

function checkCookiesAccepted() {
    return async (dispatch) => {
        try {
            const cookiesAccepted = getCookiesAccepted();
            dispatch(userActions.setCookiesAccepted(cookiesAccepted));
            manageCookies(cookiesAccepted);
        } catch (error) {
            console.log(error);
            dispatch(userActions.setCookiesAccepted({
                importantCookiesApproved: false,
                convenienceCookiesApproved: false,
                statisticalCookiesApproved: false
            }));
        }
    }
}

function getCookiesAccepted() {
    let cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === "false") {
        cookiesAccepted = {
            importantCookiesApproved: false,
            convenienceCookiesApproved: false,
            statisticalCookiesApproved: false
        }
    } else if (cookiesAccepted === "true") {
        cookiesAccepted = {
            importantCookiesApproved: true,
            convenienceCookiesApproved: true,
            statisticalCookiesApproved: true
        }
    } else if (cookiesAccepted) {
        cookiesAccepted = JSON.parse(cookiesAccepted);
    }

    return cookiesAccepted;
}

function setCookiesAccepted(cookiesAccepted) {
    try {
        manageCookies(cookiesAccepted);
        if (!cookiesAccepted ||
            (cookiesAccepted.importantCookiesApproved === false &&
                cookiesAccepted.convenienceCookiesApproved === false &&
                cookiesAccepted.statisticalCookiesApproved === false)) {
            localStorage.removeItem('cookiesAccepted');
        } else {
            localStorage.setItem('cookiesAccepted', JSON.stringify(cookiesAccepted));
        }
        return {
            type: userActions.SET_COOKIES_ACCEPTED,
            payload: {
                cookiesAccepted,
            }
        };
    } catch (error) {
        localStorage.removeItem('cookiesAccepted');
    }
}

function manageCookies(cookiesAccepted) {
    if (cookiesAccepted && !cookiesAccepted.importantCookiesApproved) {
        localStorage.removeItem('i18nextLng');
        localStorage.removeItem('siteId');
    }

    if (cookiesAccepted && !cookiesAccepted.convenienceCookiesApproved) {
        localStorage.removeItem('anpi-jwt');
        localStorage.removeItem('newsletterSubscribed');
    }

    if (cookiesAccepted && !cookiesAccepted.statisticalCookiesApproved) {
        gtag('consent', 'update', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
        });
        window['ga-disable-UA-40537590-1'] = true;
        window['ga-disable-UA-40537590-4'] = true;
        Cookies.remove('_ga', { path: '/', domain: document.domain });
        Cookies.remove('_gid', { path: '/', domain: document.domain });
        Cookies.remove('_gat', { path: '/', domain: document.domain });
        Cookies.remove('_gat_gtag_UA_40537590_1', { path: '/', domain: document.domain });
        Cookies.remove('_gat_gtag_UA_40537590_4', { path: '/', domain: document.domain });
    } else {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });
    }
}

function setCookiesPopupOpen(cookiesPopupOpen) {
    return {
        type: userActions.SET_COOKIES_POPUP_OPEN,
        payload: {
            cookiesPopupOpen,
        }
    };
}

function checkNewsletterSubscribed() {
    return async (dispatch) => {
        try {
            const newsletterSubscribed = localStorage.getItem('newsletterSubscribed');
            dispatch(setNewsletterSubscribed(newsletterSubscribed === 'true'));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    }
}

function subscribeToNewsletter(emailAddress) {
    return async (dispatch) => {
        try {
            await userService.subscribeToNewsLetter(emailAddress);
            dispatch(setNewsletterSubscribed(true))
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setNewsletterSubscribed(newsletterSubscribed) {
    const cookiesAccepted = getCookiesAccepted();
    if (newsletterSubscribed && cookiesAccepted.convenienceCookiesApproved) {
        localStorage.setItem('newsletterSubscribed', 'true');
    } else {
        localStorage.removeItem('newsletterSubscribed');
    }
    return {
        type: userActions.SET_NEWSLETTER_SUBSCRIBED,
        payload: {
            newsletterSubscribed,
        }
    };
}
