import React from 'react';
import CardBase from '../cards/CardBase';
import InfoLayout from '../layouts/InfoLayout';
import FramedInfoIcon from '../icons/FramedInfoIcon';
import clsx from 'clsx';
import SecondaryCardList from '../containers/SecondaryCardList';
import {screens} from '../../styles/styles';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
        padding: '20px',
        minHeight: '150px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            borderTop: '1px solid rgba(239,239,239,1)',
            minHeight: '200px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            borderTop: '1px solid rgba(239,239,239,1)',
            minHeight: '124px',
        },
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        textTransform: 'none',
        [theme.breakpoints.down(screens.mobile)]: {
            '&:not(:first-child)': {
                marginTop: '12px',
            }
        },
    },
    buttonText: {
        padding: '0px',
    },
    title: {
        textDecoration: 'none',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '28px',
        padding: '0px 0px 13px 0px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            fontSize: '18px',
            lineHeight: '21px',
            padding: '0px 0px 3px 0px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '18px',
            lineHeight: '21px',
            padding: '0px 0px 4px 0px',
        },
    },
    description: {
        textDecoration: 'none',
        padding: '0px',
        color: 'rgba(155,155,155,1)',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        '& > p': {
            border: 0,
            padding: 0,
            marginBlockStart: '0px',
            marginBlockEnd: '0px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            fontSize: '14px',
            lineHeight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
    icon1: {
        backgroundColor: 'rgba(145,160,91,1)',
    },
    icon2: {
        backgroundColor: 'rgba(137,173,65,1)',
    },
    icon3: {
        backgroundColor: 'rgba(199,200,108,1)',
    }
}));

const iconDefs = [
    {
        id: 1,
        icon: (<svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 481.894 481.894'>
            <path fill="currentColor"
                  d='M414.532,349.765l-53.934-10.38l-40.671-40.671c79.24-83.337,127.72-182.789,143.519-280.268	c-97.479,15.799-196.93,64.279-280.267,143.52l-40.671-40.671l-10.38-53.934L64.767,0L0,64.768l67.362,67.361l53.934,10.381	l40.671,40.671c-79.24,83.337-127.721,182.788-143.52,280.267c97.479-15.799,196.93-64.279,280.268-143.52l40.671,40.671	l10.381,53.934l67.361,67.361l64.767-64.767L414.532,349.765z M244.237,306.021c-9.13,9.13-21.269,14.159-34.179,14.159	c-12.917-0.001-25.056-5.029-34.185-14.159c-9.13-9.13-14.159-21.27-14.159-34.182s5.028-25.052,14.159-34.182l20.285-20.285	l21.213,21.213l-20.285,20.285c-3.464,3.464-5.372,8.07-5.372,12.969c0,4.899,1.908,9.505,5.372,12.969	c3.464,3.463,8.07,5.371,12.97,5.372c4.899,0,9.503-1.908,12.968-5.372l20.285-20.285l21.213,21.213L244.237,306.021z M285.735,264.523l-21.213-21.213l20.285-20.285c3.464-3.464,5.372-8.07,5.372-12.969c0-4.899-1.908-9.505-5.372-12.969	c-3.464-3.464-8.07-5.372-12.969-5.372c-4.898,0-9.504,1.908-12.969,5.372l-20.285,20.285l-21.213-21.213l20.285-20.285	c9.13-9.13,21.27-14.159,34.182-14.159c12.913,0,25.052,5.028,34.182,14.159s14.159,21.27,14.159,34.182	c0,12.912-5.029,25.051-14.159,34.182L285.735,264.523z'
            />
        </svg>),
        classProperty: 'icon1',
    },
    {
        id: 2,
        icon: (<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="fish"
                    className="svg-inline--fa fa-fish fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512">
            <path fill="currentColor"
                  d="M327.1 96c-89.97 0-168.54 54.77-212.27 101.63L27.5 131.58c-12.13-9.18-30.24.6-27.14 14.66L24.54 256 .35 365.77c-3.1 14.06 15.01 23.83 27.14 14.66l87.33-66.05C158.55 361.23 237.13 416 327.1 416 464.56 416 576 288 576 256S464.56 96 327.1 96zm87.43 184c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24 13.26 0 24 10.74 24 24 0 13.25-10.75 24-24 24z"></path>
        </svg>),
        classProperty: 'icon2',
        url: '/',
    },
    {
        id: 3,
        classProperty: 'icon3'
    }
];

export default function CardMenu(props) {
    const {className} = props;
    const classes = useStyles();
    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);
    const {t, i18n} = useTranslation();

    function createMarkup(text) {
        return { __html: text };
    }

    function getIconDef(menuItem) {
        return iconDefs.find(iconDef => iconDef.id === menuItem.iconId);
    }

    const menu = getActualMenu(menus, MENU.TYPES.BODROGZUG_MENU_CARD, user);

    return (
        <SecondaryCardList className={clsx(className)}>
            {menu.map((menuItem, index) => (
                <Link key={menuItem.id} to={getPathWithLanguage(menuItem.url, i18n.language)}
                      className={clsx(classes.link, index > 0 ? classes.hideOnTablet : null)}
                      classes={{ text: classes.buttonText }}>
                    <CardBase className={classes.card}>
                        <InfoLayout
                            icon={(
                                <FramedInfoIcon className={classes[getIconDef(menuItem).classProperty]}>
                                    {getIconDef(menuItem).icon}
                                </FramedInfoIcon>
                            )}
                            title={(
                                <div className={classes.title}>
                                    {menuItem.content.title}
                                </div>
                            )}
                            text={(
                                <div className={classes.description}
                                     dangerouslySetInnerHTML={createMarkup(t(menuItem.content.summary))}/>
                            )}/>
                    </CardBase>
                </Link>
            ))}
        </SecondaryCardList>
    );
}
