import {sendServerRequest} from './baseService';

export const orderService = {
    createOrder,
    getOrders,
    getInitialOrder,
};

async function createOrder(order) {
    return sendServerRequest('POST', `/api/orders`, order);
}

async function getOrders() {
    return sendServerRequest('GET', `/api/orders`);
}

async function getInitialOrder() {
    return sendServerRequest('GET', `/api/orders?initialOrder=true`);
}
