import {SET_BADGES} from '../actions/badgeActions';

const initialState = {
    badges: [],
};

export function badge(state = initialState, action) {
    switch (action.type) {
        case SET_BADGES:
            return {
                badges: action.payload.badges,
            };
        default:
            return state
    }
}
