import React from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        borderRadius: '20px',
        minWidth: '40px',
        maxWidth: '40px',
        minHeight: '40px',
        maxHeight: '40px',
        position: 'relative',
        backgroundColor: 'rgba(255,32,0,1)',
    },
    icon: {
        color: theme.palette.text.primaryInverse,
        minWidth: '30px',
        maxWidth: '30px',
        minHeight: '30px',
        maxHeight: '30px',
        position: 'absolute',
        top: '5px',
        left: '5px'
    }
});

class AlertIcon extends React.Component {
    render() {
        const { classes, className } = this.props;
        const child = React.Children.toArray(this.props.children)[0];
        const iconWithExtraProp = React.cloneElement(child, {
                className: classes.icon,
        });
        return (
            <div className={clsx(classes.root, className)}>
                {iconWithExtraProp}
            </div>
        );
    }
}

AlertIcon.propTypes = {};

export default withStyles(styles)(AlertIcon);