import moment from 'moment';
import {CHECKOUT} from '../../constants';

export const orderModule = {
    recalculateOrder,
    getOrderTourDays,
    getOrderAmount,
};

function recalculateOrder(order) {
    calculateOrderPrices(order);

    if (order.price !== 0 || order.paymentType === CHECKOUT.PAYMENT_OPTIONS.NONE) {
        order.paymentType = CHECKOUT.PAYMENT_OPTIONS.CREDIT_CARD;
    }

    if (order.price === 0) {
        order.paymentType = CHECKOUT.PAYMENT_OPTIONS.NONE;
    }

    return order;
}

function calculateOrderPrices(order) {
    const tourDays = getOrderTourDays(order);
    const products = getProducts(order);
    order.price = order.orderItems.reduce((price, orderItem) => {
        const product = products.find(product => product.id === orderItem.product.id);
        orderItem.price = tourDays * orderItem.amount * product.price;
        return price + orderItem.price;
    }, 0);
}

function getProducts(order) {
    if (order.tour) {
        return order.tour.products;
    }

    return order.orderItems.reduce((accumulator, orderItem) => {
        if (!accumulator.find(product => product.id === orderItem.product.id)) {
            accumulator.push(orderItem.product);
        }
        return accumulator;
    }, []);

}

function getOrderTourDays(order) {
    return moment(order.toDate).startOf('day').diff(moment(order.fromDate).startOf('day'), 'day') + 1;
}

function getOrderAmount(order, adultsOnly = false) {
    return order.orderItems.filter(orderItem => adultsOnly ? orderItem.product.adult : true).reduce((amount, orderItem) => {
        const itemAmount = orderItem.amount === '' ? 0 : orderItem.amount;
        return amount + itemAmount;
    }, 0);
}

