import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import PlaceIcon from '@material-ui/icons/Place';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared/src/constants/menu';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const styles = theme => ({
    root: {
        height: '80px',
        width: '100%',
        background: 'linear-gradient(121.08deg, rgba(160,200,108,1) 0%, rgba(195,188,61,1) 100%)',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.21)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-around",
        zIndex: '10',
    },
    link: {
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "inherit",
        color: "inherit",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '0px',
        margin: '0px',
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        color: theme.palette.text.primaryInverse,
        minWidth: '24px',
        maxWidth: '24px',
        minHeight: '24px',
        maxHeight: '24px',
        marginBottom: '11px',
    },
    title: {
        color: theme.palette.text.primaryInverse,
        fontSize: '8px',
        fontWeight: 'bold',
        lineHeight: '9px',
        margin: '0px',
        textTransform: 'uppercase',
    },
});

const icons = [
    {
        id: 1,
        icon: (<LocalGroceryStoreIcon/>),
    },
    {
        id: 2,
        icon: (<PlaceIcon/>),
    },
    {
        id: 3,
        icon: (<NotInterestedIcon/>),
    },
    {
        id: 4,
        icon: (<svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 481.894 481.894'>
            <path fill="currentColor"
                  d='M414.532,349.765l-53.934-10.38l-40.671-40.671c79.24-83.337,127.72-182.789,143.519-280.268	c-97.479,15.799-196.93,64.279-280.267,143.52l-40.671-40.671l-10.38-53.934L64.767,0L0,64.768l67.362,67.361l53.934,10.381	l40.671,40.671c-79.24,83.337-127.721,182.788-143.52,280.267c97.479-15.799,196.93-64.279,280.268-143.52l40.671,40.671	l10.381,53.934l67.361,67.361l64.767-64.767L414.532,349.765z M244.237,306.021c-9.13,9.13-21.269,14.159-34.179,14.159	c-12.917-0.001-25.056-5.029-34.185-14.159c-9.13-9.13-14.159-21.27-14.159-34.182s5.028-25.052,14.159-34.182l20.285-20.285	l21.213,21.213l-20.285,20.285c-3.464,3.464-5.372,8.07-5.372,12.969c0,4.899,1.908,9.505,5.372,12.969	c3.464,3.463,8.07,5.371,12.97,5.372c4.899,0,9.503-1.908,12.968-5.372l20.285-20.285l21.213,21.213L244.237,306.021z M285.735,264.523l-21.213-21.213l20.285-20.285c3.464-3.464,5.372-8.07,5.372-12.969c0-4.899-1.908-9.505-5.372-12.969	c-3.464-3.464-8.07-5.372-12.969-5.372c-4.898,0-9.504,1.908-12.969,5.372l-20.285,20.285l-21.213-21.213l20.285-20.285	c9.13-9.13,21.27-14.159,34.182-14.159c12.913,0,25.052,5.028,34.182,14.159s14.159,21.27,14.159,34.182	c0,12.912-5.029,25.051-14.159,34.182L285.735,264.523z'
            />
        </svg>),
    },
];

class IconMenu extends React.Component {
    render() {
        const { classes, className, menus, user, i18n } = this.props;

        const menu = getActualMenu(menus, MENU.TYPES.BODROGZUG_MENU_ICON, user);

        return (
            <div className={clsx(className, classes.root)}>
                {menu.map(menuItem =>
                    <Link key={menuItem.id} to={getPathWithLanguage(menuItem.url, i18n.language)} className={clsx(classes.link, classes.buttonLabel)}>
                        {React.cloneElement(icons.find(icon => icon.id === menuItem.iconId).icon, {
                            className: classes.icon,
                        })}
                        <p className={classes.title}>{menuItem.title}</p>
                    </Link>
                )}
            </div>
        )
    }
}

IconMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { menus } = state.menu;
    const { user } = state.user;
    return {
        menus,
        user
    };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(IconMenu)));