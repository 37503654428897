import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/hu';
import TextField from '@material-ui/core/TextField/TextField';
import {connect} from 'react-redux';
import {screens} from '../../../styles/styles';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import {tourService} from 'client_shared/src/services/tourService';
import {withTranslation} from 'react-i18next';
import {orderModule} from 'shared/src/modules/order';
import {tourModule} from 'shared/src/modules/tour';
import {formatDate} from 'shared/src/utils/dateUtils';
import {orderActions} from 'client_shared/src/state/actions';
import {FormHelperText} from '@material-ui/core';
import {cityService} from 'client_shared/src/services/cityService';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import countries from 'i18n-iso-countries';
import _ from 'lodash';
import {COUNTRY_CODES} from 'shared/src/constants/translation';

const styles = theme => ({
    card: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        borderColor: 'rgba(239,239,239,1)',
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '30px 30px 15px 30px',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    dateControl: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up(screens.desktop)]: {
            '&:not(:last-child)': {
                marginRight: '30px',
            },
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            '&:not(:last-child)': {
                marginRight: '15px',
            },
        },
    },
    dateContainer: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'flex',
            justifyContent: 'space-between',
        }
    },
    cityContainer: {
        display: 'flex',
    },
    zip: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    city: {
        flex: 4,
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px'
    },
    control: {
        flex: 1,
    },
    link: {
        fontSize: '13px',
        letterSpacing: '0.16px',
        color: 'rgba(0,30,255,1)',
        margin: '0px',
        border: '0px',
        padding: '0px',
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    },
    linkAlign: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '35px',
    },
    checkBoxLabel: {
        fontSize: '13px',
    },
    checkBox: {
        color: 'rgba(255,159,0,1)',
        '&$checked': {
            color: 'rgba(255,159,0,1)',
        },
    },
    checked: {},
    dateWarning: {
        paddingBottom: '10px',
    },
});

class OrderDetailsCard extends React.Component {
    async recalculateOrder() {
        const order = this.props.values;

        const { tourAmount } = await tourService.getTourAmount(order.tour.id, order.fromDate, order.toDate);
        const tourDate = tourModule.getTourDate(order.tour, order.fromDate, order.toDate);
        const amountLimit = tourModule.getTourAmountLimit(tourAmount, tourDate);

        this.props.setFieldValue('amountLimit', amountLimit);
        this.props.setFieldValue('tourDate', tourDate);

        const recalculatedOrder = orderModule.recalculateOrder(order);
        this.props.dispatch(orderActions.setOrder(recalculatedOrder));
    }

    onFromDateChange = async (date) => {
        const fromDate = moment(date).startOf('day');
        await this.props.setFieldValue('fromDate', formatDate(date));
        if (moment(this.props.values.toDate).isBefore(fromDate)) {
            await this.props.setFieldValue('toDate', formatDate(date));
        }

        return this.recalculateOrder();
    };

    onToDateChange = async (date) => {
        const toDate = moment(date).startOf('day');
        await this.props.setFieldValue('toDate', formatDate(toDate));
        return this.recalculateOrder();
    };

    onInvoiceRequestedChange = (event) => {
        const invoiceRequested = event.target.checked;
        this.props.setFieldValue('invoiceRequested', invoiceRequested);
        if (invoiceRequested && this.props.values.billingName === '') {
            this.props.setFieldValue('billingName', this.props.values.guideName);
            this.props.setFieldValue('billingEmail', this.props.values.guideEmail);
            this.props.setFieldValue('billingPostCode', this.props.values.guidePostCode);
            this.props.setFieldValue('billingCity', this.props.values.guideCity);
            this.props.setFieldValue('billingAddress', this.props.values.guideAddress);
        }
    };

    postCodeChanged = (cityField) => async (event) => {
        this.props.handleChange(event);

        if (event.target.value && event.target.value.length === 4) {
            const { city } = await cityService.getCity(event.target.value);

            if (city) {
                this.props.setFieldValue(cityField, city.city);
            }
        }
    };

    render() {
        const { classes, className, values, handleChange, handleBlur, touched, errors, t, i18n } = this.props;

        moment.locale(i18n.language);

        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        countries.registerLocale(require("i18n-iso-countries/langs/hu.json"));
        countries.registerLocale(require("i18n-iso-countries/langs/sk.json"));

        const countryMap = countries.getNames(i18n.language);
        const countryMapNamesToCodes = _.invert(countryMap);
        const countryNames = COUNTRY_CODES.map(countryCode => countryMap[countryCode]).sort(Intl.Collator().compare);

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale="hu" moment={moment}>
                <div className={clsx(className, classes.card, classes.container)}>
                    {values.tourDate.limit === 0 &&
                    <FormHelperText className={classes.dateWarning} error={values.tourDate.limit === 0}>
                        {values.tourDate.message}
                    </FormHelperText>
                    }
                    {values.tourDate.limit !== 0 && values.amountLimit === 0 &&
                    <FormHelperText className={classes.dateWarning} error={values.amountLimit === 0}>
                        {t('Checkout.SoldOut')}
                    </FormHelperText>
                    }
                    <div className={classes.dateContainer}>
                        <div className={classes.dateControl}>
                            <div className={classes.text}>
                                {t('Checkout.FirstDay')}
                            </div>
                            <DatePicker
                                name="fromDate"
                                autoOk={true}
                                margin="normal"
                                variant="inline"
                                inputVariant="outlined"
                                value={values.fromDate}
                                onChange={this.onFromDateChange}
                                onBlur={handleBlur}
                                invalidDateMessage={t('Checkout.DateInvalid')}
                                disablePast
                                minDateMessage={t('Checkout.DateMinMessage')}
                            />
                        </div>
                        <div className={classes.dateControl}>
                            <div className={classes.text}>
                                {t('Checkout.LastDay')}
                            </div>
                            <DatePicker
                                name="toDate"
                                autoOk={true}
                                margin="normal"
                                variant="inline"
                                inputVariant="outlined"
                                value={values.toDate}
                                onChange={this.onToDateChange}
                                onBlur={handleBlur}
                                invalidDateMessage={t('Checkout.DateInvalid')}
                                disablePast
                                minDateMessage={t('Checkout.DateMinMessage')}
                            />
                        </div>
                    </div>
                    <div className={classes.text}>
                        {t('Checkout.GuideNameLabel')}
                    </div>
                    <TextField
                        name="guideName"
                        value={values.guideName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={!!(touched.guideName && errors.guideName)}
                        helperText={touched.guideName && errors.guideName}
                    />
                    <div className={classes.text}>
                        {t('Checkout.GuideEmailLabel')}
                    </div>
                    < TextField
                        name="guideEmail"
                        value={values.guideEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant='outlined'
                        type='email'
                        error={!!(touched.guideEmail && errors.guideEmail)}
                        helperText={touched.guideEmail && errors.guideEmail}
                    />
                    <div className={classes.text}>
                        {t('Checkout.GuidePhoneLabel')}
                    </div>
                    < TextField
                        name="guidePhone"
                        value={values.guidePhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant='outlined'
                        error={!!(touched.guidePhone && errors.guidePhone)}
                        helperText={touched.guidePhone && errors.guidePhone}
                    />
                    <div className={classes.cityContainer}>
                        <div className={classes.zip}>
                            <div className={classes.text}>
                                {t('Checkout.PostCode')}
                            </div>
                            <TextField
                                name="guidePostCode"
                                value={values.guidePostCode}
                                onChange={this.postCodeChanged('guideCity')}
                                onBlur={handleBlur}
                                variant="outlined"
                                error={!!(touched.guidePostCode && errors.guidePostCode)}
                                helperText={touched.guidePostCode && errors.guidePostCode}
                            />
                        </div>
                        <div className={classes.city}>
                            <div className={classes.text}>
                                {t('Checkout.City')}
                            </div>
                            <TextField
                                name="guideCity"
                                value={values.guideCity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                error={!!(touched.guideCity && errors.guideCity)}
                                helperText={touched.guideCity && errors.guideCity}
                            />
                        </div>
                    </div>
                    <div className={classes.text}>
                        {t('Checkout.Address')}
                    </div>
                    <TextField
                        name="guideAddress"
                        value={values.guideAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={!!(touched.guideAddress && errors.guideAddress)}
                        helperText={touched.guideAddress && errors.guideAddress}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="invoiceRequested"
                                checked={values.invoiceRequested}
                                onChange={this.onInvoiceRequestedChange}
                                classes={{ root: classes.checkBox, checked: classes.checked }}
                            />
                        }
                        classes={{ label: clsx(classes.checkBoxLabel) }}
                        label={t('Checkout.InvoiceRequested')}
                        className={classes.invoiceRequested}
                    />
                    {values.invoiceRequested &&
                    <>
                        <div className={classes.text}>
                            {t('Checkout.BillingEmail')}
                        </div>
                        <TextField
                            name="billingEmail"
                            value={values.billingEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={!!(touched.billingEmail && errors.billingEmail)}
                            helperText={touched.billingEmail && errors.billingEmail}
                        />
                        <div className={classes.text}>
                            {t('Checkout.BillingName')}
                        </div>
                        <TextField
                            name="billingName"
                            value={values.billingName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={!!(touched.billingName && errors.billingName)}
                            helperText={touched.billingName && errors.billingName}
                        />
                        <div className={classes.text}>
                            {t('Checkout.Country')}
                        </div>
                        <FormControl variant="outlined">
                            <Select
                                value={values.billingCountry}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{
                                    name: 'billingCountry',
                                    id: 'billingCountry',
                                }}
                            >
                                {countryNames.map((countryName, index) => (
                                    <MenuItem key={index} value={countryMapNamesToCodes[countryName]}>
                                        {countryName}
                                    </MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                        <div className={classes.cityContainer}>
                            <div className={classes.zip}>
                                <div className={classes.text}>
                                    {t('Checkout.PostCode')}
                                </div>
                                <TextField
                                    name="billingPostCode"
                                    value={values.billingPostCode}
                                    onChange={this.postCodeChanged('billingCity')}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    error={!!(touched.billingPostCode && errors.billingPostCode)}
                                    helperText={touched.billingPostCode && errors.billingPostCode}
                                />
                            </div>
                            <div className={classes.city}>
                                <div className={classes.text}>
                                    {t('Checkout.City')}
                                </div>
                                <TextField
                                    name="billingCity"
                                    value={values.billingCity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    error={!!(touched.billingCity && errors.billingCity)}
                                    helperText={touched.billingCity && errors.billingCity}
                                />
                            </div>
                        </div>
                        <div className={classes.text}>
                            {t('Checkout.Address')}
                        </div>
                        <TextField
                            name="billingAddress"
                            value={values.billingAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={!!(touched.billingAddress && errors.billingAddress)}
                            helperText={touched.billingAddress && errors.billingAddress}
                        />
                        <div className={classes.text}>
                            {t('Checkout.BillingVATNumber')}
                        </div>
                        <TextField
                            name="billingVATNumber"
                            value={values.billingVATNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={!!(touched.billingVATNumber && errors.billingVATNumber)}
                            helperText={touched.billingVATNumber && errors.billingVATNumber}
                        />
                    </>
                    }
                </div>
            </MuiPickersUtilsProvider>
        )
    }
}

function mapStateToProps(state) {
    const { order } = state.order;
    return {
        order,
    };
}

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(OrderDetailsCard)));