import React from 'react';
import {withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import clsx from 'clsx';
import DehazeIcon from "@material-ui/icons/Dehaze";
import CloseIcon from "@material-ui/icons/Close";
import {screens} from '../../styles/styles';
import Popover from '@material-ui/core/Popover/Popover';
import VerticalMenu from './VerticalMenu';
import {withTranslation} from 'react-i18next';
import {getActualMenu, getActualSubMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared/src/constants/menu';
import {connect} from 'react-redux';

const styles = theme => ({
    button: {
        '&.MuiButton-contained': {
            minHeight: theme.header.size[screens.desktop],
            maxHeight: theme.header.size[screens.desktop],
            minWidth: theme.header.size[screens.desktop],
            maxWidth: theme.header.size[screens.desktop],
            [theme.breakpoints.down(screens.tablet)]: {
                minHeight: theme.header.size[screens.tablet],
                maxHeight: theme.header.size[screens.tablet],
                minWidth: theme.header.size[screens.tablet],
                maxWidth: theme.header.size[screens.tablet],
            },
            [theme.breakpoints.down(screens.mobile)]: {
                minHeight: theme.header.size[screens.mobile],
                maxHeight: theme.header.size[screens.mobile],
                minWidth: theme.header.size[screens.mobile],
                maxWidth: theme.header.size[screens.mobile],
            },
            color: theme.palette.text.primaryInverse,
            backgroundColor: theme.header.hamburgerButtonColor,
            '&:hover': {
                backgroundColor: theme.header.hamburgerButtonColorHover,
            },
            borderRadius: '0px',
        }
    },
    icon: {
        minWidth: "20px",
        maxWidth: "20px",
        minHeight: "20px",
        maxHeight: "20px",
    },
    menu: {
        minWidth: '100vw',
        backgroundColor: theme.header.hamburgerButtonColor,
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '20px',
    },
    menuPopover: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginTop: '60px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginTop: '34px',
        }
    },
    menuOverride: {
        '& > *': {
            maxWidth: 'initial',
            left: '0px !important',
            borderRadius: '0px',
        }
    },
    verticalMenu: {
        paddingTop: '36px',
        minWidth: 'initial',
        maxWidth: 'initial',
    },
    verticalMenuTitle: {
        color: theme.palette.text.primaryInverse,
        fontSize: '14px',
    },
    verticalMenuListItem: {
        borderColor: theme.palette.text.primaryInverse,
    },
    verticalMenuIcon: {},
    verticalMenuLink: {
        color: theme.palette.text.primary,
        fontSize: '13px',
        '&:hover': {
            textDecoration: 'none',
            color: 'rgba(255,255,255,1)',
            backgroundColor: theme.header.hamburgerButtonColor,
        },
    },
    typography: {
        margin: theme.spacing(2),
    },
});

class MobileMenu extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const { classes, menus, user } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const menu = getActualMenu(menus, MENU.TYPES.BODROGZUG_MENU_FOOTER, user);

        return (
            <>
                <Button
                    className={clsx(classes.button)}
                    onClick={this.handleClick}
                    variant="contained"
                    aria-owns={open ? 'simple-popper' : undefined}
                    aria-haspopup="true">
                    {open ? <CloseIcon className={clsx(classes.icon)}/> :
                        <DehazeIcon className={clsx(classes.icon)}/>}
                </Button>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    className={clsx(classes.menuPopover, classes.menuOverride)}
                >
                    <div className={classes.menu}>
                        {menu.map(menuItem => (
                            <VerticalMenu
                                key={menuItem.id}
                                title={menuItem.title}
                                menuItems={getActualSubMenu(menus, menuItem.id, user)}
                                className={classes.verticalMenu}
                                classNameTitle={classes.verticalMenuTitle}
                                classNameListItem={classes.verticalMenuListItem}
                                classNameIcon={classes.verticalMenuIcon}
                                classNameLink={classes.verticalMenuLink}
                            />
                        ))}
                    </div>
                </Popover>
            </>
        );
    }
}

MobileMenu.propTypes = {};

const mapStateToProps = (state) => {
    const { menus } = state.menu;
    const { user } = state.user;
    return {
        menus,
        user
    };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(MobileMenu)));