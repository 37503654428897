import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
    row: {
        display: 'flex',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '20px',
        flex: 1,
    }
});

class InfoLayout extends React.Component {
    render() {
        const { classes, className, icon, title, text, textWide, textHidden } = this.props;
        return (
            <div className={clsx(className)}>
                <div className={clsx(classes.row)}>
                    {icon}
                    <div className={classes.column}>
                        {title}
                        {!textHidden && text}
                    </div>
                </div>
                {!textHidden && textWide}

            </div>
        )
    }
}

InfoLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    icon: PropTypes.object.isRequired,
    title: PropTypes.object.isRequired,
    text: PropTypes.object,
    textWide: PropTypes.object,
};

InfoLayout.defaultProps = {
    className: '',
    text: null,
    textWide: null,
};

export default withStyles(styles)(InfoLayout);