import {searchActions} from '../actions/searchActions';

const initialState = {
    phrase: null,
    results: null,
};

export function search(state = initialState, action) {
    switch (action.type) {
        case searchActions.SET_SEARCH_PHRASE:
            return {
                ...state,
                phrase: action.payload.phrase,
            };
        case searchActions.SET_SEARCH_RESULTS:
            return {
                ...state,
                results: action.payload.results,
            };
        default:
            return state
    }
}
