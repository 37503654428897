import React, {useEffect, useState} from 'react';
import {userService} from '../../services';
import {MessageBar} from '../errors/MessageBar';
import {loginActions, userActions} from '../../state/actions';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

export default function UserAccountHandler(props) {
    const { children } = props;
    const { search } = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showAccountActivatedMessage, setShowAccountActivatedMessage] = useState(false);

    React.useEffect(() => {
        dispatch(loginActions.autoLogin());
        dispatch(userActions.checkCookiesAccepted());
        dispatch(userActions.checkNewsletterSubscribed());
    }, [dispatch]);

    const activateAccount = async (activationToken) => {
        await userService.activateAccount(activationToken);
        setShowAccountActivatedMessage(true);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const activationToken = searchParams.get('activationToken');
        if (activationToken) {
            activateAccount(activationToken).catch(error => console.log(error));
        }
    }, [search]);

    return (
        <>
            {showAccountActivatedMessage && <MessageBar message={t('Text.ActivationSucceeded')}/>}
            {children}
        </>
    );
}
