import React from 'react';
import MainHeader from "../headers/MainHeader";
import CookieCard from '../cards/cookie/CookieCard';
import Div100vh from 'react-div-100vh';
import {makeStyles} from '@material-ui/core';
import {screens} from '../../styles/styles';

const useStyles = makeStyles(theme => ({
    mainAreaAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100% - 70px)',
        [theme.breakpoints.down(screens.tablet)]: {
            height: 'calc(100% - 76px)',
        },
        [theme.breakpoints.down(screens.mobile)]: {
        height: 'calc(100% - 50px)',
        },

    },
}));

export default function MainMapScreen(props) {
    const { children } = props;
    const classes = useStyles();

    return (
        <Div100vh style={{ height: '100rvh', width: '100%' }}>
            <MainHeader/>
            <main className={classes.mainAreaAlign}>
                {children}
            </main>
            <CookieCard/>
        </Div100vh>
    )
}
