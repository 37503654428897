import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import MainHeader from "../headers/MainHeader";
import {screens} from '../../styles/styles';
import MainFooter from '../footers/MainFooter';
import CookieCard from '../cards/cookie/CookieCard';
import CookiePopup from '../cards/cookie/CookiePopup';

const styles = theme => ({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    mainAreaAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
    },
    mainAreaSize: {
        minWidth: theme.main.width,
        maxWidth: theme.main.width,
        [theme.breakpoints.down(screens.tablet)]: {
            minWidth: '100%',
            maxWidth: '100%',
        },
    },
    layout: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    mainList: {
        flex: 1,
    },
    sideMenu: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minWidth: '228px',
            maxWidth: '228px',
            marginRight: '18px',
        },
    },
    mainFooter: {},
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
});

class MainScreen extends React.Component {
    render() {
        const { classes, children, sideMenu } = this.props;

        return (
            <div className={classes.page}>
                <MainHeader/>
                <main className={classes.mainAreaAlign}>
                    <div className={classes.mainAreaSize}>
                        <div className={classes.layout}>
                            <div className={classes.mainList}>
                                {children}
                            </div>
                            <div hidden={!sideMenu} className={classes.sideMenu}>
                                {sideMenu}
                            </div>
                        </div>
                    </div>
                </main>
                <MainFooter className={classes.mainFooter}/>
                <CookieCard/>
                <CookiePopup/>
            </div>
        )
    }
}

MainScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainScreen);