import {sendServerRequest} from './baseService';

export const contentService = {
    getContent,
    getContents,
    getContentCount,
    getContentByName,
};

async function getContents(propsArg) {
    const props = {...propsArg, enabled: true};
    const params = [];
    Object.keys(props).forEach(key => {
        key !== 'ids' && props[key] && params.push(`${key}=${props[key]}`);
    });

    props['ids'] && params.push(`ids=${JSON.stringify(props['ids'])}`);

    return sendServerRequest('GET', `/api/contents?${params.join('&')}`);
}

async function getContentCount({type = null}) {
    const params = ['count=true'];
    type && params.push(`type=${type}`);
    return sendServerRequest('GET', `/api/contents?${params.join('&')}`);
}

async function getContent(contentId) {
    return sendServerRequest('GET', `/api/contents/${contentId}`);
}

async function getContentByName(articleName) {
    return sendServerRequest('GET', `/api/contents?contentName=${articleName}`);
}
