import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {CustomControl} from '@googlemap-react/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

const useStyles = makeStyles(theme => ({
    layout: {},
    wrapper: {
        margin: '10px',
        position: 'absolute',
        top: '0px',
        left: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: 0,
        backgroundColor: 'white',
        borderRadius: '2px',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    },
    titleRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px'
    },
    legendArea: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 10px 10px 10px',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: '10px',
        }
    },
    expandButton: {
        width: '40px',
        '&.MuiButton-root': {
            minWidth: '0px'
        }
    },
    colorBox: {
        width: '10px',
        height: '10px'
    },
    inaccessible: {
        backgroundColor: '#E64545',
    },
    closed: {
        backgroundColor: '#FFAA00',
    },
    difficult: {
        backgroundColor: '#E6D200',
    },
    easy: {
        backgroundColor: '#00B377',
    },
    legendTitle: {
        fontSize: '14px',
    },
    legendText: {
        whiteSpace: 'nowrap',
    },
}));

export default function Legend() {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const { t } = useTranslation();

    const isTabletOrDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {
        setOpen(isTabletOrDesktop);
    }, [isTabletOrDesktop]);

    function handleClick() {
        setOpen(!open);
    }

    return (
        <CustomControl bindingPosition='LEFT_TOP'>
            <div className={classes.wrapper}>
                <div className={classes.titleRow}>
                    <div className={classes.legendTitle}>{t('Text.Legend')}</div>
                    <Button className={classes.expandButton} onClick={handleClick}>
                        {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </Button>
                </div>
                {open &&
                <div className={classes.legendArea}>
                    <div className={classes.row}>
                        <div className={clsx(classes.colorBox, classes.inaccessible)}/>
                        <div className={classes.legendText}>{t('Text.Inaccessible')}</div>
                    </div>
                    <div className={classes.row}>
                        <div className={clsx(classes.colorBox, classes.closed)}/>
                        <div className={classes.legendText}>{t('Text.Closed')}</div>
                    </div>
                    <div className={classes.row}>
                        <div className={clsx(classes.colorBox, classes.difficult)}/>
                        <div className={classes.legendText}>{t('Text.Difficult')}</div>
                    </div>
                    <div className={classes.row}>
                        <div className={clsx(classes.colorBox, classes.easy)}/>
                        <div className={classes.legendText}>{t('Text.Easy')}</div>
                    </div>
                </div>
                }
            </div>
        </CustomControl>
    );
}
