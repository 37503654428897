import {settingService} from '../../services';
import {errorActions} from './errorActions';

export const settingActions = {
    loadSettings,
    setSettings,
    SET_SETTINGS: 'SETTING_SET_SETTINGS',
};

function loadSettings() {
    return async (dispatch) => {
        try {
            const settings = await settingService.loadSettings();
            dispatch(settingActions.setSettings(settings));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setSettings(settings) {
    return {
        type: settingActions.SET_SETTINGS,
        payload: {
            settings,
        }
    };
}



