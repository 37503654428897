import React from 'react';
import {makeStyles} from '@material-ui/styles';
import QuillSnowStylesheet from './QuillSnowStylesheet';
const useStyles = makeStyles(theme => ({
    '@global': Object.assign({}, QuillSnowStylesheet, {
        '.paragraph1': {
            ...theme.typography.body1,
        },
        '.paragraph2': {
            ...theme.typography.body1,
        },
        '.paragraph3': {
            ...theme.typography.body2,
        },
        '.paragraph4': {
            ...theme.typography.body2,
        },
    }),
}));

export default function GlobalCss(props) {
    useStyles();

    return (
        <>
            {props.children}
        </>
    );
}