import React from 'react';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router';
import {ROUTES} from 'shared';
import {loginActions} from 'client_shared/src/state/actions';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {withTranslation} from 'react-i18next';

class Logout extends React.Component {
    componentDidMount() {
        return this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            return this.getData();
        }
    }

    getData() {
        this.props.dispatch(loginActions.logout());
    }

    render() {
        return (
            <Redirect to={getPathWithLanguage(ROUTES.HOME, this.props.i18n.language)}/>
        );
    }
}

export default withTranslation()(withRouter(connect()(Logout)));