export const TOURS = {
    TYPES: {
        WATER_TOUR: 1,
        CAVE_TOUR: 2,
        ADVANCED_CAVE_TOUR: 3,
        WALKING_TOUR: 4,
        CYCLING_TOUR: 5,
        HORSE_TOUR: 6,
        OTHER_PROGRAM: 7,
        TOUR_PACKAGE: 8,
    },
    DIFFICULTIES: {
        EASY: 1,
        MEDIUM: 2,
        HARD: 3,
    },

    WATER_TOUR_ID: 1,
};
