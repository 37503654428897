import React from 'react';
import MainScreen from "../components/screens/MainScreen";
import ArticleCard from '../components/cards/article/ArticleCard';
import {makeStyles} from '@material-ui/core';
import {screens} from '../styles/styles';
import {contentActions} from 'client_shared/src/state/actions';
import {getArticleId, getArticleName} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    article: {
        marginBottom: '10px',
        '&:last-child': {
            marginBottom: '47px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginBottom: '10px',
            '&:last-child': {
                marginBottom: '35px',
            },
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '28px',
            '&:last-child': {
                marginBottom: '47px',
            },
        },
    },
}));

function Article() {
    const classes = useStyles();
    return (
        <MainScreen>
            <ArticleCard className={classes.article}/>
        </MainScreen>
    );
}

Article.initState = (environment) => {
    const { dispatch, path, searchParams } = environment;

    const promises = [];

    const contentId = getArticleId(path);
    const contentName = getArticleName(path, searchParams);

    if (contentId) {
        promises.push(dispatch(contentActions.loadContent(contentId)));
    }

    if (contentName) {
        promises.push(dispatch(contentActions.loadContentByName(contentName)));
    }

    return promises;
};
export default Article;
