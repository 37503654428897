import {mapActions} from '../actions/mapActions';

const initialState = {
    layers: [],
    zoomingFeature: null,
    selection: null,
    centerMap: null,
};

export function map(state = initialState, action) {
    switch (action.type) {
        case mapActions.SET_LAYERS:
            return {
                ...state,
                layers: action.payload.layers,
            };
        case mapActions.UPDATE_LAYER:
            return {
                ...state,
                layers: state.layers.map(layer => {
                    return layer.id === action.payload.layer.id ?
                        {...action.payload.layer}
                        :
                        layer;
                }),
            };
        case mapActions.ZOOMING_REQUESTED:
            return {
                ...state,
                zoomingFeature: action.payload.feature,
            };
        case mapActions.ZOOMING_DONE:
            return {
                ...state,
                zoomingFeature: null,
            };
        case mapActions.SHOW_FEATURE_INFO:
            return {
                ...state,
                selection: action.payload,
            };
        case mapActions.HIDE_FEATURE_INFO:
            return {
                ...state,
                selection: null,
            };
        case mapActions.CENTER_MAP:
            return {
                ...state,
                centerMap: true,
            };
        case mapActions.CENTER_MAP_DONE:
            return {
                ...state,
                centerMap: null,
            };
        default:
            return state
    }
}
