import {contentActions} from '../actions/contentActions';

const initialState = {
    content: null,
    news: [],
    homePageNews: [],
    rules: [],
    dangers: [],
    homeSummary: null,
    headlineContent: null,
    offeredProgrammes: [],
    promotion: null,
    promotionBig: null,
    articles: [],
    videos: [],
    privacyPolicy: null,
    cookiePolicy: null,
    cookieWarning: null,
};

export function content(state = initialState, action) {
    switch (action.type) {
        case contentActions.SET_CONTENT:
            return {
                ...state,
                content: action.payload.content,
            };
        case contentActions.SET_HOME_SUMMARY:
            return {
                ...state,
                homeSummary: action.payload.homeSummary,
            };
        case contentActions.SET_NEWS:
            return {
                ...state,
                news: action.payload.news,
            };
        case contentActions.SET_HOME_PAGE_NEWS:
            return {
                ...state,
                homePageNews: action.payload.homePageNews,
            };
        case contentActions.SET_OFFERED_PROGRAMMES:
            return {
                ...state,
                offeredProgrammes: action.payload.offeredProgrammes,
            };
        case contentActions.SET_ARTICLES:
            return {
                ...state,
                articles: action.payload.articles,
            };
        case contentActions.SET_VIDEOS:
            return {
                ...state,
                videos: action.payload.videos,
            };
        case contentActions.SET_RULES:
            return {
                ...state,
                rules: action.payload.rules,
            };
        case contentActions.SET_PRIVACY_POLICY:
            return {
                ...state,
                privacyPolicy: action.payload.privacyPolicy,
            };
        case contentActions.SET_COOKIE_POLICY:
            return {
                ...state,
                cookiePolicy: action.payload.cookiePolicy,
            };
        case contentActions.SET_COOKIE_WARNING:
            return {
                ...state,
                cookieWarning: action.payload.cookieWarning,
            };
        case contentActions.SET_DANGERS:
            return {
                ...state,
                dangers: action.payload.dangers,
            };
        case contentActions.SET_HEADLINE_CONTENT:
            return {
                ...state,
                headlineContent: action.payload.headlineContent,
            };
        case contentActions.SET_PROMOTION:
            return {
                ...state,
                promotion: action.payload.promotion,
            };
        case contentActions.SET_PROMOTION_BIG:
            return {
                ...state,
                promotionBig: action.payload.promotionBig,
            };
        default:
            return state
    }
}
