export default function getI18NextOptions(siteId) {
    return {
        fallbackLng: ['hu'],
        preload: ['hu', 'en', 'sk'],
        keySeparator: '.',
        whitelist: ['hu', 'en', 'sk'],
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `/api/translation/{{lng}}?siteId=${siteId}`,
        }
    };
}