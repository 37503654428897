import {ROUTES} from '../index';
import Path from 'path-parser';
import 'url-search-params-polyfill';
import {LANGUAGE, LANGUAGES} from '../constants/translation';
import {startsWith} from '../utils/stringUtils';

export function getArticleId(path) {
    let pathParser = new Path(`${ROUTES.ARTICLE(':id')}`);
    if (startsWith(path, '/hu')) {
        pathParser = new Path(`/hu${ROUTES.ARTICLE(':id')}`);
    }
    if (startsWith(path, '/en')) {
        pathParser = new Path(`/en${ROUTES.ARTICLE(':id')}`);
    }
    if (startsWith(path, '/sk')) {
        pathParser = new Path(`/sk${ROUTES.ARTICLE(':id')}`);
    }
    const params = pathParser.test(path);
    if (params && params.id) {
        return parseInt(params.id, 10);
    }

    return null;
}

export function getArticleName(path, searchParams) {
    let pathParser = new Path(`${ROUTES.ARTICLE('')}`);
    if (startsWith(path, '/hu')) {
        pathParser = new Path(`/hu${ROUTES.ARTICLE('')}`);
    }
    if (startsWith(path, '/en')) {
        pathParser = new Path(`/en${ROUTES.ARTICLE('')}`);
    }
    if (startsWith(path, '/sk')) {
        pathParser = new Path(`/sk${ROUTES.ARTICLE('')}`);
    }

    const params = pathParser.test(path);
    if (params) {
        const searchParamsParsed = new URLSearchParams(searchParams);
        const articleName = searchParamsParsed.get('articleName');
        return articleName;
    }

    return null;
}

export function getPathFromLocation(location) {
    const pathWithLanguage = location.pathname;
    return getPathWithoutLanguage(pathWithLanguage);
}

export function getPathWithLanguageForLanguageChange(path, language) {
    return getPathWithLanguage(path, language);
}

export function getPathWithLanguage(path, language) {
    if (language === LANGUAGE.HUNGARIAN) {
        return path;
    }

    return `/${language}${path}`;
}

export function getPathWithoutLanguage(path) {
    let pathToStrip = '';
    LANGUAGES.forEach(languageConf => {
        const languagePath = `/${languageConf.code}`;
        if (startsWith(path, languagePath)) {
            pathToStrip = languagePath;
        }
    });

    return path.slice(pathToStrip.length);
}

export function getLanguageFromLocation(location) {
    const pathWithLanguage = location.pathname;
    return getLanguageFromPath(pathWithLanguage);
}

export function getLanguageFromPath(path) {
    let language = LANGUAGE.HUNGARIAN;
    LANGUAGES.forEach(languageConf => {
        const languagePath = `/${languageConf.code}`;
        if (startsWith(path, languagePath)) {
            language = languageConf.code;
        }
    });

    return language;
}

export function getPathParserWithLanguage(path) {
    const languages = LANGUAGES.map(languageConf => languageConf.code).join('|');
    return `/:lng(${languages})?${path}`;
}