import {simpleAction} from './baseActions';
import {errorActions} from './errorActions';
import {searchService} from '../../services/searchService';

export const searchActions = {
    searchSite,
    setSearchPhrase,
    SET_SEARCH_PHRASE: 'SEARCH_SET_SEARCH_PHRASE',
    SET_SEARCH_RESULTS: 'SEARCH_SET_SEARCH_RESULTS',
};

function searchSite(phrase) {
    return async (dispatch) => {
        try {
            const results = await searchService.searchSite(phrase);
            dispatch(searchActions.setSearchPhrase(phrase));
            dispatch(simpleAction(searchActions.SET_SEARCH_RESULTS, { results }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setSearchPhrase(phrase) {
    return simpleAction(searchActions.SET_SEARCH_PHRASE, { phrase });
}