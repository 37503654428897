import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CardBase from '../cards/CardBase';
import clsx from 'clsx';
import VerticalMenu from '../menus/VerticalMenu';
import {screens} from '../../styles/styles';
import LanguageMenu from '../menus/LanguageMenu';
import ContactFooter from './ContactFooter';
import {withTranslation} from 'react-i18next';
import {getActualMenu, getActualSubMenu} from 'shared/src/helpers/menuHelper';
import {connect} from 'react-redux';
import {MENU} from 'shared/src/constants/menu';

const styles = theme => ({
    colors: {
        backgroundColor: 'rgba(57,57,57,1)',
        boxShadow: 'box-shadow: 0 12px 20px 0 rgba(0,0,0,0.2)',
    },
    layout: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0px 70px 0px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            padding: '40px 18px 70px 18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '30px 20px 113px 20px',
        },
    },
    column: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up(screens.desktop)]: {
            minWidth: theme.main.width,
            maxWidth: theme.main.width,
        },
        [theme.breakpoints.down(screens.mobile)]: {
            flexDirection: 'column',
        },
    },
    menu: {
        display: 'flex',
        [theme.breakpoints.down(screens.mobile)]: {
            flexDirection: 'column',
        },
        '& > *:not(:first-child)': {
            marginLeft: '29px',
            [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
                marginLeft: '15px',
            },
            [theme.breakpoints.down(screens.mobile)]: {
                marginLeft: '0px',
                marginTop: '40px',
            },
        }
    },
    languages: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            top: '-20px',
            right: '0px',
        }
    },
    copyright: {
        position: 'absolute',
        color: 'rgba(155,155,155,1)',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        margin: '0px',
        [theme.breakpoints.up(screens.desktop)]: {
            bottom: '-37px',
            right: '0px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            fontSize: '11px',
            fontWeight: 500,
            lineHeight: '13px',
            bottom: '-37px',
            right: '0px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            textAlign: 'left',
            fontSize: '9px',
            fontWeight: 500,
            lineHeight: '10px',
            bottom: '-81px',
            left: '0px',
        }
    },
    interregDisclaimer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        color: 'rgba(155,155,155,1)',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        margin: '0px',
        [theme.breakpoints.up(screens.desktop)]: {
            bottom: '-37px',
            left: '0px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            fontSize: '11px',
            fontWeight: 500,
            lineHeight: '13px',
            bottom: '-37px',
            left: '0px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            textAlign: 'left',
            fontSize: '11px',
            fontWeight: 500,
            lineHeight: '13px',
            bottom: '-41px',
            left: '0px',
        }
    },
    verticalMenuTitle: {
        color: theme.header.hamburgerButtonColor,
    },
    verticalMenuListItem: {
        borderColor: 'rgba(91,91,91,1)'
    },
    verticalMenuIcon: {
        color: 'rgba(149,149,149,1)',
    },
    verticalMenuLink: {
        color: theme.palette.text.primaryInverse,
    },
    contactsTop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            paddingTop: '59px',
        },
        paddingBottom: '40px',
    },
    contactsBottom: {
        flex: 1,
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
    interreg: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '20px',
        textDecoration: 'none',
    },
    interregColumn: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '10px',
    },
    interregText: {
        color: 'rgba(155,155,155,1)',
        fontSize: '14px',
        fontWeight: 'bold',
    }
});


class MainFooter extends React.Component {
    render() {
        const { classes, className, t, user, menus } = this.props;

        const menu = getActualMenu(menus, MENU.TYPES.BODROGZUG_MENU_FOOTER, user);

        return (
            <CardBase className={clsx(className, classes.colors, classes.layout)}>
                <div className={classes.column}>
                    <div className={classes.row}>
                        <div className={clsx(classes.languages)}>
                            <div/>
                            <LanguageMenu/>
                        </div>
                        <ContactFooter className={clsx(classes.contactsTop)}/>
                        <div className={classes.menu}>
                        {menu.filter(menuItem => !menuItem.parentMenuItemId).map(menuItem => (
                            <VerticalMenu
                                key={menuItem.id}
                                title={menuItem.title}
                                menuItems={getActualSubMenu(menus, menuItem.id, user)}
                                classNameTitle={classes.verticalMenuTitle}
                                classNameListItem={classes.verticalMenuListItem}
                                classNameIcon={classes.verticalMenuIcon}
                                classNameLink={classes.verticalMenuLink}
                            />
                        ))}
                        </div>
                        <ContactFooter className={clsx(classes.contactsBottom)}/>
                    </div>
                    <a className={classes.interreg} href="http://www.skhu.eu" target="_blank" rel="noopener noreferrer">
                        <img alt="Interreg" src={`/images/interreg/${t('Text.InterregLogo')}`} height="30"/>
                        <div className={classes.interregColumn}>
                            <div className={classes.interregText}>
                                {t('Text.InterregTitle')}
                            </div>
                            <div className={classes.interregText}>
                                {t('Text.InterregSlogan')}
                            </div>
                        </div>
                    </a>
                    <p className={classes.copyright}>{t('Text.Copyright')}</p>
                    <div className={classes.interregDisclaimer}>
                        {t('Text.InterregDisclaimer')}
                    </div>
                </div>
            </CardBase>
        )
    }
}

MainFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { menus } = state.menu;
    const { user } = state.user;
    return {
        user,
        menus,
    };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(MainFooter)));