import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import CardBase from '../CardBase';
import InfoLayout from '../../layouts/InfoLayout';
import AlertIcon from '../../icons/AlertIcon';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {screens} from '../../../styles/styles';

const styles = theme => ({
    card: {
        padding: '20px',
    },
    title: {
        padding: '0px 0px 13px 0px',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '21px',
        [theme.breakpoints.down(screens.tablet)]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '18px',
        },
    },
    description: {
        color: 'rgba(155,155,155,1)',
        fontSize: '16px',
        fontWeight: 'normal',
        lineSpacing: '18px',
        [theme.breakpoints.down(screens.tablet)]: {
            fontSize: '14px',
            lineHeight: '20px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '14px',
            lineHeight: '20px',
        },
        '& > a': {
            color: 'rgba(0,30,255,1)',
        }
    }
});

class DangerCard extends React.Component {
    createMarkup = (text) => {
        return { __html: text };
    };

    render() {
        const { classes, className, danger } = this.props;
        return (
            <CardBase className={clsx(classes.card, className)}>
                <InfoLayout
                    icon={(
                        <AlertIcon>
                            <PriorityHighIcon/>
                        </AlertIcon>
                    )}
                    title={(
                        <div className={classes.title}>
                            {danger.title}
                        </div>
                    )}
                    text={(
                        <div className={classes.description}
                             dangerouslySetInnerHTML={this.createMarkup(danger.summary)}/>
                    )}
                />
            </CardBase>
        )
    }
}

DangerCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

DangerCard.defaultProps = {
    className: '',
};

export default withStyles(styles)(DangerCard);