import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CardBase from '../CardBase';
import clsx from 'clsx';
import {screens} from '../../../styles/styles';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    card: {
        display: 'flex',
        padding: '20px',
        [theme.breakpoints.down(screens.mobile)]: {
            flexDirection: 'column',
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '133px',
        paddingRight: '83px',
        paddingTop: '29px',
        [theme.breakpoints.down(screens.tablet)]: {
            paddingTop: '0px',
            paddingLeft: '15px',
            paddingRight: '29.38px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            paddingTop: '20px',
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    imageMask: {
        minHeight: '271px',
        maxHeight: '271px',
        minWidth: '462px',
        maxWidth: '462px',
        [theme.breakpoints.down(screens.tablet)]: {
            minHeight: '159px',
            maxHeight: '159px',
            minWidth: '208px',
            maxWidth: '208px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minHeight: '120px',
            maxHeight: '120px',
            minWidth: 'initial',
            maxWidth: 'initial',
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    title: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '30px',
        padding: '0px 0px 11px 0px',
        [theme.breakpoints.down(screens.tablet)]: {
            fontSize: '18px',
            lineHeight: '21px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '18px',
            lineHeight: '21px',
        }
    },
    date: {
        color: 'rgba(155,155,155,1)',
        fontSize: '12px',
        lineHeight: '14px',
        paddingBottom: '13px',
    },
    textBlock: {
        '& > p': {
            fontSize: '18px',
            lineHeight: '24px',
            [theme.breakpoints.down(screens.tablet)]: {
                fontSize: '14px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(screens.mobile)]: {
                fontSize: '14px',
                lineHeight: '20px',
            },
        }
    }
});

class NewsCard extends React.Component {
    createMarkup = (text) => {
        return { __html: text };
    };

    render() {
        const { classes, className, imageFileName, url, title, date, text, i18n } = this.props;
        return (
            <CardBase className={clsx(className, classes.card)}>
                <div style={{ backgroundImage: `url("/content/images/${imageFileName}")` }} className={classes.imageMask}/>
                <div className={classes.column}>
                    {url ?
                        <Link to={getPathWithLanguage(url, i18n.language)} className={classes.title}>
                            {title}
                        </Link>
                        :
                        <div className={classes.title}>
                            {title}
                        </div>
                    }
                    {date ?
                        <div className={classes.date}>
                            {date}
                        </div>
                        :
                        null
                    }
                    <div className={classes.textBlock} dangerouslySetInnerHTML={this.createMarkup(text)}/>
                </div>
            </CardBase>
        )
    }
}

NewsCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(NewsCard));