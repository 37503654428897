import {loginActions} from '../actions/loginActions';

const initialState = {
};

export function login(state = initialState, action) {
    switch (action.type) {
        case loginActions.LOGIN_SUCCESS:
            return {
                success: true,
            };
        case loginActions.LOGIN_FAILURE:
            return {
                error: action.payload,
            };
        case loginActions.LOGOUT:
            return {
                success: false,
            };
        default:
            return state
    }
}
