import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';

const styles = theme => ({});

class NotFound extends React.Component {
    render() {
        return (
            <div>
                404 - page not found
            </div>
        );
    }
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);