import {accommodationActions} from '../actions/accommodationActions';

const initialState = {
    accommodation: null,
    accommodations: [],
    accommodationCategories: [],
    accommodationServices: [],
};

export function accommodation(state = initialState, action) {
    switch (action.type) {
        case accommodationActions.SET_ACCOMMODATION:
            return {
                ...state,
                accommodation: action.payload.accommodation,
            };
        case accommodationActions.SET_ACCOMMODATIONS:
            return {
                ...state,
                accommodations: action.payload.accommodations,
            };
        case accommodationActions.SET_ACCOMMODATION_CATEGORIES:
            return {
                ...state,
                accommodationCategories: action.payload.accommodationCategories,
            };
        case accommodationActions.SET_ACCOMMODATION_SERVICES:
            return {
                ...state,
                accommodationServices: action.payload.accommodationServices,
            };
        default:
            return state
    }
}
