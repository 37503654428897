import React from 'react';
import CardBase from '../CardBase';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {screens} from '../../../styles/styles';
import {connect} from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';

const styles = theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    image: {
        minHeight: '450px',
        maxHeight: '450px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minHeight: '300px',
            maxHeight: '300px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minHeight: '192px',
            maxHeight: '192px',
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    articleLayout: {
        paddingTop: '35px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '30px',
    },
    title: {
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '35px',
        paddingBottom: '25px',
    },
    date: {
        color: 'rgba(155,155,155,1)',
        fontSize: '12px',
        lineHeight: '14px',
        paddingBottom: '23px',
    },
    textBlock: {
        '& > p': {
            color: 'rgba(74,74,74,1)',
            fontSize: '16px',
            lineHeight: '21px',
            [theme.breakpoints.down(screens.mobile)]: {
                fontSize: '14px',
                lineHeight: '18px',
            },
            '&:not(:first-child)': {
                marginTop: '30px',
            },
        },
        '& img': {
            maxWidth: '100%',
        },
        color: 'rgba(74,74,74,1)',
        fontSize: '16px',
        lineHeight: '21px',
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '14px',
            lineHeight: '18px',
        },
    },
    articleImage: {
        '&:not(:first-child)': {
            marginTop: '30px',
        }
    }
});

export class ArticleCard extends React.Component {
    createMarkup = (text) => {
        return { __html: text };
    };

    render() {
        const { classes, className, content: article } = this.props;

        if (!article) {
            return null;
        }

        return (
            <CardBase className={className}>
                <div className={classes.layout}>
                    {article.headerImage && <div className={classes.image}
                                                 style={{ backgroundImage: `url("/content/images/${article.headerImage}")` }}/>}
                    <div className={classes.articleLayout}>
                        <div className={classes.title}>
                            {article.title}
                        </div>
                        <div className={classes.date}>
                            {article.date && moment(article.date).format('YYYY. MM. DD.')}
                        </div>
                        <div className={clsx('ql-editor', classes.textBlock)} dangerouslySetInnerHTML={this.createMarkup(article.body)}/>
                    </div>
                </div>
            </CardBase>
        )
    }
}

ArticleCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

ArticleCard.defaultProps = {
    className: '',
};

const mapStateToProps = (state) => {
    const { content } = state.content;
    return {
        content,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(ArticleCard));