import {tourService} from '../../services';
import {simpleAction} from './baseActions';
import {errorActions} from './errorActions';
import {TOURS} from 'shared/src/constants/tours';
import {CONTENT} from 'shared';

export const tourActions = {
    loadTour,
    loadTopCaveTours,
    loadTopProgrammes,
    loadTours,
    setTour,
    SET_TOUR: 'TOURS_SET_TOUR',
    SET_TOURS: 'TOURS_SET_TOURS',
    SET_TOP_CAVE_TOURS: 'TOURS_SET_TOP_CAVE_TOURS',
    SET_TOP_PROGRAMMES: 'TOURS_SET_TOP_PROGRAMMES',
};

function loadTour(tourId) {
    return async (dispatch) => {
        try {
            const tour = await tourService.getTour(tourId);
            dispatch(simpleAction(tourActions.SET_TOUR, { tour }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadTours() {
    return async (dispatch) => {
        try {
            const tours = await tourService.getTours({});
            dispatch(simpleAction(tourActions.SET_TOURS, { tours }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadTopCaveTours(count = CONTENT.MAX_HOME_PAGE_TOP_CAVE_TOUR_COUNT) {
    return async (dispatch) => {
        try {
            const topCaveTours = await tourService.getTours({
                tourTypeId: TOURS.TYPES.CAVE_TOUR,
                topCaveTour: true,
                start: 1,
                end: count,
                sort: 'topCaveTourIndex',
                order: 'ASC'
            });
            dispatch(simpleAction(tourActions.SET_TOP_CAVE_TOURS, { topCaveTours }));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setTour(tour) {
    return simpleAction(tourActions.SET_TOUR, { tour });
}

function loadTopProgrammes(count = CONTENT.MAX_HOME_PAGE_TOP_PROGRAM_COUNT) {
    return async (dispatch) => {
        try {
            const topProgrammes = await tourService.getTours({
                topProgram: true,
                start: 1,
                end: count,
                sort: 'topProgramIndex',
                order: 'ASC'
            });
            dispatch(setTopProgrammes(topProgrammes));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setTopProgrammes(topProgrammes) {
    return {
        type: tourActions.SET_TOP_PROGRAMMES,
        payload: {
            topProgrammes,
        }
    };
}