import React from 'react';
import {withStyles} from '@material-ui/core';
import Button from "@material-ui/core/Button/Button";
import clsx from 'clsx';
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {
    },
    button: {
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
});

class SubmitButton extends React.Component {
    render() {
        const { classes, className, title } = this.props;
        return (
            <Button className={clsx(className, classes.root, classes.button)} type="submit">
                {title}
            </Button>
        );
    }
}

SubmitButton.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
};

SubmitButton.defaultProps = {
    className: '',
};

export default withStyles(styles)(SubmitButton);