import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {ROUTES} from 'shared/src';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const styles = theme => ({
    align: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontSize: '15px',
        lineHeight: '18px',
        fontWeight: 'normal',
        textTransform: 'none',
        textDecoration: 'none',
    },
    size: {
        minHeight: "40px",
        maxHeight: "40px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    color: {
        color: theme.palette.text.primaryInverse,
        backgroundColor: '#0054FF',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
    },
    shape: {
        borderRadius: '25px',
    },
});

class CheckoutButton extends React.Component {
    render() {
        const { classes, className, t, i18n } = this.props;
        return (
            <Link className={clsx(className, classes.align, classes.text, classes.color, classes.size, classes.shape)}
                  to={getPathWithLanguage(ROUTES.CHECKOUT, i18n.language)}>
                {t('Button.Checkout')}
            </Link>
        );
    }
}

CheckoutButton.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

CheckoutButton.defaultProps = {
    className: '',
};

export default withTranslation()(withStyles(styles)(CheckoutButton));