import React from 'react';
import PropTypes from 'prop-types';
import OrderAcknowledgementCard from '../components/cards/checkout/OrderAcknowledgementCard';
import SecondaryScreen from '../components/screens/SecondaryScreen';

class OrderAcknowledgement extends React.Component {
    render() {
        return (
            <SecondaryScreen>
                <OrderAcknowledgementCard/>
            </SecondaryScreen>
        );
    }
}

OrderAcknowledgement.propTypes = {
    classes: PropTypes.object.isRequired,
    classNames: PropTypes.string,
};

OrderAcknowledgement.defaultProps = {
    classes: {},
    className: '',
};

export default OrderAcknowledgement;