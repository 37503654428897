import {settingActions} from '../actions/settingActions';

const initialState = {
    settings: null,
};

export function setting(state = initialState, action) {
    switch (action.type) {
        case settingActions.SET_SETTINGS:
            return {
                settings: action.payload.settings,
            };
        default:
            return state
    }
}
