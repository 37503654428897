import React from 'react';
import MainScreen from "../components/screens/MainScreen";
import MainInfoCard from '../components/cards/mainInfo/MainInfoCard';
import CardMenu from '../components/menus/CardMenu';
import NewsCard from '../components/cards/news/NewsCard';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import {screens} from '../styles/styles';
import MainSummaryCard from '../components/cards/mainInfo/MainSummaryCard';
import {useSelector} from 'react-redux';
import DangerCard from '../components/cards/danger/DangerCard';
import _ from 'lodash';
import {contentActions} from 'client_shared/src/state/actions/contentActions';
import {ROUTES} from 'shared/src/constants/routes';

const useStyles = makeStyles(theme => ({
    mainInfoCard: {
        marginBottom: '40px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginBottom: '14px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '17px',
        },
    },
    dangerCard: {
        marginBottom: '47px',
        [theme.breakpoints.down(screens.tablet)]: {
            marginBottom: '14px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '25px',
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    cardMenu: {
        marginBottom: '47px',
        [theme.breakpoints.down(screens.tablet)]: {
            marginBottom: '14px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '12px',
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    newsCard: {
        marginBottom: '10px',
        '&:last-child': {
            marginBottom: '47px',
        },
        [theme.breakpoints.down(screens.tablet)]: {
            marginBottom: '10px',
            '&:last-child': {
                marginBottom: '35px',
            },
            marginLeft: '18px',
            marginRight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '19px',
            '&:last-child': {
                marginBottom: '20px',
            },
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
    separator: {
        width: '100%',
        borderTop: '1px solid rgba(239,239,239,1)',
    },
}));

function Home() {
    const classes = useStyles();

    const homePageNews = useSelector(state => state.content.homePageNews);
    const dangers = useSelector(state => state.content.dangers);

    return (
        <MainScreen sideMenu={(
            <div className={clsx(classes.hideOnDesktop, classes.hideOnMobile)}><MainSummaryCard/>
                <div className={classes.separator}/>
                <CardMenu/></div>)}>
            <MainInfoCard className={classes.mainInfoCard}/>
            {dangers && dangers.filter(danger => danger.enabled && danger.title && danger.summary).map(danger =>
                <DangerCard key={danger.id} className={classes.dangerCard} danger={danger}/>
            )}
            <CardMenu className={clsx(classes.cardMenu, classes.hideOnTablet)}/>
            <div>
                {homePageNews && homePageNews.map(news => (
                    news.enabled && news.title && news.summary &&
                    <NewsCard key={news.id} url={ROUTES.ARTICLE(news.id)} title={news.title} text={news.summary}
                              imageFileName={news.headerImage} date={null} className={classes.newsCard}/>
                ))}
            </div>
        </MainScreen>
    );
}

Home.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.content.homePageNews)) {
        promises.push(dispatch(contentActions.loadHomePageNews()));
    }

    if (_.isEmpty(storeState.content.dangers)) {
        promises.push(dispatch(contentActions.loadDangers()));
    }

    if (_.isEmpty(storeState.content.homeSummary)) {
        promises.push(dispatch(contentActions.loadHomeSummary()));
    }

    return promises;
};

export default Home;
