import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {screens} from '../../styles/styles';
import {connect} from 'react-redux';
import {loginActions, shoppingCartActions, userActions} from 'client_shared/src/state/actions';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const styles = theme => ({
    root: {
        minWidth: '280px',
        maxWidth: '280px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minWidth: '200px',
            maxWidth: '200px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minWidth: 'initial',
            maxWidth: 'initial',
        },
    },

    title: {
        fontWeight: '900',
        fontSize: '14px',
        marginTop: '0px',
        marginBottom: '18px',
        lineHeight: '16px',
        textTransform: 'uppercase',
    },

    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "stretch",
        justifyContent: "space-between",
        margin: 0,
        padding: 0,
    },

    listItem: {
        display: 'flex',
        listStyleType: "none",
        minHeight: '38px',
        maxHeight: '38px',
        borderTopWidth: '2px',
        borderTopStyle: 'solid',
        '&:last-child': {
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        }
    },

    link: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textDecoration: "none",
        textTransform: 'uppercase',
        fontSize: '13px',
        lineHeight: '15px',
        fontWeight: '500',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    icon: {
        minWidth: '20px',
        maxWidth: '20px',
        minHeight: '20px',
        maxHeight: '20px',
        color: 'inherit',
    }
});

class VerticalMenu extends React.Component {
    onLoginOpen = () => {
        this.props.user ? this.props.dispatch(userActions.logout()) : this.props.dispatch(loginActions.newLogin());
    };

    onLogout = () => {
        this.props.dispatch(userActions.logout());
    };

    onOrdersOpen = () => {
        this.props.dispatch(shoppingCartActions.showShoppingCart());
    };

    render() {
        const { classes, className, title, menuItems, classNameTitle, classNameListItem, classNameIcon, classNameLink, t, i18n } = this.props;
        return (
            <div className={clsx(classes.root, className)}>
                <p className={clsx(classes.title, classNameTitle)}>{title}</p>
                <ul className={classes.list}>
                    {menuItems.map(menuItem =>
                        <li key={menuItem.id} className={clsx(classes.listItem, classNameListItem)}>
                            {menuItem.url.search('http') === -1 ?
                                <Link to={getPathWithLanguage(menuItem.url, i18n.language)} className={clsx(classes.link, classNameLink)}>
                                    {t(menuItem.title)}
                                    <ChevronRightIcon className={clsx(classes.icon, classNameIcon)}/>
                                </Link>
                                :
                                <a href={menuItem.url} target="_blank" rel="noopener noreferrer"
                                   className={clsx(classes.link, classNameLink)}>
                                    {t(menuItem.title)}
                                    <ChevronRightIcon className={clsx(classes.icon, classNameIcon)}/>
                                </a>
                            }
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

VerticalMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.user;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(VerticalMenu)));