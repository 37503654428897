import {userActions} from '../actions/userActions';

const initialState = {
    user: null,
    cookiesAccepted: true,
    newsletterSubscribed: false,
    cookiesPopupOpen: false,
};

export function user(state = initialState, action) {
    switch (action.type) {
        case userActions.SET_USER:
            return {
                ...state,
                user: action.payload.user,
            };
        case userActions.REMOVE_USER:
            return {
                ...state,
                user: null,
            };
        case userActions.SET_COOKIES_ACCEPTED:
            return {
                ...state,
                cookiesAccepted: action.payload.cookiesAccepted,
            };
        case userActions.SET_COOKIES_POPUP_OPEN:
            return {
                ...state,
                cookiesPopupOpen: action.payload.cookiesPopupOpen,
            };
        case userActions.SET_NEWSLETTER_SUBSCRIBED:
            return {
                ...state,
                newsletterSubscribed: action.payload.newsletterSubscribed,
            };
        default:
            return state
    }
}
