import React, {useState} from 'react';
import MainScreen from "../components/screens/MainScreen";
import NewsSummaryCard from '../components/cards/news/NewsSummaryCard';
import NewsCard from '../components/cards/news/NewsCard';
import {makeStyles} from '@material-ui/core';
import {screens} from '../styles/styles';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import {contentActions} from 'client_shared/src/state/actions/contentActions';
import {ROUTES} from 'shared';

const useStyles = makeStyles(theme => ({
    newsSummaryCard: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginLeft: '18px',
            marginRight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    newsCard: {
        marginBottom: '10px',
        '&:last-child': {
            marginBottom: '47px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginBottom: '10px',
            '&:last-child': {
                marginBottom: '35px',
            },
            marginLeft: '18px',
            marginRight: '18px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '28px',
            '&:last-child': {
                marginBottom: '47px',
            },
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'center',
    },
    moreButton: {
        margin: '10px',
        paddingLeft: '60px',
        paddingRight: '60px',
        minHeight: '60px',
        maxHeight: '60px',
        borderRadius: '4px',
        backgroundColor: '#709cfa',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            marginTop: '0px',
            marginBottom: '10px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginTop: '0px',
            marginBottom: '30px',
        },
    }
}));

function News() {
    const classes = useStyles();
    const news = useSelector(state => state.content.news);
    const settings = useSelector(state => state.setting.settings);
    const [page, setPage] = useState(1);

    const { t } = useTranslation();

    function onMore() {
        setPage(page + 1);
    }

    const newsNumber = settings ? page * settings.newsPageNewsLimit : 0;

    return (
        <MainScreen>
            <NewsSummaryCard className={classes.newsSummaryCard}/>
            {news && news.map((newsItem, index) => (
                index < newsNumber && newsItem.title && newsItem.summary && <NewsCard
                    key={newsItem.id}
                    title={newsItem.title}
                    text={newsItem.summary}
                    imageFileName={newsItem.headerImage}
                    date={moment(newsItem.date).format('YYYY. MM. DD')}
                    className={classes.newsCard}
                    url={ROUTES.ARTICLE(newsItem.id)}
                />
            ))}
            {news.length > newsNumber &&
            <div className={classes.buttonRow}>
                <Button className={classes.moreButton} onClick={onMore}>
                    {t('Button.MoreNews')}
                </Button>
            </div>
            }
        </MainScreen>
    );
}

News.initState = (environment) => {
    const { storeState, dispatch } = environment;

    const promises = [];

    if (_.isEmpty(storeState.content.news)) {
        promises.push(dispatch(contentActions.loadNews()));
    }

    return promises;
};

export default News;
