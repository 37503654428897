import {simpleAction} from './baseActions';
import {mapService} from '../../services';
import {errorActions} from './errorActions';

export const mapActions = {
    getLayers,
    setLayers,
    updateLayer,
    zoomToFeature,
    zoomToFeatureDone,
    showFeatureInfo,
    hideFeatureInfo,
    centerMap,
    centerMapDone,
    SET_LAYERS: 'MAP_SET_LAYERS',
    UPDATE_LAYER: 'MAP_UPDATE_LAYER',
    ZOOMING_REQUESTED: 'MAP_ZOOMING_REQUESTED',
    ZOOMING_DONE: 'MAP_ZOOMING_DONE',
    SHOW_FEATURE_INFO: 'MAP_SHOW_FEATURE_INFO',
    HIDE_FEATURE_INFO: 'MAP_HIDE_FEATURE_INFO',
    CENTER_MAP: 'MAP_CENTER_MAP',
    CENTER_MAP_DONE: 'MAP_CENTER_MAP_DONE',
};

function getLayers() {
    return async (dispatch) => {
        try {
            const layers = await mapService.getMapLayers();
            dispatch(mapActions.setLayers(layers));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setLayers(layers) {
    return simpleAction(mapActions.SET_LAYERS, { layers });
}

function updateLayer(layer) {
    return simpleAction(mapActions.UPDATE_LAYER, { layer });
}

function zoomToFeature(feature) {
    return simpleAction(mapActions.ZOOMING_REQUESTED, { feature });
}

function zoomToFeatureDone() {
    return simpleAction(mapActions.ZOOMING_DONE, {});
}

function showFeatureInfo(mapFeature, clickPosition) {
    return simpleAction(mapActions.SHOW_FEATURE_INFO, {
        mapFeature,
        clickPosition,
    })
}

function hideFeatureInfo() {
    return simpleAction(mapActions.HIDE_FEATURE_INFO, {})
}

function centerMap() {
    return simpleAction(mapActions.CENTER_MAP, {})
}

function centerMapDone() {
    return simpleAction(mapActions.CENTER_MAP_DONE, {})
}
