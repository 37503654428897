import {errorActions} from './errorActions';
import {faqService} from '../../services/faqService';
import {simpleAction} from './baseActions';

export const faqActions = {
    loadFaqCategories,
    loadFaqsForCategory,
    setFaqCategory,
    setFaqChildCategory,
    SET_FAQ_CATEGORIES: 'FAQ_SET_FAQ_CATEGORIES',
    SET_FAQ_CATEGORY: 'PAGE_SET_FAQ_CATEGORY',
    SET_FAQ_CHILD_CATEGORY: 'PAGE_SET_FAQ_CHILD_CATEGORY',
    SET_FAQS: 'FAQ_SET_FAQS',
};

function loadFaqsForCategory(categoryId) {
    return async (dispatch) => {
        try {
            const faqs = await faqService.getFaqsForCategory(categoryId);
            dispatch(simpleAction(faqActions.SET_FAQS, {faqs}));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadFaqCategories() {
    return async (dispatch) => {
        try {
            const faqCategories = await faqService.getFaqCategories();
            dispatch(simpleAction(faqActions.SET_FAQ_CATEGORIES, {faqCategories}));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function setFaqCategory(faqCategory) {
    return simpleAction(faqActions.SET_FAQ_CATEGORY, {faqCategory});
}

function setFaqChildCategory(faqChildCategory) {
    return simpleAction(faqActions.SET_FAQ_CHILD_CATEGORY, {faqChildCategory});
}
