import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {screens} from '../../styles/styles';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    imageMask: {
        minHeight: '525px',
        maxHeight: '525px',
        minWidth: '893px',
        maxWidth: '893px',
        backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0.8) 100%), url("/content/images/image1.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        [theme.breakpoints.down(screens.tablet)]: {
            minHeight: '302px',
            maxHeight: '302px',
            minWidth: 'initial',
            maxWidth: 'initial',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minHeight: '192px',
            maxHeight: '192px',
            minWidth: 'initial',
            maxWidth: 'initial',
        },
    },
    imageTitle: {
        color: theme.palette.text.primaryInverse,
        fontSize: '36px',
        fontWeight: 'bold',
        lineHeight: '42px',
        paddingTop: '428px',
        paddingLeft: '37px',
        margin: 0,
    },
    imageSubTitle: {
        color: theme.palette.text.secondaryInverse,
        fontSize: '20px',
        lineHeight: '24px',
        paddingTop: '5px',
        paddingLeft: '37px',
        margin: 0,
    },

    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnMobileAndTablet: {
        [theme.breakpoints.down(screens.tablet)]: {
            display: 'none',
        }
    },
});

export class MainImage extends React.Component {
    render() {
        const { classes, className, t } = this.props;
        return (
            <div className={clsx(classes.imageMask, className) }>
                <p className={clsx(classes.imageTitle, classes.hideOnMobileAndTablet)}>{t('Button.Bodrogzug')}</p>
                <p className={clsx(classes.imageSubTitle, classes.hideOnMobileAndTablet)}>{t('Text.BodrogzugSubTitle')}</p>
            </div>
        )
    }
}

MainImage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

MainImage.defaultProps = {
    className: '',
};

export default withTranslation()(withStyles(styles)(MainImage));