import {errorActions} from './errorActions';
import {accommodationService} from '../../services/accommodationService';
import {simpleAction} from './baseActions';

export const accommodationActions = {
    loadAccommodation,
    loadAccommodations,
    loadAccommodationCategories,
    loadAccommodationServices,
    SET_ACCOMMODATION: 'ACCOMMODATION_SET_ACCOMMODATION',
    SET_ACCOMMODATIONS: 'ACCOMMODATION_SET_ACCOMMODATIONS',
    SET_ACCOMMODATION_CATEGORIES: 'ACCOMMODATION_SET_ACCOMMODATION_CATEGORIES',
    SET_ACCOMMODATION_SERVICES: 'ACCOMMODATION_SET_ACCOMMODATION_SERVICES',
};

function loadAccommodation(accommodationId) {
    return async (dispatch) => {
        try {
            const accommodation = await accommodationService.getAccommodation(accommodationId);
            dispatch(simpleAction(accommodationActions.SET_ACCOMMODATION, {accommodation}));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadAccommodations() {
    return async (dispatch) => {
        try {
            const accommodations = await accommodationService.getAccommodations();
            dispatch(simpleAction(accommodationActions.SET_ACCOMMODATIONS, {accommodations}));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}

function loadAccommodationCategories() {
    return async (dispatch) => {
        try {
            const accommodationCategories = await accommodationService.getAccommodationCategories();
            dispatch(simpleAction(accommodationActions.SET_ACCOMMODATION_CATEGORIES, {accommodationCategories}));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}
function loadAccommodationServices() {
    return async (dispatch) => {
        try {
            const accommodationServices = await accommodationService.getAccommodationServices();
            dispatch(simpleAction(accommodationActions.SET_ACCOMMODATION_SERVICES, {accommodationServices}));
        } catch (error) {
            dispatch(errorActions.setError(error));
        }
    };
}
