import React from 'react';
import CardBase from '../CardBase';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import {screens} from '../../../styles/styles';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
    descriptionLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    descriptionBlockLayout: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            padding: '20px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '35px 20px 20px 20px',
        },
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '28px',
        paddingBottom: '15px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            fontSize: '24px',
            lineHeight: '28px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '24px',
            lineHeight: '28px',
        },
    },
    description: {
        color: 'rgba(74,74,74,1)',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'normal',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            fontSize: '14px',
            lineHeight: '20px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    phoneTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    phone: {
        color: 'rgba(74,74,74,1)',
        fontSize: '16px',
        fontWeight: 'normal',
    },
    separator: {
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        borderTop: `2px solid ${theme.palette.grey['200']}`,
    },
    icon: {
        color: 'rgba(255,159,0,1)',
        minHeight: '20px',
        maxHeight: '20px',
        minWidth: '20px',
        maxWidth: '20px',
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnMobileAndTablet: {
        [theme.breakpoints.down(screens.tablet)]: {
            display: 'none',
        }
    },
}));

export default function MainSummaryCard(props) {
    const { className } = props;
    const classes = useStyles();
    const homeContent = useSelector(state => state.content.homeSummary);

    function createMarkup(text) {
        return { __html: text || '' };
    }

    return (
        <CardBase className={clsx(classes.descriptionBlockLayout, className)}>
            <div className={classes.title}>
                {homeContent && homeContent.title}
            </div>
            <div className={classes.description}
                 dangerouslySetInnerHTML={createMarkup(homeContent && homeContent.summary)}/>
        </CardBase>
    );
}
