import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {FormHelperText} from '@material-ui/core';
import 'moment/locale/hu';
import TextField from '@material-ui/core/TextField/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {screens} from '../../../styles/styles';
import numeral from 'numeral';
import {withTranslation} from 'react-i18next';
import {FieldArray} from 'formik';
import {orderModule} from 'shared/src/modules/order';
import {orderActions} from 'client_shared/src/state/actions';

const styles = theme => ({
    card: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        borderColor: 'rgba(239,239,239,1)',
        [theme.breakpoints.up(screens.desktop)]: {
            margin: '30px 0px 0px 0px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            margin: '30px 0px 0px 0px',
            paddingBottom: '30px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '30px 30px 30px 30px',
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '11px',
    },
    title: {
        color: 'rgba(142,142,147,1)',
        fontSize: '14px',
        letterSpacing: '0.17px',
        lineHeight: '16px',
    },
    freeAmountText1: {
        color: 'rgba(18,218,173,1)',
        fontSize: '14px',
        marginLeft: '15px',
    },
    amount: {
        maxWidth: '52px',
        marginTop: '0px',
        marginBottom: '0px',
    },
    input: {
        fontSize: '17px !important',
        textAlign: 'center',
    },
    amountContainer: {
        marginBottom: '30px',
        '&:last-child': {
            marginBottom: '11px',
        }
    },
    amountRow: {
        display: 'flex',
        alignItems: 'center',
    },
    amountText: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19px',
    },
    amountTextColumn: {
        paddingLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    freeAmountText: {
        fontSize: '14px',
    },
});

function OrderAmountCard(props) {
    const { classes, className, values, handleChange, handleBlur, t } = props;
    const freeAmount = Math.max(0, values.amountLimit - orderModule.getOrderAmount(values));

    const dispatch = useDispatch();
    
    useEffect(() => {
        const order = orderModule.recalculateOrder(values);
        dispatch(orderActions.setOrder(order));
    }, [dispatch, values]);


    const order = useSelector(state => state.order.order);

    if (!order) {
        return null;
    }

    return (
        <div className={clsx(className, classes.card, classes.container)}>
            <div className={classes.titleRow}>
                <div className={classes.title}>
                    {t('Checkout.Amount')}
                </div>
                <div className={classes.freeAmountText1}>
                    {freeAmount} {t('Checkout.FreeAmount')}
                </div>
            </div>
            <FieldArray
                name="blocks"
                render={({ form }) => {
                    return (
                        <div>
                            {values.orderItems.map((orderItem, index) =>
                                <div key={index} className={classes.amountContainer}>
                                    <div className={classes.amountRow}>
                                        <TextField
                                            name={`orderItems.${index}.amount`}
                                            value={form.values.orderItems[index].amount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            type="number"
                                            classes={{ root: classes.amount }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.input
                                                },
                                            }}
                                        />
                                        <div className={classes.amountTextColumn}>
                                            <div className={classes.amountText}>
                                                {orderItem.product.title}
                                            </div>
                                            <div className={classes.freeAmountText}>
                                                {`${numeral(orderItem.product.price).format('0,0').replace(/,/g, ' ')} Ft / ${t('Checkout.Person')} / ${t('Checkout.Day')}`}
                                            </div>
                                        </div>

                                        <div className={classes.amountText}>
                                        </div>
                                    </div>
                                    <FormHelperText
                                        error={!!(form.touched.orderItems && form.touched.orderItems[index] && form.touched.orderItems[index].amount && form.errors.orderItems &&
                                            form.errors.orderItems[index] && form.errors.orderItems[index].amount)}
                                    >
                                        {form.touched.orderItems && form.touched.orderItems[index] && form.touched.orderItems[index].amount && form.errors.orderItems &&
                                        form.errors.orderItems[index] && form.errors.orderItems[index].amount}
                                    </FormHelperText>
                                </div>
                            )}
                        </div>
                    )
                }
                }
            />
        </div>
    );
}

export default withTranslation()(withStyles(styles)(OrderAmountCard));
