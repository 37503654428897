import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {screens} from '../../../styles/styles';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: '30px',
        paddingBottom: '30px',
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '30px',
        },
    },
    title: {
        color: 'rgba(74,74,74,1)',
        fontSize: '18px',
        fontWeight: 400,
        paddingBottom: '30px',
        lineHeight: '24px',
    },
    contact: {
        color: 'rgba(0,30,255,1)',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
        paddingBottom: '30px',
        textDecoration: 'underline',
    },
    addressTitle: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'bold',
        paddingBottom: '20px',
    },
    address: {
        fontSize: '14px',
        lineHeight: '20px',
    },
});

class OrderHelpCard extends React.Component {
    render() {
        const { classes, className, t } = this.props;
        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.title}>
                    {t('Text.OrderHelpTitle')}
                </div>
                <div className={classes.contact}>
                    <a href={`mailto:${t('Text.EmailAddress')}`} className={classes.contact}>{t('Text.EmailAddress')}</a>
                </div>
                <div className={classes.contact}>
                    {t('Text.OrderHelpPhone')}
                </div>
                <div className={classes.addressTitle}>
                    {t('Text.OrderHelpAddress1')}
                </div>
                <div className={classes.address}>
                    {t('Text.OrderHelpAddress2')}
                </div>
            </div>
        )
    }
}

OrderHelpCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

OrderHelpCard.defaultProps = {
    className: '',
};


export default withTranslation()(withStyles(styles)(OrderHelpCard));