import React from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import {screens} from '../../styles/styles';

const styles = theme => ({
    root: {
        borderRadius: '12px',
        minWidth: '60px',
        maxWidth: '60px',
        minHeight: '60px',
        maxHeight: '60px',
        position: 'relative',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minWidth: '48px',
            maxWidth: '48px',
            minHeight: '48px',
            maxHeight: '48px',
        },
    },
    icon: {
        color: theme.palette.text.primaryInverse,
        minWidth: '30px',
        maxWidth: '30px',
        minHeight: '30px',
        maxHeight: '30px',
        position: 'absolute',
        top: '15px',
        left: '15px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minWidth: '25px',
            maxWidth: '25px',
            minHeight: '25px',
            maxHeight: '25px',
            top: '11.5px',
            left: '11.5px',
        },
    }
});

class FramedInfoIcon extends React.Component {
    render() {
        const { classes, className } = this.props;
        const child = React.Children.toArray(this.props.children)[0];
        const iconWithExtraProp = React.cloneElement(child, {
                className: classes.icon,
        });
        return (
            <div className={clsx(classes.root, className)}>
                {iconWithExtraProp}
            </div>
        );
    }
}

FramedInfoIcon.propTypes = {};

export default withStyles(styles)(FramedInfoIcon);