import {tourGuideActions} from '../actions/tourGuideActions';

const initialState = {
    tourGuide: null,
    tourGuides: [],
};

export function tourGuide(state = initialState, action) {
    switch (action.type) {
        case tourGuideActions.SET_TOUR_GUIDES:
            return {
                ...state,
                tourGuides: action.payload.tourGuides,
            };
        case tourGuideActions.SET_TOUR_GUIDE:
            return {
                ...state,
                tourGuide: action.payload.tourGuide,
            };
        default:
            return state
    }
}
