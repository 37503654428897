import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import CardBase from '../CardBase';
import 'moment/locale/hu';
import moment from 'moment';
import numeral from 'numeral';
import {orderModule} from 'shared/src/modules/order';
import clsx from 'clsx';
import {CHECKOUT} from 'shared/src/constants/checkout';

const styles = theme => ({
    card: {
        padding: '30px',
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        borderColor: 'rgba(239,239,239,1)',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.16)',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    labelRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    firstLabelRow: {
        paddingBottom: '2px',
    },
    date: {
        color: 'rgba(142,142,147,1)',
        fontSize: '12px',
        letterSpacing: '0.14px',
        lineHeight: '14px',
    },
    amount: {
        color: 'rgba(142,142,147,1)',
        fontSize: '13px',
        letterSpacing: '0.19px',
        fontWeight: 'normal',
        lineHeight: '16px',
    },
    name: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '0.19px',
        lineHeight: '18px',
    },
    price: {
        fontSize: '20px',
        fontWeight: 'bold',
        letterSpacing: '0.24px',
        lineHeight: '24px',
    },
    unpaid: {
        color: 'red',
    },
    paid: {
        color: 'green',
    }
});

function OrderCard(props) {
    const { classes, className, order } = props;

    if (!order) {
        return null;
    }

    orderModule.recalculateOrder(order);

    moment.locale('hu');

    const fromDate = moment(order.fromDate).startOf('day');
    const toDate = moment(order.toDate).startOf('day');

    const tourDays = orderModule.getOrderTourDays(order);

    return (
        <CardBase className={clsx(className, classes.card, classes.container)}>
            <div className={clsx(classes.labelRow, classes.firstLabelRow)}>
                <div className={classes.date}>
                    {tourDays === 1 ?
                        `${fromDate.format('YYYY. MMMM DD.')}`
                        :
                        `${fromDate.format('YYYY. MMMM DD.')} - ${toDate.format('YYYY. MMMM DD.')}`
                    }
                </div>
                <div className={classes.amount}>
                    {`${orderModule.getOrderAmount(order)} fő`}
                </div>
            </div>
            <div className={classes.labelRow}>
                <div className={classes.name}>
                    {`${order.guideName}`}
                </div>
                <div className={clsx(classes.price, order.paymentStatus === CHECKOUT.PAYMENT_STATUSES.OUTSTANDING ? classes.unpaid : classes.paid)}>
                    {`${numeral(order.price).format('0,0').replace(/,/g, ' ')} Ft`}
                </div>
            </div>
        </CardBase>
    )
}

export default withStyles(styles)(OrderCard);