import React from 'react';
import {ErrorBar} from './ErrorBar';
import {useDispatch, useSelector} from 'react-redux';
import {SERVER_ERROR_INVALID_CREDENTIALS} from '../../services/baseService';
import {errorActions} from '../../state/actions';
import {useTranslation} from 'react-i18next';

export default function Errors(props) {
    const { children } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const error = useSelector(state => state.error.error);

    const handleClose = () => {
        dispatch(errorActions.setError(null));
    };

    return (
        <>
            {error && error.code !== SERVER_ERROR_INVALID_CREDENTIALS &&
            <ErrorBar message={'Hiba történt! A szolgáltatás jelenleg nem elérhető.'} onClose={handleClose}/>
            }
            {children}
        </>
    );
}
