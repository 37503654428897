import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {withTranslation} from 'react-i18next';

const styles = theme => ({
    root: {},
    title: {
        paddingTop: '27px',
        paddingBottom: '15px',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '28px',
    },
});

class NewsSummaryCard extends React.Component {
    render() {
        const { classes, className, t } = this.props;
        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.title}>
                    {t('Button.News')}
                </div>
            </div>
        )
    }
}

NewsSummaryCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

NewsSummaryCard.defaultProps = {
    className: '',
};


export default withTranslation()(withStyles(styles)(NewsSummaryCard));