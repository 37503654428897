import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {screens} from '../../styles/styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(screens.tablet)]: {
            flexDirection: 'column',
        },
    },
    item: {
        [theme.breakpoints.up(screens.desktop)]: {
            marginRight: '20px',
            '&:last-child': {
                marginRight: "0px",
            },
        },
        flex: 1,
    },
});

class SecondaryCardList extends React.Component {
    render() {
        const { classes, className } = this.props;
        const childrenWithExtraProps = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                className: clsx(child.props.className, classes.item),
            });
        });

        return (
            <div className={clsx(className, classes.root)}>
                {childrenWithExtraProps}
            </div>
        )
    }
}

SecondaryCardList.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

SecondaryCardList.defaultProps = {
    className: '',
};

export default withStyles(styles)(SecondaryCardList);