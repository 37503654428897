import {ROUTES} from './routes';

export const MENU = {
    TYPES: {
        BODROGZUG_MENU_MAIN: 1,
        BODROGZUG_MENU_FOOTER: 2,
        BODROGZUG_MENU_ICON: 3,
        BODROGZUG_MENU_CARD: 4,
    },

    TYPES_ANP: {
        ANP_MENU_DEPARTMENTS: 5,
        ANP_MENU_MAIN: 6,
        ANP_MENU_FOOTER: 7,
        ANP_MENU_PARK_SIDE: 8,
        ANP_MENU_TOURS_SIDE: 9,
    },

    DEPARTMENTS_MENU_ANP: {
        AGGTELEK: 1,
        BODROGZUG: 2,
        DIRECTORATE: 3,
    },

    MAIN_MENU_ANP: {
        TOUR: 1,
        LOCATION: 2,
        PARK: 3,
        EDUCATION: 4,
    },

    VISIBILITY: {
        PUBLIC: 1,
        LOGGED_OUT: 2,
        LOGGED_IN: 3,
        ADMIN: 4,
    },
};
