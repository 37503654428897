import moment from 'moment';
import fetch from 'isomorphic-fetch'
import {SITE} from 'shared/src/constants/site';

export const SERVER_ERROR_INVALID_CREDENTIALS = 1;
export const SERVER_ERROR_SERVICE_UNAVAILABLE = 2;

export async function sendServerRequest(method, path, body) {
    moment.fn.toJSON = function() { return this.format(); };

    let siteId = SITE.BODROGZUG;
    if (process && process.env && process.env.SITE_ID) {
        siteId = process.env.SITE_ID;
    } else {
        try {
            siteId = localStorage.getItem('siteId');
        } catch (error) {
        }
    }

    let language = 'hu';
    if (process && process.env && process.env.LANGUAGE) {
        language = process.env.LANGUAGE;
    } else {
        try {
            language = localStorage.getItem('i18nextLng');
        } catch (error) {
        }
    }

    let token = null;
    try {
        token = localStorage.getItem('anpi-jwt');
    } catch (error) {
    }

    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'site-id': siteId,
            'language': language,
            ...token ? {'Authorization': `Bearer ${token}`}: {},
        },
        body: JSON.stringify(body),
    };

    let response = null;

    try {
        if (process && process.env && process.env.SERVER_URL) {
            response = await fetch(`${process.env.SERVER_URL}${path}`, options);
        } else {
            response = await fetch(`${window.__SERVER_URL__ ? window.__SERVER_URL__ : ''}${path}`, options);
        }
    } catch (error) {
        handleServiceError(error);
    }

    if (response.status === 403 || response.status === 401) {
        handleCredentialsError();
    }

    if (response.status < 200 || response.status >= 400) {
        handleServiceError();
    }

    return response.json();
}

function handleServiceError(errorObject) {
    console.log('Client error occurred', errorObject);
    const error = new Error('Error.ServiceUnavailable');
    Object.assign(error, {
        code: SERVER_ERROR_SERVICE_UNAVAILABLE
    });
    throw error;
}

function handleCredentialsError() {
    const error = new Error('Error.InvalidCredentials');
    Object.assign(error, {
        code: SERVER_ERROR_INVALID_CREDENTIALS
    });
    throw error;
}
