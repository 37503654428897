import createTheme from '@material-ui/core/styles/createTheme';

export const screens = {
    mobile: 'xs',
    tablet: 'md',
    desktop: 'lg',
};

export const themeBase = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 768,
            lg: 1200,
        }
    },
    typography: {
        body2: {
            lineHeight: '21px',
            letterSpacing: 'normal',
            fontWeight: 'normal',
        },
        useNextVariants: true,
    },
    palette: {
        text: {
            primary: 'rgba(0,0,0,1)',
            primaryInverse: 'rgba(255,255,255,1)',
            secondary: 'rgba(155,155,155,1)',
            secondaryInverse: 'rgba(155,155,155,1)',
        },
        background: {
            default: 'rgba(249,249,249,1)',
        }
    },
    screens,
    main: {
        width: '1200px',
        backgroundColor: 'rgba(239,239,239,1)',
        cardListPadding: '10px',
    },
    header: {
        backgroundColor: 'rgba(0,0,0,1)',
        hamburgerButtonColor: 'rgba(255,159,0,1)',
        hamburgerButtonColorHover: '#DD8A00',
        size: {
            [screens.mobile]: '50px',
            [screens.tablet]: '76px',
            [screens.desktop]: '70px',
        },
        padding: {
            [screens.mobile]: '8px',
            [screens.tablet]: '18px',
            [screens.desktop]: '12px'
        },
    },
    secondaryHeader: {
        backgroundColor: 'rgba(255,159,0,1)',
        size: {
            [screens.desktop]: '60px',
        },
        padding: {
            [screens.desktop]: '15px'
        },
    },
    checkoutDialog: {
        columnWidth: {
            [screens.desktop]: '380px',
            [screens.tablet]: '471px',
            [screens.mobile]: '100%',
        },
    },
    overrides: {
        MuiInputAdornment: {
            positionEnd: {
                paddingRight: '0px',
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '12px 13px'
            }
        },
        MuiInputBase: {
            input: {
                fontSize: '13px',
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: '11px',
                marginBottom: '30px',
            },
            root: {
                marginTop: '11px',
                marginBottom: '30px',
            }
        }
    }
};

const theme = createTheme(themeBase);
export default theme;