export const ROUTES = {
    HOME: '/',
    LOGIN: '/bejelentkezes',
    PASSWORD_RESET: '/jelszo-visszaallitas',
    NEW_PASSWORD_RESET: (token) => `/uj-jelszo/${token}`,
    ACTIVATE_ACCOUNT: (token) => `/?activationToken=${token}`,
    LOGOUT: '/kilepes',
    RULES: '/szabalyok',
    NEWS: '/hirek',
    MAPS: '/terkep',
    WATER_TOUR: '/vizitura',
    ARTICLE: (id) => `/cikk/${id}`,
    CHECKOUT: '/jegyvasarlas',
    SHOPPING_CART: '/foglalasok',
    PAYMENT_ACKNOWLEDGEMENT: '/backref',
    PAYMENT_TIMEOUT: '/timeout',
    PRIVACY_POLICY: '/adatvedelmi-nyilatkozat',
    COOKIE_POLICY: '/cookie-szabalyzat',
};

export const ROUTES_ANP = {
    HOME: '/',
    TOURS: '/turak-es-programok',
    TOUR: (id) => `/tura/${id}`,
    LOCATIONS: '/kiemelt-helyszinek',
    PARK: '/a-parkrol',
    NATURE_VALUE: '/termeszeti-ertekek',
    WORLD_HERITAGE: '/vilagorokseg',
    EDUCATION: '/kornyezeti-neveles',
    NEWS: '/hirek',
    FAQ_TOP: '/gyakran-ismetelt-kerdesek',
    FAQ: (id) => `/gyakran-ismetelt-kerdesek/${id}`,
    CAVE_TOURS: '/barlangi-turak',
    ADVANCED_CAVE_TOURS: '/rutint-igenylo-barlangi-turak',
    WALKING_TOURS: '/gyalog-turak',
    CYCLING_TOURS: '/kerekparos-turak',
    HORSE_TOURS: '/hucul-lovas-turak',
    OTHER_PROGRAMMES: '/sajat-szervezesu-programok',
    PROGRAMMES: '/programok',
    PAYMENT: '/fizetes',
    BARADLA: '/baradla-barlang',
    HUCUL: '/hucul-menes',
    BODROGZUG: 'https://bodrogzug.hu',
    PAYMENT_ACKNOWLEDGEMENT: '/backref',
    PAYMENT_TIMEOUT: '/timeout',
    MAPS: '/terkep',
    TOUR_PACKAGES: '/csomag-ajanlatok',
    SEARCH: '/kereses',
    ACCOMMODATIONS: '/szallasok-es-ettermek',
    ACCOMMODATION: (id) => `/szallas-etterem/${id}`,
    TOUR_GUIDES: '/turavezetoink',
    TOUR_GUIDE: (id) => `/turavezeto/${id}`,
    HANDICAP_INFORMATION: '/akadalymentesseg',
    CONTACT_INFORMATION: '/elerhetosegek',
    SAFETY: '/biztonsag',
    NEWS_ARTICLE: (id) => `/hir/${id}`,
    NATURE_TRAILS: '/tanosvenyek',
    CLASS_TRIPS: '/osztalykirandulasok',
    FOREST_CAMPS: '/erdei-taborok',
    CONTENT_ARTICLE: (id) => `/cikk/${id}`,
    PRIVACY_POLICY: '/adatvedelmi-nyilatkozat',
    COOKIE_POLICY: '/cookie-szabalyzat',
    HANDICAP_POLICY: '/akadalymentesitesi-nyilatkozat',
};

