import React from 'react';
import 'moment/locale/hu';
import OrderDetailsCard from '../cards/checkout/OrderDetailsCard';
import OrderAmountCard from '../cards/checkout/OrderAmountCard';
import OrderSummaryCard from '../cards/checkout/OrderSummaryCard';
import {CHECKOUT, ROUTES} from 'shared';
import {Formik} from 'formik';
import {userService} from 'client_shared/src/services/userService';
import ScrollToError from 'client_shared/src/components/errors/ScrollToError';
import {useTranslation} from 'react-i18next';
import {orderService} from 'client_shared/src/services';
import {useDispatch, useSelector} from 'react-redux';
import {orderActions} from 'client_shared/src/state/actions/orderActions';
import {orderModule} from 'shared/src/modules/order';
import {taxModule} from 'shared/src/modules/tax';
import {withRouter} from 'react-router';
import {COUNTRY} from 'shared/src/constants/translation';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

function OrderForm(props) {
    const order = useSelector(state => state.order.order);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const createOrderItemError = (errors, index, field, message) => {
        if (!errors.orderItems) {
            errors.orderItems = [];
        }
        if (!errors.orderItems[index]) {
            errors.orderItems[index] = {};
        }
        errors.orderItems[index][field] = message;
    };

    const validate = async (values) => {
        let errors = {};
        const requiredFields = [
            'fromDate',
            'toDate',
            'guideName',
            'guideEmail',
            'guidePhone',
            'guidePostCode',
            'guideCity',
            'guideAddress',
            'rulesApproved',
        ];
        requiredFields.forEach(field => {
            if (!values[field] && values[field] !== 0) {
                errors[field] = t('Form.Mandatory');
            }
        });

        if (values['invoiceRequested']) {
            [
                'billingName',
                'billingVATNumber',
                'billingCountry',
                'billingPostCode',
                'billingCity',
                'billingAddress',
            ]
                .forEach(field => {
                    if (!values[field]) {
                        errors[field] = t('Form.Mandatory');
                    }
                });

/*
            if (values.billingCountry === COUNTRY.HUNGARY &&
                !taxModule.validateCompanyVATNumber(values.billingVATNumber) &&
                !taxModule.validatePersonalVATNumber(values.billingVATNumber)) {
                errors.billingVATNumber = t('Form.InvalidVATNumber');
            }
*/
        }

        if (
            values.guideEmail &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.guideEmail)
        ) {
            errors.guideEmail = t('Form.InvalidEmail');
        }

        if (
            values.guidePhone &&
            !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/gi.test(values.guidePhone)
        ) {
            errors.guidePhone = t('Form.InvalidPhone');
        }

        if (!values.rulesApproved) {
            errors.rulesApproved = t('Form.RuleApprovalMandatory');
        }

        values.orderItems.forEach((orderItem, index) => {
            if (!orderItem.amount && orderItem.amount !== 0) {
                createOrderItemError(errors, index, 'amount', t('Form.Mandatory'));
            }

            if (orderItem.amount < 0) {
                createOrderItemError(errors, index, 'amount', t('Form.InvalidAmount'));
            }
        });

        const adultAmount = orderModule.getOrderAmount(values, true);

        if (adultAmount < 1) {
            values.orderItems.forEach((orderItem, index) => {
                if (orderItem.product.adult) {
                    createOrderItemError(errors, index, 'amount', t('Form.InvalidAmount'));
                }
            });
        }

        const orderAmount = orderModule.getOrderAmount(values);
        const limit = Math.min(order.amountLimit, order.tour.maxOrderAmount);

        if (orderAmount > limit) {
            values.orderItems.forEach((orderItem, index) => {
                createOrderItemError(errors, index, 'amount', `${t('Form.MaxAmount')} ${limit} ${t('Checkout.Person')}`);
            });
        }

        if (values.registerAccount && (!values.accountPassword || values.accountPassword === '')) {
            errors.accountPassword = t('Form.PasswordMandatory');
        }

        if (values.registerAccount && (!values.accountEmail || values.accountEmail === '')) {
            errors.accountEmail = t('Form.EmailMandatory');
        }

        if (values.registerAccount && values.accountEmail &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.accountEmail)) {
            errors.accountEmail = t('Form.InvalidEmail');
        }

        return errors;
    };

    const onOrderDetailsCompleted = async (orderValues) => {
        orderModule.recalculateOrder(orderValues);

        if (orderValues.paymentType === CHECKOUT.PAYMENT_OPTIONS.NONE) {
            await orderService.createOrder(orderValues);
            dispatch(orderActions.setOrder(null));
            dispatch(orderActions.setOrderStep(null));
            props.history.push(getPathWithLanguage(ROUTES.HOME, i18n.language));
            return;
        }

        dispatch(orderActions.setOrder(orderValues));
        dispatch(orderActions.setOrderStep(CHECKOUT.STEPS.PAYMENT_OPTIONS));
        window.scrollTo(0, 0);
    };

    const onSubmit = async (values, { setSubmitting, setStatus }) => {
        if (values.registerAccount) {
            setStatus(undefined);
            const response = await userService.validateRegistrationInfo(values.accountEmail);
            if (!response.result) {
                setStatus({ accountEmail: t('Form.EmailAlreadyUsed') });
                setSubmitting(false);
                return;
            }
        }

        await onOrderDetailsCompleted(values);

        setSubmitting(false);
    };

    if (!order) {
        return null;
    }

    return (
        <Formik
            initialValues={order}
            validate={validate}
            onSubmit={onSubmit}
        >
            {(props) => (
                <form onSubmit={props.handleSubmit}>
                    <OrderDetailsCard {...props} />
                    <OrderAmountCard {...props}/>
                    <OrderSummaryCard {...props}/>
                    <ScrollToError {...props} />
                </form>
            )}
        </Formik>
    )
}

export default withRouter(OrderForm);