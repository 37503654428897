import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
    colors: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.16)',
    },
});

class CardBase extends React.Component {
    render() {
        const { classes, className } = this.props;
        return (
            <div className={clsx(classes.colors, className)}>
                {this.props.children}
            </div>
        )
    }
}

CardBase.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

CardBase.defaultProps = {
    className: '',
};

export default withStyles(styles)(CardBase);