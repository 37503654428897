import React, {useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {makeStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {green} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

export function MessageBar(props) {
    const { message, onClose, hasCloseIcon = true } = props;
    const classes = useStyles();

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose && onClose();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            autoHideDuration={hasCloseIcon ? null : 6000}
            className={classes.margin}
            action={hasCloseIcon ? [
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>
            ] : null}
        >
            <SnackbarContent
                className={clsx(classes.success)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={clsx(classes.message, classes.success)}>
                        <CheckCircleIcon className={clsx(classes.icon, classes.iconVariant)}/>
                        {message}
                    </span>
                }
                action={hasCloseIcon ? [
                    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                        <CloseIcon className={classes.icon}/>
                    </IconButton>,
                ] : null}
            />
        </Snackbar>
    );
}