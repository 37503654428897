import React from 'react';
import MainMapScreen from '../components/screens/MainMapScreen';
import MyMap from 'client_shared/src/components/maps/MyMap';
import _ from 'lodash';
import {mapActions} from 'client_shared/src/state/actions';

function Maps() {
    return (
        <MainMapScreen>
            <MyMap/>
        </MainMapScreen>
    );
}

Maps.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.map.layers)) {
        promises.push(dispatch(mapActions.getLayers()));
    }

    return promises;
};

export default Maps;