import {sendServerRequest} from './baseService';
import {formatDate, formatTime} from 'shared/src/utils/dateUtils';

export const tourService = {
    getTours,
    getTour,
    getTourAmount,
    getTourAmountForTourTime
};

async function getTours(props) {
    const params = [];
    Object.keys(props).forEach(key => {
        props[key] && params.push(`${key}=${props[key]}`);
    });
    return sendServerRequest('GET', `/api/tours?${params.join('&')}`);
}

async function getTour(tourId) {
    return sendServerRequest('GET', `/api/tours/${tourId}`);
}

async function getTourAmount(tourId, fromDate, toDate) {
    return sendServerRequest('GET', `/api/tours/${tourId}?amountOnly=true&fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}`);
}

async function getTourAmountForTourTime(tourId, date, time, tourDateId, tourTimeId) {
    return sendServerRequest('GET', `/api/tours/${tourId}?amountOnly=true&date=${formatDate(date)}&time=${formatTime(time)}&tourDateId=${tourDateId}&tourTimeId=${tourTimeId}`);
}
