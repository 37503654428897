import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import {useDispatch, useSelector} from 'react-redux';
import {mapActions} from '../../state/actions/mapActions';
import SearchField from './SearchField';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    listWrapper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        border: '0px',
        padding: '0px',
        borderRadius: '2px',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
        width: '300px',
        minHeight: 0,
    },
    open: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('lg')]: {
            width: 'inherited',
            height: 'inherited',
        },
    },
    closed: {
        width: '100%',
        height: '50px',
        [theme.breakpoints.up('lg')]: {
            width: 'inherited',
            height: 'inherited',
        },
    },
    filters: {
        height: '50px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        cursor: 'pointer',
    },
    listItemsWrapper: {
        flex: 1,
        overflow: 'auto',
        minHeight: 0,
    },
    list: {
        '& > *:not(:last-child)': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        }
    },
    listHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '48px',
    },
    listItem: {
        padding: 0,
    },
    listItemText: {
        marginLeft: '5px',
    },
    icon: {
        width: '19px',
        height: '22px',
    },
    checkBox: {
        color: 'rgba(255,159,0,1)',
    },
    layerGroupTitle: {
        marginLeft: '12px',
        '&.paragraph3': {
            fontWeight: 'bold',
        }
    },
    polygonLegend: {
        margin: '3px',
        border: '1px solid #000000',
        width: '12px',
        minWidth: '12px',
        height: '12px',
        minHeight: '12px',
    },
    listItemGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    listItemGroupRight: {
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    },
    interregLogo: {
        height: '20px',
        [theme.breakpoints.up('sm')]: {
            height: '18px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '28px',
        }
    },
    interregTextBlock: {
        marginTop: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    interregText: {
        '&.paragraph4': {
            fontSize: '8px',
            textAlign: 'center',
            lineHeight: '10px'
        },
        '& a': {
            color: 'white',
            textDecoration: 'none',
        },
    },
    interreg: {
        textDecoration: 'none',
    },
    interregBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
    }
}));

export default function LayerSwitcher(props) {
    const classes = useStyles(props);
    const layers = useSelector(state => state.map.layers);
    const dispatch = useDispatch();
    const [layerGroups, setLayerGroups] = useState([]);
    const [initialised, setInitialised] = useState(false);
    const isMobileOrTablet = !useMediaQuery(theme => theme.breakpoints.up('lg'));
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    function setLayerGroupsCheckedState(layersByGroups) {
        return layersByGroups.map(layerGroup => {
            layerGroup.allLayerChecked = !layerGroup.layers.some(layer => !layer.visible);
            return { ...layerGroup };
        });
    }

    const toggleVisibility = (layer) => () => {
        layer.visible = !layer.visible;
        const newLayerGroups = setLayerGroupsCheckedState(layerGroups);
        setLayerGroups(newLayerGroups);
        dispatch(mapActions.updateLayer(layer));
    };

    const toggleExpanded = (layerGroup) => () => {
        layerGroup.expanded = !layerGroup.expanded;
        setLayerGroups(layerGroups.map(layerGroup => ({ ...layerGroup })));
    };

    const toggleChecked = (layerGroups, layerGroup) => () => {
        layerGroup.layers.forEach(layer => layer.visible = !layerGroup.allLayerChecked);
        const newLayerGroups = setLayerGroupsCheckedState(layerGroups);
        setLayerGroups(newLayerGroups);
        layerGroup.layers.forEach(layer => dispatch(mapActions.updateLayer(layer)));
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (!layers || layers.length === 0 || initialised) {
            return;
        }

        const layersByGroups = layers.reduce((accumulator, layer) => {
            let layerGroup = accumulator.find(layerGroup => layerGroup.id === layer.mapLayerGroupId);
            if (!layerGroup) {
                layerGroup = { ...layer.mapLayerGroup };
                accumulator.push(layerGroup);
                layerGroup.layers = [];
            }
            layerGroup.layers.push(layer);
            return accumulator;
        }, []);

        setLayerGroupsCheckedState(layersByGroups);

        setLayerGroups(layersByGroups);
        setInitialised(true);
    }, [layers, initialised]);

    return (
        <div className={clsx(isMobileOrTablet && (open ? classes.open : classes.closed), classes.listWrapper)}>
            {isMobileOrTablet &&
            <div className={classes.filters} onClick={toggleOpen}>
                {open ? t('Text.CloseFilters') : t('Text.Filters')}
            </div>
            }
            {(!isMobileOrTablet || open) &&
            <>
                <SearchField className={classes.search}/>
                <div className={classes.interregBlock}>
                    <a className={classes.interreg} href="http://www.skhu.eu" target="_blank" rel="noopener noreferrer">
                        <img alt="Interreg" src={`/content/images/interreg/${t('Text.InterregLogo')}`} height="30"/>
                    </a>
                    <div className={classes.interregTextBlock}>
                        <div className={clsx('paragraph4', classes.interregText)} dangerouslySetInnerHTML={createMarkup(t('Text.InterregTitle'))} />
                        <div className={clsx('paragraph4', classes.interregText)} dangerouslySetInnerHTML={createMarkup(t('Text.InterregDisclaimer'))} />
                    </div>
                </div>
                <div className={classes.listItemsWrapper}>
                    <List className={classes.list}>
                        {layerGroups.map(layerGroup => (
                            <div key={layerGroup.id}>
                                <ListItem key={layerGroup.id} className={clsx(classes.listItem, classes.listItemGroup)}>
                                    <div className={clsx('paragraph3', classes.layerGroupTitle)}>
                                        {layerGroup.title}
                                    </div>
                                    <IconButton onClick={toggleExpanded(layerGroup)}>
                                        {layerGroup.expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                    </IconButton>
                                </ListItem>
                                {layerGroup.expanded && layerGroup.selectAllEnabled &&
                                <ListItem className={classes.listItem}>
                                    <Checkbox
                                        checked={layerGroup.allLayerChecked}
                                        onChange={toggleChecked(layerGroups, layerGroup)}
                                        classes={{ root: classes.checkBox, checked: classes.checked }}
                                    />
                                    <div className={classes.polygonLegend}
                                         style={{ backgroundColor: 'white' }}/>

                                    <div className={clsx('paragraph3', classes.listItemText)}>
                                        {t('Text.All')}
                                    </div>
                                </ListItem>
                                }
                                {layerGroup.expanded && layerGroup.layers.map(layer =>
                                    <ListItem key={`${layerGroup.id}-${layer.id}`} className={classes.listItem}>
                                        <Checkbox
                                            checked={layer.visible}
                                            onChange={toggleVisibility(layer)}
                                            classes={{ root: classes.checkBox, checked: classes.checked }}
                                        />
                                        {layer.icon &&
                                        <img src={`/images/markers/${layer.icon}`} alt='marker'
                                             className={classes.icon}/>
                                        }
                                        {layer.color &&
                                        <div className={classes.polygonLegend}
                                             style={{ backgroundColor: layer.color }}/>
                                        }
                                        <div className={clsx('paragraph3', classes.listItemText)}>
                                            {layer.title}
                                        </div>
                                    </ListItem>
                                )}
                            </div>
                        ))}
                    </List>
                </div>
            </>
            }
        </div>
    );
}
