import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import 'moment/locale/hu';
import {connect, useSelector} from 'react-redux';
import numeral from 'numeral';
import {screens} from '../../../styles/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import {withTranslation} from 'react-i18next';
import {orderModule} from 'shared/src/modules/order';

const styles = theme => ({
    card: {
        [theme.breakpoints.up(screens.desktop)]: {
            padding: '30px 00px 30px 0px'
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            margin: '30px 0px 54px 0px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '30px 30px 54px 30px'
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    labelRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    text: {
        color: 'rgba(155,155,155,1)',
        fontSize: '14px',
        lineHeight: '16px',
    },
    amount: {
        fontSize: '30px',
        fontWeight: 900,
        lineHeight: '35px',
    },
    checkBoxLabel: {
        fontSize: '13px',
    },
    checkBoxRoot: {
        alignItems: 'flex-start',
    },
    checkBox: {
        alignItems: 'flex-start',
        color: 'rgba(255,159,0,1)',
        '&$checked': {
            color: 'rgba(255,159,0,1)',
        },
    },
    checkBoxLong: {
        paddingTop: '0px',
    },
    checked: {},
    submitButton: {
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
    },
    hidden: {
        display: 'none',
    },
    description: {
        '& > a': {
            color: 'rgba(0,30,255,1)',
        }
    }
});

const createMarkup = (text) => {
    return { __html: text };
};

function OrderSummaryCard(props) {
    const { classes, className, user, values, handleChange, handleBlur, touched, errors, status, t } = props;
    const order = useSelector(state => state.order.order);

    const onRulesApprovedChange = (event) => {
        props.setFieldValue('rulesApproved', event.target.checked);
    };

    const onNewsletterChange = (event) => {
        props.setFieldValue('newsletter', event.target.checked);
    };

    const onRegisterAccountChange = (event) => {
        const registerAccount = event.target.checked;
        props.setFieldValue('registerAccount', registerAccount);
        if (registerAccount) {
            props.setFieldValue('accountEmail', props.values.guideEmail);
        }
    };

    if (!order) {
        return null;
    }

    const orderAmount = orderModule.getOrderAmount(order);
    const limit = Math.min(order.amountLimit, order.tour.maxOrderAmount);
    const amountTooMuch = orderAmount > limit;

    return (
        <div className={clsx(className, classes.card, classes.container)}>
            <div className={classes.text}>
                {t('Checkout.Comment')}
            </div>
            <TextField
                name="comment"
                multiline={true}
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={!!(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        name="rulesApproved"
                        checked={values.rulesApproved}
                        onChange={onRulesApprovedChange}
                        classes={{
                            root: clsx(classes.checkBox, classes.checkBoxLong),
                            checked: classes.checked
                        }}
                    />
                }
                classes={{ root: classes.checkBoxRoot, label: clsx(classes.checkBoxLabel) }}
                label={
                    <span className={classes.description}
                          dangerouslySetInnerHTML={createMarkup(t('Checkout.Approve'))}/>
                }
            />
            <FormHelperText
                error={!!(touched.rulesApproved && errors.rulesApproved)}>
                {touched.rulesApproved && errors.rulesApproved}
            </FormHelperText>
            {!user &&
            <FormControlLabel
                control={
                    <Checkbox
                        name="registerAccount"
                        checked={values.registerAccount}
                        onChange={onRegisterAccountChange}
                        classes={{
                            root: clsx(classes.checkBox),
                            checked: classes.checked
                        }}
                    />
                }
                classes={{ label: clsx(classes.checkBoxLabel) }}
                label={t('Checkout.Register')}
            />
            }
            {values.registerAccount &&
            <>
                <div className={classes.text}>
                    {t('Checkout.RegisterEmail')}
                </div>
                <TextField
                    name="accountEmail"
                    value={values.accountEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    error={!!((touched.accountEmail && errors.accountEmail) || (touched.accountEmail && status && status.accountEmail))}
                    helperText={(touched.accountEmail && errors.accountEmail) || (touched.accountEmail && status && status.accountEmail)}
                />
                <div className={classes.text}>
                    {t('Checkout.RegisterPassword')}
                </div>
                <TextField
                    name="accountPassword"
                    type={"password"}
                    value={values.accountPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    error={!!(touched.accountPassword && errors.accountPassword)}
                    helperText={touched.accountPassword && errors.accountPassword}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="newsletter"
                            checked={values.newsletter}
                            onChange={onNewsletterChange}
                            classes={{
                                root: clsx(classes.checkBox),
                                checked: classes.checked
                            }}
                        />
                    }
                    classes={{ label: clsx(classes.checkBoxLabel) }}
                    label={t('Checkout.Newsletter')}
                />
            </>
            }
            <div className={clsx(classes.labelRow, amountTooMuch ? classes.hidden : null)}>
                <div className={classes.text}>
                    {t('Checkout.ToBePaid')}
                </div>
                <div className={classes.amount}>
                    {`${numeral(order.price).format('0,0').replace(/,/g, ' ')} Ft`}
                </div>
            </div>
            <Button className={classes.submitButton} type={"submit"} disabled={amountTooMuch}>
                {order.price > 0 ? t('Checkout.Payment') : t('Checkout.Order')}
            </Button>
        </div>
    );
}

function mapStateToProps(state) {
    const { order } = state.order;
    const { user } = state.user;
    return {
        order,
        user,
    };
}

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(OrderSummaryCard)));