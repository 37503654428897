import {useCallback, useContext, useEffect, useState} from 'react';
import {toWgs84} from 'reproject';
import epsg from 'epsg';
import {GoogleMapContext} from '@googlemap-react/core';
import {mapService} from '../../services';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {mapActions, showFeatureInfo, updateLayer, zoomToFeatureDone} from '../../state/actions/mapActions';
import {MAP_FEATURES} from 'shared/src/constants/mapFeatures';

function getFillColor(mapFeature, layer) {
    switch (mapFeature.accessibility) {
        case MAP_FEATURES.ACCESSIBILITY.NORMAL:
            return (mapFeature.color || layer.color || '#000000');
        case MAP_FEATURES.ACCESSIBILITY.INACCESSIBLE:
            return '#FF0000';
        case MAP_FEATURES.ACCESSIBILITY.CLOSED:
            return '#FFA500';
        case MAP_FEATURES.ACCESSIBILITY.DIFFICULT:
            return '#FFFF00';
        case MAP_FEATURES.ACCESSIBILITY.EASY:
            return '#00FF00';
        default:
                throw new Error('Unknown accessibility');
    }
}

function getStrokeColor(mapFeature) {
    switch (mapFeature.accessibility) {
        case MAP_FEATURES.ACCESSIBILITY.NORMAL:
            return (mapFeature.color || '#000000');
        case MAP_FEATURES.ACCESSIBILITY.INACCESSIBLE:
            return '#E64545';
        case MAP_FEATURES.ACCESSIBILITY.CLOSED:
            return '#FFAA00';
        case MAP_FEATURES.ACCESSIBILITY.DIFFICULT:
            return '#E6D200';
        case MAP_FEATURES.ACCESSIBILITY.EASY:
            return '#00B377';
        default:
                throw new Error('Unknown accessibility');
    }
}

export default function POILayer(props) {
    const { layer } = props;
    const { i18n } = useTranslation();
    const { state } = useContext(GoogleMapContext);
    const [dataLayer, setDataLayer] = useState(null);
    const [mapFeatures, setMapFeatures] = useState([]);
    const dispatch = useDispatch();
    const zoomingFeature = useSelector(state => state.map.zoomingFeature);

    const loadFeatures = useCallback(async () => {
        const features = await mapService.getMapFeatures(layer.layerId);
        layer.features = features;
        dispatch(mapActions.updateLayer(layer));
    }, [dispatch, i18n, layer]);

    useEffect(() => {
        if (state.map && !dataLayer) {
            const lineSymbol = {
                // eslint-disable-next-line no-undef
                path: google.maps.SymbolPath.CIRCLE,
                strokeColor: '#E64545',
                fillColor: '#E64545',
                strokeOpacity: 1,
                fillOpacity: 1,
                scale: 3
            };

            // eslint-disable-next-line no-undef
            const dataLayer = new google.maps.Data();

            dataLayer.setStyle((feature) => {
                const mapFeature = feature.getProperty('feature');
                return {
                    icons: [{
                        icon: lineSymbol,
                        offset: '0',
                    }],
                    fillColor: getFillColor(mapFeature, layer),
                    strokeColor: getStrokeColor(mapFeature),
                    strokeWeight: mapFeature.color ? 5 : 1,
                    fillOpacity: 0.4,
                    icon: mapFeature.closed ? `/images/markers/lock-2.png` : `/images/markers/${layer.icon}`,
                };
            });

            dataLayer.addListener('click', function (event) {
                dispatch(mapActions.showFeatureInfo(event.feature, event.latLng));
            });

            setDataLayer(dataLayer);
        }
    }, [dispatch, dataLayer, state.map, layer]);

    useEffect(() => {
        if (!dataLayer || !layer) {
            return;
        }

        if (!layer.visible) {
            dataLayer.setMap(null);
            return;
        }

        if (!layer.features) {
            loadFeatures().catch(error => console.log(error));
            return;
        }

        if (mapFeatures.length === 0 && layer.features.length !== 0) {
            const addedMapFeatures = [];
            layer.features.forEach(feature => {
                const geoJson = {
                    type: "Feature",
                    geometry: toWgs84(feature.geom, 'EPSG:23700', epsg),
                    properties: {
                        feature: feature,
                    }
                };
                const newMapFeatures = dataLayer.addGeoJson(geoJson);
                addedMapFeatures.push(...newMapFeatures);
            });
            setMapFeatures(addedMapFeatures);
        }

        if (!dataLayer.getMap()) {
            dataLayer.setMap(state.map);
        }
    }, [i18n, loadFeatures, dataLayer, layer, state.map, mapFeatures]);

    useEffect(() => {
        function zoomToFeature(feature) {
            const mapFeature = mapFeatures.find(mapFeature => mapFeature.getProperty('feature').id === feature.id);
            // eslint-disable-next-line no-undef
            const bounds = new google.maps.LatLngBounds();

            mapFeature.getGeometry().forEachLatLng(latlng => {
                bounds.extend(latlng);
            });

            state.map.fitBounds(bounds);

            dispatch(mapActions.zoomToFeatureDone());
        }

        if (layer && zoomingFeature) {
            if (zoomingFeature.layerId === layer.layerId) {
                if (!layer.features) {
                    loadFeatures().catch(error => console.log(error));
                    return;
                }

                if (!layer.visible) {
                    layer.visible = true;
                    dispatch(mapActions.updateLayer(layer));
                    return;
                }

                if (mapFeatures.length > 0) {
                    zoomToFeature(zoomingFeature);
                }
            }
        }
    }, [layer, zoomingFeature, dataLayer, loadFeatures, state.map, mapFeatures, dispatch]);

    return null;
}