import React from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        borderRadius: '39px',
        minWidth: '78px',
        maxWidth: '78px',
        minHeight: '78px',
        maxHeight: '78px',
        position: 'relative',
    },
    icon: {
        color: theme.palette.text.primaryInverse,
        minWidth: '44px',
        maxWidth: '44px',
        minHeight: '44px',
        maxHeight: '44px',
        position: 'absolute',
        top: '17px',
        left: '17px'
    }
});

class DoneIcon extends React.Component {
    render() {
        const { classes, className } = this.props;
        const child = React.Children.toArray(this.props.children)[0];
        const iconWithExtraProp = React.cloneElement(child, {
                className: classes.icon,
        });
        return (
            <div className={clsx(classes.root, className)}>
                {iconWithExtraProp}
            </div>
        );
    }
}

DoneIcon.propTypes = {};

export default withStyles(styles)(DoneIcon);