import {orderActions} from '../actions/orderActions';

const initialState = {
    order: null,
    step: null,
};

export function order(state = initialState, action) {
    switch (action.type) {
        case orderActions.SET_ORDER:
            return {
                ...state,
                order: action.payload.order,
            };
        case orderActions.SET_ORDER_STEP:
            return {
                ...state,
                step: action.payload.step,
            };
        default:
            return state
    }
}
