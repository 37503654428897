import {combineReducers} from 'redux';

import {content} from './contentReducer';
import {badge} from './badgeReducer';
import {checkout} from './checkoutReducer';
import {error} from './errorReducer';
import {login} from './loginReducer';
import {map} from './mapReducer';
import {menu} from './menuReducer';
import {order} from './orderReducer';
import {setting} from './settingReducer';
import {tour} from './tourReducer';
import {tourGuide} from './tourGuideReducer';
import {user} from './userReducer';
import {page} from './pageReducer';
import {faq} from './faqReducer';
import {accommodation} from './accommodationReducer';
import {search} from './searchReducer';

export const rootStoreActions = {
    RESET_STORE: 'STORE_RESET_STORE',
};

const appReducer = combineReducers({
    login,
    user,
    content,
    menu,
    setting,
    tour,
    tourGuide,
    error,
    order,
    map,
    badge,
    checkout,
    page,
    faq,
    accommodation,
    search
});

const rootReducer = (state, action) => {
    if (action.type === rootStoreActions.RESET_STORE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;