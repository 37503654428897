import {faqActions} from '../actions/faqActions';

const initialState = {
    faqCategories: [],
    faqCategory: null,
    faqChildCategory: null,
    faqs: [],
};

export function faq(state = initialState, action) {
    switch (action.type) {
        case faqActions.SET_FAQ_CATEGORIES:
            return {
                ...state,
                faqCategories: action.payload.faqCategories,
            };
        case faqActions.SET_FAQ_CATEGORY:
            return {
                ...state,
                faqCategory: action.payload.faqCategory,
            };
        case faqActions.SET_FAQ_CHILD_CATEGORY:
            return {
                ...state,
                faqChildCategory: action.payload.faqChildCategory,
            };
        case faqActions.SET_FAQS:
            return {
                ...state,
                faqs: action.payload.faqs,
            };
        default:
            return state
    }
}
