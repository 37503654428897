import {MENU} from '../constants/menu';

export function getActualMenu(menus, menuId, user) {
    if (!menus) {
        return [];
    }

    return menus.filter(menu => {
        const isInMenu = menu.menuId === menuId && !menu.parentMenuItemId;
        const visible = menu.visibility === MENU.VISIBILITY.PUBLIC || (menu.visibility === MENU.VISIBILITY.LOGGED_OUT && !user) || (menu.visibility === MENU.VISIBILITY.LOGGED_IN && user);
        return isInMenu && visible;
    });
}

export function getActualSubMenu(menus, parentMenuItemId, user) {
    if (!menus) {
        return [];
    }

    return menus.filter(menu => {
        const isInMenu = menu.parentMenuItemId === parentMenuItemId;
        const visible = menu.visibility === MENU.VISIBILITY.PUBLIC || (menu.visibility === MENU.VISIBILITY.LOGGED_OUT && !user) || (menu.visibility === MENU.VISIBILITY.LOGGED_IN && user);
        return isInMenu && visible;
    });
}

