import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

export function createStoreWithInitialState(preloadedState = null) {
    return preloadedState ? createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(
            thunkMiddleware,
        )
    ) : createStore(
        rootReducer,
        applyMiddleware(
            thunkMiddleware,
        )
    );
}