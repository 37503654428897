import * as React from "react";

function SvgDelete(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M62.4 1.6c2.1 2.1 2.1 5.5 0 7.5L39.5 32l22.9 22.9c2 2 2.1 5.3.2 7.4l-.2.2c-2.1 2.1-5.5 2.1-7.5 0L32 39.5 9.1 62.4c-2.1 2.1-5.5 2.1-7.5 0-2.1-2.1-2.1-5.5 0-7.5L24.5 32 1.6 9.1C-.5 7-.5 3.6 1.6 1.6s5.5-2.1 7.5 0L32 24.5 54.9 1.6c2.1-2.1 5.5-2.1 7.5 0z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgDelete;
