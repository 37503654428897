import React, {useEffect} from 'react';

export default function ScrollToError(props) {
    const { errors, isSubmitting, isValidating } = props;
    useEffect(() => {
        const keys = Object.keys(errors);
        if (keys.length > 0 && isSubmitting && !isValidating) {
            const field = document.querySelector(`[name="${keys[0]}"]`);
            if (field) {
                field.focus();
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
}
