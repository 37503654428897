import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

class CheckoutModule {
    addToOrder(order, tour, date = null, time = null, tourDateId = null, tourTimeId = null, overwrite = false) {
        _.unset(tour, 'body');
        _.unset(tour, 'body_en');
        _.unset(tour, 'body_hu');
        _.unset(tour, 'body_sk');

        if (!overwrite) {
            if (!order.orderItems.find(orderItem => orderItem.product.tour.id === tour.id)) {
                order.orderItems = [...order.orderItems, ...tour.products.map(product => (
                    {
                        product: {...product, tour},
                        productId: product.id,
                        amount: 0,
                        price: product.price,
                        date: this.getNextAvailableDate(tour),
                        time: this.getNextAvailableTime(tour, this.getNextAvailableDate(tour)).time,
                    }))];
            }
        } else {
            order.orderItems = [...tour.products.map(product => (
                {
                    product: {...product, tour},
                    productId: product.id,
                    amount: 0,
                    price: product.price,
                    date: date || this.getNextAvailableDate(tour),
                    time: time || this.getNextAvailableTime(tour, this.getNextAvailableDate(tour)).time,
                    tourDateId: tourDateId,
                    tourTimeId: tourTimeId
                }))];
        }
        return order;
    }

    removeFromOrder(order, tour) {
        order.orderItems = order.orderItems.filter(orderItem => orderItem.product.tour.id !== tour.id);

        return order;
    }

    recalculateOrder(order) {

    }

    getOrderTours(order) {
        return order.orderItems.reduce((accumulator, orderItem) => {
            if (!accumulator.find(tour => tour.id === orderItem.product.tour.id)) {
                accumulator.push(orderItem.product.tour);
            }
            return accumulator;
        }, []);
    }

    changeOrderItemDates(order, tour, date) {
        order.orderItems.forEach(orderItem => {
            if (!orderItem.product.tour.id === tour.id) {
                orderItem.date = date;
            }
        });
    }

    getOrderItemIndicesForTour(order, tour) {
        return order.orderItems
            .map((orderItem, index) => orderItem.product.tour.id === tour.id ? index : null)
            .filter(index => index !== null)
            .sort((orderItemIndex1, orderItemIndex2) => {
                if (order.orderItems[orderItemIndex1].product.index < order.orderItems[orderItemIndex2].product.index) {
                    return -1;
                }
                if (order.orderItems[orderItemIndex1].product.index > order.orderItems[orderItemIndex2].product.index) {
                    return 1;
                }
                return 0;
            });
    }

    getNextAvailableDate(tour) {
        if (this.getNextAvailableTime(tour)) {
            return moment();
        }
        return moment().add(1, 'day');
    }

    getNextAvailableTime(tour, date = moment()) {
        if (date.isAfter(moment())) {
            return tour.tourDates[0].tourTimes[0];
        }

        return tour.tourDates[0].tourTimes
            .sort((tourTime1, tourTime2) => {
                if (moment(tourTime1.time, 'HH:mm').isBefore(moment(tourTime2.time, 'HH:mm'))) {
                    return -1;
                }
                if (moment(tourTime1.time, 'HH:mm').isAfter(moment(tourTime2.time, 'HH:mm'))) {
                    return 1;
                }
                return 0;
            })
            .find(tourTime => {
            return moment(tourTime.time, 'HH:mm').isAfter(moment(date));
        });
    }

    getOrderItemMap(order, tours) {
        return tours.reduce((accumulator, tour) => {
            const orderItemIndices = checkoutModule.getOrderItemIndicesForTour(order, tour);
            accumulator[tour.id] = order.orderItems.filter((orderItem, index) => orderItemIndices.includes(index) && orderItem.amount > 0);
            return accumulator;
        }, {})
    }

    getOrderSum(order) {
        const tours = checkoutModule.getOrderTours(order);
        const orderItemMap = checkoutModule.getOrderItemMap(order, tours);

        return Object.values(orderItemMap).reduce((accumulator, orderItems) => {
            return accumulator + orderItems.reduce((sum, orderItem) => sum + orderItem.price, 0);
        }, 0)
    }

    formatMoney(amount) {
        return numeral(amount).format('0,0').replace(/,/g, ' ');
    }
}

export const checkoutModule = new CheckoutModule();
