export const SITE = {
    BODROGZUG: 1,
    ANP: 2,
    ADMIN: 3,
};

export const SITE_NAME = {
    BODROGZUG: 'bodrogzug',
    ANP: 'anp',
    ADMIN: 'admin',
};