import React, {useEffect, useState, useContext} from 'react';
import {makeStyles} from '@material-ui/core';
import {GoogleMapContext} from '@googlemap-react/core';

const useStyles = makeStyles(theme => ({
}));

export default function GeoLocation() {
    const classes = useStyles();
    const [marker, setMarker] = useState(null);
    const { state: { map } } = useContext(GoogleMapContext);

    useEffect(() => {
        if (!map) {
            return;
        }

        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({ map });
        setMarker(marker);
    }, [map]);

    const trackLocation = ({ onSuccess, onError = () => { } }) => {
        if ('geolocation' in navigator === false) {
            return onError(new Error('Geolocation is not supported by your browser.'));
        }

        return navigator.geolocation.watchPosition(onSuccess, onError, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    };

    useEffect(() => {
        if (!map || !marker) {
            return;
        }

        trackLocation({
            onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
                marker.setPosition({ lat, lng });
                //map.panTo({ lat, lng });
            },
            onError: error => console.log(error)
        });
    }, [map, marker]);

    return null;
}
