import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import CheckoutButton from '../buttons/CheckoutButton';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared/src/constants/menu';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    list: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: 0,
        padding: 0,
    },
    item: {
        display: "block",
        flex: "0 1 auto",
        listStyleType: "none",
        '&:not(:first-child)': {
            paddingLeft: "10px",
        },
    },

    link: {
        color: theme.palette.text.primaryInverse,
        textDecoration: "none",
        fontSize: "18px",
        fontWeight: "inherit",
    },

    button: {
        color: theme.palette.text.primaryInverse,
        textTransform: "none",
        fontSize: "18px",
        fontWeight: "inherit",
        margin: 0,
        border: 0,
        padding: 0,
    },

    checkoutButton: {
        marginLeft: '77px',
    }
});

class MainMenu extends React.Component {
    render() {
        const { classes, user, menus, t, i18n } = this.props;

        const menu = getActualMenu(menus, MENU.TYPES.BODROGZUG_MENU_MAIN, user);

        return (
            <div className={classes.root}>
                <ul className={classes.list}>
                    {menu.map(menuItem =>
                        <li key={menuItem.id} className={classes.item}>
                            <Link to={getPathWithLanguage(menuItem.url, i18n.language)} className={classes.link}>
                                {t(menuItem.title)}
                            </Link>
                        </li>
                    )}
                </ul>
                <CheckoutButton className={classes.checkoutButton}/>
            </div>
        );
    }
}

MainMenu.propTypes = {};

function mapStateToProps(state) {
    const { user } = state.user;
    const { menus } = state.menu;
    return {
        user,
        menus
    };
}

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(MainMenu)));