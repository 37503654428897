export const CHECKOUT = {
    STEPS: {
        ORDER_DETAILS: 1,
        PAYMENT_OPTIONS: 2,
        ACKNOWLEDGEMENT: 3,
    },

    PAYMENT_OPTIONS: {
        NONE: 1,
        CREDIT_CARD: 2,
        BANK_TRANSFER: 3,
    },

    PAYMENT_STATUSES: {
        OUTSTANDING: 1,
        PAID: 2,
    },

    ORDER_REF_PREFIX: 'ORD',
};
