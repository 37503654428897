import React from 'react';
import CardBase from '../CardBase';
import {withStyles} from '@material-ui/core';
import TicketInfoCard from '../TicketInfoCard';
import PropTypes from 'prop-types';
import {screens} from '../../../styles/styles';
import {connect} from 'react-redux';

const styles = theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    image: {
        minHeight: '450px',
        maxHeight: '450px',
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            minHeight: '300px',
            maxHeight: '300px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            minHeight: '192px',
            maxHeight: '192px',
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    articleLayout: {
        paddingTop: '30px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '30px',
    },
    title: {
        fontSize: '30px',
        fontWeight: 'bold',
        lineHeight: '35px',
    },
    textBlock: {
        '& > p': {
            color: 'rgba(74,74,74,1)',
            fontSize: '16px',
            lineHeight: '21px',
            margin: 0,
            [theme.breakpoints.down(screens.mobile)]: {
                fontSize: '14px',
                lineHeight: '18px',
            },
            '&:not(:first-child)': {
                paddingTop: '30px',
            }
        }
    },
    ticketInfo: {
        paddingTop: '50px',
        paddingBottom: '41px',
        [theme.breakpoints.up(screens.desktop)]: {
            minWidth: '300px',
            maxWidth: '300px',
        },
    },
    ticketInfoWrapper: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'flex',
            justifyContent: 'flex-end',
        }

    },
    articleImage: {
        marginTop: '30px',
        marginBottom: '30px',
    }
});

export class WaterTourArticleCard extends React.Component {
    createMarkup = (text) => {
        return { __html: text };
    };

    render() {
        const { classes, className, content: article } = this.props;

        return (
            <CardBase className={className}>
                <div className={classes.layout}>
                    {article &&
                    <>
                        {article.headerImage && <div className={classes.image}
                                                     style={{ backgroundImage: `url("/content/images/${article.headerImage}")` }}/>}
                        <div className={classes.articleLayout}>
                            <div className={classes.title}>
                                {article.title}
                            </div>
                            <div className={classes.ticketInfoWrapper}>
                                <TicketInfoCard className={classes.ticketInfo}/>
                            </div>
                            <div className={classes.textBlock}
                                 dangerouslySetInnerHTML={this.createMarkup(article.body)}/>
                        </div>
                    </>
                    }
                </div>
            </CardBase>
        )
    }
}

WaterTourArticleCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

WaterTourArticleCard.defaultProps = {
    className: '',
};

const mapStateToProps = (state) => {
    const { content } = state.content;
    return {
        content,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(WaterTourArticleCard));