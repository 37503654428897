import React from 'react';
import {makeStyles} from '@material-ui/core';
import {CustomControl} from '@googlemap-react/core';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import {useDispatch} from 'react-redux';
import {centerMap, mapActions} from '../../state/actions/mapActions';

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: '10px',
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        minHeight: 0,
    },
    button: {
        background: 'none rgb(255, 255, 255)',
        border: '0px',
        padding: '0px',
        cursor: 'pointer',
        userSelect: 'none',
        borderRadius: '2px',
        height: '40px',
        width: '40px',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
        overflow: 'hidden',
        color: '#666666',
        '&:hover': {
            color: '#000000',
        },
        '&:focus': {
            outline: 0,
        }
    },
}));

export default function CenterButton() {
    const classes = useStyles();
    const dispatch = useDispatch();

    function onClick() {
        dispatch(mapActions.centerMap());
    }

    return (
        <CustomControl bindingPosition='RIGHT_BOTTOM'>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={onClick}>
                    <CenterFocusWeakIcon/>
                </button>
            </div>
        </CustomControl>
    );
}
