import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {screens} from '../../../styles/styles';

const styles = theme => ({
    root: {
        padding: '40px',
        '&:not(:last-child)': {
            paddingBottom: '8px',
        },
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down(screens.mobile)]: {
            padding: '30px',
        },
    },
    title: {
        color: 'rgba(74,74,74,1)',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '21px',
        paddingBottom: '10px',
    },
    description: {
        color: 'rgba(155,155,155,1)',
        fontSize: '16px',
        lineHeight: '20px',
        '& > a': {
            color: 'rgba(0,30,255,1)',
        }
    }
});

class OrderInfoCard extends React.Component {
    createMarkup = (text) => {
        return { __html: text };
    };

    render() {
        const { classes, className, title, description } = this.props;
        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.title}>
                    {title}
                </div>
                <div className={classes.description} dangerouslySetInnerHTML={this.createMarkup(description)}/>
            </div>
        )
    }
}

OrderInfoCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

OrderInfoCard.defaultProps = {
    className: '',
};


export default withStyles(styles)(OrderInfoCard);