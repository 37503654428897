import React from 'react';
import CardBase from '../CardBase';
import clsx from 'clsx';
import IconMenu from '../../menus/IconMenu';
import PropTypes from 'prop-types';
import {screens} from '../../../styles/styles';
import {withStyles} from '@material-ui/core';
import MainImage from '../../images/MainImage';
import MainSummaryCard from './MainSummaryCard';

const styles = theme => ({
    card: {
        marginBottom: '40px',
        [theme.breakpoints.down(screens.tablet)]: {
            marginBottom: '14px',
        },
        [theme.breakpoints.down(screens.mobile)]: {
            marginBottom: '30px',
            '&:not(:first-child)': {
                marginLeft: theme.main.cardListPadding,
                marginRight: theme.main.cardListPadding,
            }
        },
    },
    layout: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(screens.tablet)]: {
            flexDirection: 'column',
        },
    },
    descriptionLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
    mainSummaryCard: {
        flex: 1,
    },
    separator: {
        width: '100%',
        borderTop: `2px solid ${theme.palette.grey['200']}`,
    },
    hideOnDesktop: {
        [theme.breakpoints.up(screens.desktop)]: {
            display: 'none',
        }
    },
    hideOnTablet: {
        [theme.breakpoints.between(screens.tablet, screens.tablet)]: {
            display: 'none',
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down(screens.mobile)]: {
            display: 'none',
        }
    },
});

export class MainInfoCard extends React.Component {
    render() {
        const { classes, className } = this.props;
        return (
            <CardBase className={className}>
                <div className={classes.layout}>
                    <MainImage/>
                    <IconMenu className={clsx(classes.hideOnDesktop, classes.hideOnTablet)}/>
                    <div className={clsx(classes.descriptionLayout, classes.hideOnTablet)}>
                        <MainSummaryCard className={classes.mainSummaryCard}/>
                    </div>
                </div>
            </CardBase>
        )
    }
}

MainInfoCard.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

MainInfoCard.defaultProps = {
    className: '',
};

export default withStyles(styles)(MainInfoCard);