import React from 'react';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {ROUTES} from 'shared';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const styles = theme => ({
    align: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        minHeight: '60px',
        maxHeight: '60px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,84,255,1)',
        '&:hover': {
            backgroundColor: '#004EEE',
        },
        boxShadow: '0 2px 4px 0 rgba(5,33,81,0.07)',
        color: theme.palette.text.primaryInverse,
        fontSize: '18px',
        textTransform: 'none',
        textDecoration: 'none',
        fontWeight: '500',
        letterSpacing: '0.22px',
        lineHeight: '41px',
    },
});

class CheckoutButton2 extends React.Component {
    render() {
        const { classes, className, t, i18n } = this.props;
        return (
            <Link className={clsx(className, classes.align, classes.button)} to={getPathWithLanguage(ROUTES.CHECKOUT, i18n.language)}>
                {t('Button.Checkout')}
            </Link>
        );
    }
}

CheckoutButton2.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

CheckoutButton2.defaultProps = {
    className: '',
};

export default withTranslation()(withStyles(styles)(CheckoutButton2));